import React, { FunctionComponent } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { AssetFormType } from '../../@types';
import AssetForm from './AssetForm';
import { Grid } from '@material-ui/core';
import Dropzone from '../dropzone/Dropzone';

interface OwnProps {
  name: string;
}

type Props = OwnProps;

const AssetsForm: FunctionComponent<Props> = ({ name, ...props }) => {
  const { control } = useFormContext();
  const { fields: assets, append } = useFieldArray({
    control,
    name: name,
    keyName: '_id',
  });
  const handleUpload = React.useCallback(
    (uuid: string, file: File) => {
      const formData = {
        asset: {
          uploadedFile: uuid,
          description: null,
          name: null,
          tags: [],
          _file: file,
          file: null,
        },
      };
      append(formData);
    },
    [append],
  );
  return (
    <Grid container spacing={4}>
      <Grid item md={12}>
        <Dropzone onUpload={handleUpload} />
      </Grid>
      <Grid item md={12}>
        <Grid container spacing={2}>
          {assets.map((asset, index) => (
            <AssetForm
              name={`${name}[${index}]`}
              defaultValue={asset as AssetFormType}
              key={`${name}-${index}`}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AssetsForm;
