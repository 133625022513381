import React, { FunctionComponent } from 'react';
import TablePage from '../../../CapeMorris/components/table-page/table-page';
import { TableProperty } from '../../../CapeMorris/components/table/table-property';
import StringTemplate from '../../../CapeMorris/components/table/cell-templates/string-template';
import IdTemplate from '../../../CapeMorris/components/table/cell-templates/id-template';
import { useHistory, useRouteMatch } from 'react-router-dom';

interface OwnProps {}

type Props = OwnProps;

const CompanyListScreen: FunctionComponent<Props> = (props) => {
  const { push } = useHistory();
  let { url } = useRouteMatch();
  const onCreate = () => {
    push(`${url}/create`);
  };
  const [properties] = React.useState<TableProperty[]>([
    {
      name: 'id',
      visibleName: 'ID',
      template: IdTemplate,
    },
    {
      name: 'name',
      visibleName: 'Nazwa',
      template: StringTemplate,
    },
    {
      name: 'vatNumber',
      visibleName: 'NIP',
      template: StringTemplate,
    },
  ]);

  return (
    <TablePage
      onCreate={onCreate}
      title={'Firmy'}
      name={'firmę'}
      url={'/companies'}
      properties={properties}
    />
  );
};

export default CompanyListScreen;
