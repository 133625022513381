import React, { FunctionComponent } from 'react';
import FormPage from '../../../CapeMorris/components/form-page/form-page';
import InventoryForm from './InventoryForm/InventoryForm';

interface OwnProps {}

type Props = OwnProps;

const InventoryFormScreen: FunctionComponent<Props> = (props) => {
  return (
    <FormPage
      title={'Przyjmij samochód na stan'}
      editTitle={'Edytuj informacje o samochodzie na stanie'}>
      {(methods, isEdit) => <InventoryForm methods={methods} isEdit={isEdit} />}
    </FormPage>
  );
};

export default InventoryFormScreen;
