import React, { FunctionComponent } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { EntityContext } from '../../../CapeMorris/context/entity-context/entity-context';
import { CustomerType } from '../../../@types/Customer/CustomerType';
import { Button, Header, Loader } from '../../../CapeMorris/components';
import { PageBase } from '../../../CapeMorris/components/layout/page/page';
import { PageContent, PageTop } from '../../../CapeMorris/components/layout/page/page.styled';
import { Grid } from '@material-ui/core';
import { SingleDetail } from '../../../CapeMorris/components/Details';
import Table from '../../../CapeMorris/components/table/table';
import { InventoryListProperties } from '../../Inventory/Inventory/InventoryListScreen';
import DateString from '../../../CapeMorris/components/Date/DateString';

interface OwnProps {}

type Props = OwnProps;

const CustomerShowScreen: FunctionComponent<Props> = (props) => {
  const { push } = useHistory();
  let { url } = useRouteMatch();
  const [entity, setEntity] = React.useState<CustomerType | undefined>();
  let { id } = useParams<{ id?: string }>();
  const { load, state } = React.useContext(EntityContext);

  React.useEffect(() => {
    load(id).then((entity) => setEntity(entity));
  }, [id, load]);

  React.useEffect(() => {
    setEntity(state.entity);
  }, [state]);

  React.useEffect(() => {
    return () => {
      setEntity(undefined);
    };
  }, []);
  return (
    <>
      {!entity && <Loader />}
      {entity && (
        <PageBase>
          <PageTop>
            <Grid container spacing={2} justify={'space-between'} alignItems={'center'}>
              <Grid item>
                <Grid container direction={'column'}>
                  <Grid item>
                    <Header>{entity.name}</Header>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Button text={'Edytuj'} onClick={() => push(`${url.replace('show', 'edit')}`)} />
              </Grid>
            </Grid>
          </PageTop>
          <PageContent>
            <Grid container spacing={2}>
              <SingleDetail md={4} title={'Nazwa'}>
                {entity.name}
              </SingleDetail>
              <SingleDetail md={4} title={'E-mail'}>
                {entity.email}
              </SingleDetail>
              <SingleDetail md={4} title={'Telefon'}>
                {entity.phoneNumber}
              </SingleDetail>
              <SingleDetail md={4} title={'Data urodzenia'}>
                <DateString input={entity.birthDate} />
              </SingleDetail>
              <SingleDetail md={4} title={'Kraj'}>
                {entity.country}
              </SingleDetail>
              <SingleDetail md={4} title={'Język'}>
                {entity.language}
              </SingleDetail>
              <SingleDetail md={4} title={'Płeć'}>
                {entity.sex}
              </SingleDetail>
              <SingleDetail md={4} title={'Firmy'}>
                {entity.companies.map((c) => c.name).join(',')}
              </SingleDetail>
              <SingleDetail md={12} title={'Komentarz'}>
                {entity.internalComment}
              </SingleDetail>
              <Grid item md={12}>
                <Header header2>Samochody tego klienta</Header>
                <Table
                  onItemClick={(item) => {
                    push(`/inventory/${item.id}/show`);
                  }}
                  searchFilter={false}
                  predefinedFilters={{ owner: entity.id }}
                  url={'/vehicle_inventories'}
                  properties={InventoryListProperties}
                />
              </Grid>
            </Grid>
          </PageContent>
        </PageBase>
      )}
    </>
  );
};

export default CustomerShowScreen;
