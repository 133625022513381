import React, { FunctionComponent } from 'react';
import * as Modal from './modal.styled';

interface OwnProps {}

type Props = OwnProps;

const ModalContent: FunctionComponent<Props> = (props) => {
  return <Modal.Body>{props.children}</Modal.Body>;
};

export default ModalContent;
