import React, { FunctionComponent } from 'react';
import { AssetType } from '../../@types';
import Card from '@material-ui/core/Card';
import {
  AssetDeleteIcon,
  AssetIcon,
  AssetName,
  AssetPreview,
  AssetPropName,
  AssetProps,
  AssetPropValue,
} from './Asset.styled';
import { parseDate } from '../../helpers/date';
import { COLORS_BY_FUNCTION, COLORS_BY_SENTIMENT } from '../../../assets/colors';
import { FileIcon } from 'react-file-icon';
import { createFullApiUrl } from '../../services/api';
import { Icon } from '../icon/icon';
import DeleteIcon from '../../icons/delete.icon';

interface OwnProps {
  asset: AssetType;
  onClick: () => void;
  selected?: boolean;
  onDelete?: (asset: AssetType) => void;
}

type Props = OwnProps;

const Asset: FunctionComponent<Props> = ({ asset, onClick, selected, onDelete }) => {
  const border = `1px solid ${COLORS_BY_FUNCTION.BORDER}`;
  const selectedBorder = `1px solid ${COLORS_BY_SENTIMENT.PRIMARY.NORMAL}`;
  return (
    <Card
      style={{ padding: 5, border: selected ? selectedBorder : border, cursor: 'pointer' }}
      onClick={onClick}>
      <AssetPreview>
        {asset.file.thumbnail && (
          <img src={createFullApiUrl(`/files/${asset.file.id}/thumbnail`)} alt="Podgląd obrazka" />
        )}
        {!asset.file.thumbnail && (
          <AssetIcon>
            <FileIcon extension={asset.extension} />
          </AssetIcon>
        )}
        {onDelete !== undefined && (
          <AssetDeleteIcon
            onClick={(event) => {
              event.stopPropagation();
              onDelete(asset);
            }}>
            <Icon icon={<DeleteIcon />} />
          </AssetDeleteIcon>
        )}
      </AssetPreview>
      <AssetName>{asset.name}</AssetName>
      <AssetProps>
        <div>
          <AssetPropName>Autor:</AssetPropName>
          <AssetPropValue>{asset.createdBy.name}</AssetPropValue>
        </div>
        <div>
          <AssetPropName>Dodano:</AssetPropName>
          <AssetPropValue>{parseDate(asset.createdAt)}</AssetPropValue>
        </div>
      </AssetProps>
    </Card>
  );
};

export default Asset;
