import React, { FunctionComponent } from 'react';
import Select from '../../form/select/select';
import { Grid } from '@material-ui/core';

interface OwnProps {
  defaultValue?: string;
  name: string;
  label: string;
  onChange: (value: any) => void;
  options?: any[];
  optionLabel?: string;
  baseUrl?: string;
  size: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
}

type Props = OwnProps;

const SelectFilter: FunctionComponent<Props> = (props) => {
  return (
    <Grid item md={props.size}>
      <Select
        name={`filters.${props.name}`}
        label={props.label}
        onChange={props.onChange}
        defaultValue={props.defaultValue}
        baseUrl={props.baseUrl}
        options={props.options}
        optionLabel={props.optionLabel}
      />
    </Grid>
  );
};

export default SelectFilter;
