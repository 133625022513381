import { UserType } from '../../@types';
import { AuthStateType } from './auth-context';

export type BaseAction = {
  type: 'LOADING' | 'LOGIN' | 'LOGOUT' | 'UPDATE_USER' | 'INVALID_CREDENTIALS';
  user?: UserType;
};

export const authReducer = (previousState: AuthStateType, action: BaseAction): AuthStateType => {
  switch (action.type) {
    case 'UPDATE_USER':
    case 'LOGIN':
      if (action.user === undefined || action.user === null) {
        throw Error('User has to be provided into authReducer when LOGIN action is declared');
      }
      return {
        ...previousState,
        state: 'LOGGED_IN',
        user: action.user,
      };
    case 'LOGOUT':
      return {
        ...previousState,
        state: 'LOGGED_OUT',
        user: null,
      };
    case 'INVALID_CREDENTIALS':
      return {
        ...previousState,
        state: 'INVALID_CREDENTIALS',
        user: null,
      };
    case 'LOADING': {
      return {
        ...previousState,
        state: 'LOADING',
        user: null,
      };
    }
  }
};
