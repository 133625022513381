import React, { FunctionComponent } from 'react';
import { Content, Label } from './SingleDetail.styled';
import { GridProps } from '@material-ui/core/Grid/Grid';
import { Grid } from '@material-ui/core';
import EyeIcon from '../../../icons/Eye.icon';
import { Icon } from '../../icon/icon';
import { COLORS_BY_SENTIMENT } from '../../../../assets/colors';
import { DictValue } from '../../../@types/dictionary/dictionary';

type Props = GridProps & {
  title: string;
  hidden?: boolean;
  values?: DictValue[];
};

const SingleDetail: FunctionComponent<Props> = ({ title, hidden = false, ...props }) => {
  const [show, setShow] = React.useState(true);
  React.useEffect(() => {
    setShow(!hidden);
  }, [hidden]);

  const translate = React.useCallback(
    (value) => {
      if (!props.values) {
        return value;
      }
      if (!value) {
        return value;
      }
      if (typeof value !== 'string') {
        return value;
      }
      let result = value;
      props.values.forEach((dictValue) => {
        if (dictValue.value === value) {
          result = dictValue.label;
        }
      });
      return result;
    },
    [props.values],
  );
  return (
    <Grid item {...props} onClick={() => hidden && setShow((show) => !show)}>
      <Label>{title}</Label>
      {show && <Content>{translate(props.children)}</Content>}
      {!show && (
        <Content>
          <Icon
            style={{ cursor: 'pointer', marginTop: -6 }}
            icon={<EyeIcon />}
            color={COLORS_BY_SENTIMENT.PRIMARY.DARKEST}
          />
        </Content>
      )}
    </Grid>
  );
};

export default SingleDetail;
