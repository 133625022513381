import React, { FunctionComponent } from 'react';
import { OptionProps, components } from 'react-select';
import { InventoryType } from '../../@types/Inventory/Inventory';
import { Grid } from '@material-ui/core';
import { createFullApiUrl } from '../../CapeMorris/services/api';
import placeholder from '../../assets/images/car-placeholder.png';
import { Sidenote } from '../../CapeMorris/components/Typography';

type Props = OptionProps<{ value: string; label: string; meta: InventoryType }, false>;

const InventoryOption: FunctionComponent<Props> = (props) => {
  const [src, setSrc] = React.useState<string | any>(placeholder);
  React.useEffect(() => {
    if (props.data.meta?.vehicle?.mainPhoto?.file?.id) {
      setSrc(createFullApiUrl('/files/' + props.data.meta?.mainPhoto?.file?.id + '/thumbnail'));
    }
  }, []);

  return (
    <components.Option {...props}>
      <Grid container direction="row" spacing={2}>
        <Grid item>
          <img src={src} style={{ height: 60 }} />
        </Grid>
        <Grid item md={true}>
          <Grid container>
            <Grid item md={12}>
              {props.data.label}
            </Grid>
            <Grid item md={12}>
              <Sidenote>{props.data.meta.plates}</Sidenote>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </components.Option>
  );
};

export default InventoryOption;
