import React, { FunctionComponent } from 'react';
import { Statuses, StatusType } from '../../@types/Inventory/Inventory';
import { Tag } from '../../CapeMorris/components';
import COLORS, { COLORS_BY_SENTIMENT } from '../../assets/colors';

interface OwnProps {
  status: StatusType;
}

type Props = OwnProps;

const Status: FunctionComponent<Props> = ({ status, ...props }) => {
  const [color, setColor] = React.useState<string>(COLORS_BY_SENTIMENT.PRIMARY.DARKEST);
  React.useEffect(() => {
    switch (status) {
      case 'on_sale':
        setColor(COLORS.GREEN.NORMAL);
        break;
      case 'booked':
      case 'at_owners':
        setColor(COLORS.ORANGE.NORMAL);
        break;
      default:
        setColor(COLORS_BY_SENTIMENT.PRIMARY.DARKEST);
    }
  }, [status]);
  return <Tag color={color}>{Statuses[status]}</Tag>;
};

export default Status;
