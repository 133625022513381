import React, { FunctionComponent } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { EntityProvider } from '../../CapeMorris/context/entity-context/entity-provider';
import VehicleFormScreen from './Vehicle/VehicleFormScreen';
import VehicleListScreen from './Vehicle/VehicleListScreen';
import LocationFormScreen from './Location/LocationFormScreen';
import LocationListScreen from './Location/LocationListScreen';
import InventoryListScreen from './Inventory/InventoryListScreen';
import InventoryFormScreen from './Inventory/InventoryFormScreen';
import LocationDetailsScreen from './Location/LocationDetailsScreen';
import InventoryShowScreen from './Inventory/InventoryShowScreen';
import VehicleShowScreen from './Vehicle/VehicleShowScreen';

interface OwnProps {}

type Props = OwnProps;

const InventoryBaseScreen: FunctionComponent<Props> = (props) => {
  let { path } = useRouteMatch();
  return (
    <>
      <EntityProvider baseUrl="/vehicle_inventories" rootPath={`${path}`}>
        <Switch>
          <Route exact path={`${path}`}>
            <InventoryListScreen />
          </Route>
          <Route exact path={`${path}/create`}>
            <InventoryFormScreen />
          </Route>
          <Route exact path={`${path}/:id/edit`}>
            <InventoryFormScreen />
          </Route>
          <Route exact path={`${path}/:id/show`}>
            <InventoryShowScreen />
          </Route>
        </Switch>
      </EntityProvider>
      <EntityProvider baseUrl="/vehicles" rootPath={`${path}/vehicles`}>
        <Switch>
          <Route exact path={`${path}/vehicles`}>
            <VehicleListScreen />
          </Route>
          <Route exact path={`${path}/vehicles/create`}>
            <VehicleFormScreen />
          </Route>
          <Route exact path={`${path}/vehicles/:id/edit`}>
            <VehicleFormScreen />
          </Route>
          <Route exact path={`${path}/vehicles/:id/show`}>
            <VehicleShowScreen />
          </Route>
        </Switch>
      </EntityProvider>
      <EntityProvider baseUrl="/locations" rootPath={`${path}/locations`}>
        <Switch>
          <Route exact path={`${path}/locations`}>
            <LocationListScreen />
          </Route>
          <Route exact path={`${path}/locations/create`}>
            <LocationFormScreen />
          </Route>
          <Route exact path={`${path}/locations/:id/edit`}>
            <LocationFormScreen />
          </Route>
          <Route exact path={`${path}/locations/:id/show`}>
            <LocationDetailsScreen />
          </Route>
        </Switch>
      </EntityProvider>
    </>
  );
};

export default InventoryBaseScreen;
