import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import Switch from '../../CapeMorris/components/form/switch/Switch';
import { useFormContext } from 'react-hook-form';

interface OwnProps {
  defaultValue?: string;
  name: string;
  label: string;
  onChange: (value: any) => void;
  size: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
}

type Props = OwnProps;

const StatusFilter: FunctionComponent<Props> = (props) => {
  const { register, watch, setValue } = useFormContext();
  const watcher = watch(`filters._${props.name}`);

  React.useEffect(() => {
    if (watcher) {
      setValue(`filters.${props.name}`, ['preparation', 'on_sale', 'booked', 'at_owners']);
    } else {
      setValue(`filters.${props.name}`, ['sold', 'withdrawn']);
    }
    console.log(watcher);
  }, [watcher]);

  return (
    <Grid item md={props.size}>
      <input hidden name={`filters.${props.name}`} ref={register} />
      <Switch
        defaultChecked={true}
        name={`filters._${props.name}`}
        label={props.label}
        onChange={props.onChange}
      />
    </Grid>
  );
};

export default StatusFilter;
