import { DictValue } from '../../CapeMorris/@types/dictionary/dictionary';

export const BodyTypes: DictValue[] = [
  { value: 'sedan', label: 'Sedan' },
  { value: 'hatchback', label: 'Hatchback' },
  { value: 'station_wagon', label: 'Kombi' },
  { value: 'suv', label: 'SUV' },
  { value: 'minivan', label: 'Minivan' },
  { value: 'compact', label: 'Kompakt' },
  { value: 'coupe', label: 'Coupe' },
  { value: 'sport_coupe', label: 'Sport Coupe' },
  { value: 'pickup', label: 'Pickup' },
  { value: 'van', label: 'VAN' },
  { value: 'city_car', label: 'Auto miejskie' },
  { value: 'convertible', label: 'Cabrio' },
];
