import styled from 'styled-components';
import { COLORS_BY_SENTIMENT } from '../../../../assets/colors';

export const PageContainer = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column;
  position: relative;
`;

export const PageTop = styled.div`
  padding: 35px 30px;
  background-color: ${COLORS_BY_SENTIMENT.PRIMARY.LIGHTEST};
`;

type PageContentProps = {
  disableMargin?: boolean;
};

export const PageContent = styled.div<PageContentProps>`
  padding: ${(props) => (props.disableMargin ? 0 : '50px 30px 72px')};
  height: 100%;
  position: relative;
`;
