import React from 'react';
import { SplashWrapper } from './splash.styled';
import { Logo } from '../../CapeMorris/components';

export const Splash: React.FC = () => {
  return (
    <SplashWrapper>
      <Logo width={400} />
    </SplashWrapper>
  );
};
