import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import { Button, Header } from '../../../../CapeMorris/components';
import { SingleDetail } from '../../../../CapeMorris/components/Details';
import DateString from '../../../../CapeMorris/components/Date/DateString';
import MoneyAsString from '../../../../CapeMorris/components/Money/MoneyAsString';
import { InventoryType, StatusType } from '../../../../@types/Inventory/Inventory';
import api from '../../../../CapeMorris/services/api';
import { EntityContext } from '../../../../CapeMorris/context/entity-context/entity-context';
import COLORS from '../../../../assets/colors';
import Status from '../../../../components/Status/Status';
import Form from '../../../../CapeMorris/components/form/form';
import ChangeStatusSelect from './ChangeStatusSelect';
import { ServiceTypes } from '../../../../@types/Inventory/ServiceType';

interface OwnProps {
  entity: InventoryType;
  onStatusChange?: (status: StatusType) => void;
}

type Props = OwnProps;

const InventoryShowDetails: FunctionComponent<Props> = ({ entity, ...props }) => {
  const { load } = React.useContext(EntityContext);

  const changeStatus = React.useCallback(
    (status) => {
      let url = `${entity['@id'].substr(4)}/${status}`;
      return api.put(url, {}).then(() => {
        void load(entity.id);
        if (props.onStatusChange) {
          props.onStatusChange(status);
        }
      });
    },
    [entity],
  );

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Grid container spacing={2} direction="row">
          <Grid item>
            <Header header2>Status</Header>
          </Grid>
          <Grid item>
            <Status status={entity.status} />
          </Grid>
          <Grid item md={6}>
            <Form onSubmit={() => {}} defaultValues={{}}>
              <ChangeStatusSelect onChange={changeStatus} />
            </Form>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {entity.possibleStatuses.includes('on_sale') && (
            <Grid item>
              <Button
                small
                color={COLORS.GREEN.NORMAL}
                text={'W sprzedaży'}
                onClick={() => changeStatus('on_sale')}
              />
            </Grid>
          )}
          {entity.possibleStatuses.includes('booked') && (
            <Grid item>
              <Button
                small
                color={COLORS.ORANGE.NORMAL}
                text={'Zarezerwowany'}
                onClick={() => changeStatus('booked')}
              />
            </Grid>
          )}
          {entity.possibleStatuses.includes('sold') && (
            <Grid item>
              <Button small text={'Sprzedany'} onClick={() => changeStatus('sold')} />
            </Grid>
          )}
          {entity.possibleStatuses.includes('withdrawn') && (
            <Grid item>
              <Button small text={'Wycofany'} onClick={() => changeStatus('withdrawn')} />
            </Grid>
          )}
          {entity.possibleStatuses.includes('at_owners') && (
            <Grid item>
              <Button
                small
                color={COLORS.ORANGE.NORMAL}
                text={'U właściciela'}
                onClick={() => changeStatus('at_owners')}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item md={12}>
        <Header header2>Podstawowe informacje</Header>
        <Grid container spacing={2}>
          <SingleDetail md={3} title={'Typ własności'}>
            {entity.ownership}
          </SingleDetail>
          <SingleDetail md={3} title={'Opiekun'}>
            {entity.employee.name}
          </SingleDetail>
          <SingleDetail md={3} title={'Miejsce postoju'}>
            {entity.location?.name} ({entity.position})
          </SingleDetail>
          <SingleDetail md={3} title={'Certyfikat DEKRA'}>
            {entity.dekraCertificate}
          </SingleDetail>
          <SingleDetail md={3} title={'Serwis'} values={ServiceTypes}>
            {entity.serviceType}
          </SingleDetail>
          <SingleDetail md={3} title={'Utworzono przez'}>
            {entity.createdBy.name}
          </SingleDetail>
          <SingleDetail md={3} title={'Utworzono dnia'}>
            <DateString input={entity.createdAt} time />
          </SingleDetail>
          <SingleDetail md={12} title={'Komentarz'}>
            {entity.internalComment}
          </SingleDetail>
        </Grid>
      </Grid>
      <Grid item md={12}>
        <Header header2>Samochód</Header>
        <Grid container spacing={3}>
          <SingleDetail md={3} title={'Marka'}>
            {entity.vehicle.make}
          </SingleDetail>
          <SingleDetail md={3} title={'Model'}>
            {entity.vehicle.model}
          </SingleDetail>
          <SingleDetail md={3} title={'VIN'}>
            {entity.vehicle.vin}
          </SingleDetail>
          <SingleDetail md={3} title={'Rok produkcji'}>
            {entity.vehicle.year}
          </SingleDetail>
          <SingleDetail md={3} title={'Kraj pochodzenia'}>
            {entity.vehicle.originCountry}
          </SingleDetail>
          <SingleDetail md={3} title={'Nadwozie'}>
            {entity.vehicle.bodyType}
          </SingleDetail>
          <SingleDetail md={3} title={'Silnik'}>
            {entity.vehicle.engine}
          </SingleDetail>
          <SingleDetail md={3} title={'Skrzynia biegów'}>
            {entity.vehicle.transmission}
          </SingleDetail>
          <SingleDetail md={3} title={'Napęd'}>
            {entity.vehicle.driveType}
          </SingleDetail>
          <SingleDetail md={3} title={'Kolor nadwozia'}>
            {entity.vehicle.exteriorColor}
          </SingleDetail>
          <SingleDetail md={3} title={'Kolor wnętrza'}>
            {entity.vehicle.interiorColor}
          </SingleDetail>
          <SingleDetail md={3} title={'Przebieg'}>
            {entity.mileage}
          </SingleDetail>
          <SingleDetail md={3} title={'Pojemność silnika (cm3)'}>
            {entity.vehicle.capacity}
          </SingleDetail>
          <SingleDetail md={3} title={'Moc silnika (KM)'}>
            {entity.vehicle.power}
          </SingleDetail>
          <SingleDetail md={3} title={'Numery rejestracyjne'}>
            {entity.plates}
          </SingleDetail>
          <SingleDetail md={3} title={'Przegląd techniczny'}>
            {entity.examinationDate && <DateString input={entity.examinationDate} />}
          </SingleDetail>
          <SingleDetail md={3} title={'Ubezpieczenie'}>
            {entity.insuranceDate && <DateString input={entity.insuranceDate} />}
          </SingleDetail>
        </Grid>
      </Grid>
      <Grid item md={12}>
        <Header header2>Finanse</Header>
        <Grid container spacing={3}>
          <SingleDetail md={3} title={'Kwota zakupu'} hidden>
            <MoneyAsString money={entity.purchasePrice} />
          </SingleDetail>
          <SingleDetail md={3} title={'Kwota sprzedaży'}>
            <MoneyAsString money={entity.salesPrice} />
          </SingleDetail>
          <SingleDetail md={3} title={'Typ sprzedaży'}>
            {entity.salesType}
          </SingleDetail>
        </Grid>
      </Grid>
      {entity.ownership === 'customer' && (
        <Grid item md={12}>
          <Header header2>Właściciel</Header>
          <Grid container spacing={3}>
            <SingleDetail md={3} title={'Nazwa'}>
              {entity.owner?.name}
            </SingleDetail>
            <SingleDetail md={3} title={'Telefon'}>
              {entity.owner?.phoneNumber}
            </SingleDetail>
            <SingleDetail md={4} title={'E-mail'}>
              {entity.owner?.email}
            </SingleDetail>
            {entity.company && (
              <SingleDetail md={3} title={'Firma'}>
                {entity.company?.name}
              </SingleDetail>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default InventoryShowDetails;
