import React from 'react';
import { CellTemplate } from '../../CapeMorris/components/table/table-property';
import Status from '../Status/Status';
import { StatusType } from '../../@types/Inventory/Inventory';

const StatusTemplate: CellTemplate<StatusType> = (props) => {
  return <Status status={props.value} />;
};

export default StatusTemplate;
