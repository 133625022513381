import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import { Button } from '../../../../CapeMorris/components';
import { useFormContext } from 'react-hook-form';
import Select from '../../../../CapeMorris/components/form/select/select';
import COLORS from '../../../../assets/colors';
import ModalContainer from '../../../../CapeMorris/components/modal/use-modal-container';
import CustomerModal from './CustomerModal';

interface OwnProps {}

type Props = OwnProps;

const InventoryCustomerForm: FunctionComponent<Props> = (props) => {
  const methods = useFormContext();
  const [companies, setCompanies] = React.useState<{ value: string; label: string }[]>([]);
  const [showCompany, setShowCompany] = React.useState(true);
  const { showModal } = ModalContainer.useContainer();

  React.useEffect(() => {
    return () => {
      methods.unregister('customer');
      methods.unregister('company');
    };
  }, []);

  const handleCustomerChange = React.useCallback((value) => {
    methods.setValue('company', {});
    setShowCompany(false);
    setTimeout(() => setShowCompany(true), 300);
    setCompanies(
      value.meta.companies.map((c: { name: string; '@id': string }) => ({
        label: c.name,
        value: c['@id'],
      })),
    );
  }, []);

  const openModal = React.useCallback(() => {
    showModal({
      title: 'Nowy klient',
      body: <CustomerModal />,
    });
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <Select
          name={'owner'}
          label={'Właściciel'}
          placeholder={'Jan Kowalski'}
          baseUrl={'/customers'}
          required
          onChange={handleCustomerChange}
        />
      </Grid>
      <Grid item md={6}>
        {showCompany && (
          <Select
            name={'company'}
            label={'Firma'}
            placeholder={'Dobra Firma Sp. z o.o.'}
            options={companies}
          />
        )}
      </Grid>
      <Grid item>
        <Button small color={COLORS.GREEN.NORMAL} text="Dodaj nowego klienta" onClick={openModal} />
      </Grid>
    </Grid>
  );
};

export default InventoryCustomerForm;
