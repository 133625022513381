import styled, { css } from 'styled-components';
import COLORS, { COLORS_BY_SENTIMENT, COLORS_BY_FUNCTION } from '../../../assets/colors';
import { WEIGHTS } from '../../../assets/fonts';

type CommonProps = {
  status: string;
  isSuffix?: boolean;
  required?: boolean;
};

export const FormWrapper = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FormInputWrapper = styled.div<CommonProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  padding: 7px 0;
  border-bottom: 1px solid ${COLORS_BY_FUNCTION.BORDER};
  border-color: ${(props) => props.status === 'error' && COLORS_BY_SENTIMENT.ERROR.NORMAL};
  min-height: 48px;
  max-height: 250px;
  box-shadow: none;
  transition: border-color 0.2s ease;
  pointer-events: ${(props) => (props.status === 'disabled' ? 'none' : 'initial')};
  background-color: ${(props) =>
    props.status === 'disabled'
      ? COLORS.GREY.LIGHTEST
      : props.status === 'error'
      ? COLORS.RED.LIGHT
      : COLORS.WHITE};
`;

export const FormInputSuffix = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  color: ${COLORS_BY_SENTIMENT.PRIMARY.DARKEST};
  height: 100%;
  min-width: 48px;
  text-align: center;
  background-color: ${COLORS_BY_SENTIMENT.PRIMARY.LIGHTEST};
  font-weight: ${WEIGHTS.BOLD};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormLabel = styled.span<CommonProps>`
  color: ${COLORS_BY_SENTIMENT.PRIMARY.DARKEST};
  font-weight: ${WEIGHTS.MEDIUM};
  text-transform: uppercase;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.6px;
  margin-bottom: 11px;
  transition: color 0.2s ease;
  ${(props) =>
    props.required &&
    css`
      &:after {
        padding-left: 3px;
        content: '*';
        color: ${COLORS.RED.NORMAL};
      }
    `}
`;

export const FormError = styled.span`
  color: ${COLORS_BY_SENTIMENT.ERROR.NORMAL};
  font-weight: ${WEIGHTS.SEMI_BOLD};
  font-size: 14px;
  line-height: 26px;
  transition: opacity 1s ease;
  height: 26px;
`;

export const FormInput = styled.input<CommonProps>`
  border: none;
  outline: none;
  font-weight: ${WEIGHTS.BOLD};
  font-size: 14px;
  line-height: 17px;
  background-color: ${(props) =>
    props.status === 'error' ? COLORS_BY_SENTIMENT.PRIMARY.LIGHT : COLORS.WHITE};
  width: 100%;
  padding: 0;
  color: ${(props) =>
    props.status === 'error'
      ? COLORS_BY_SENTIMENT.ERROR.NORMAL
      : COLORS_BY_SENTIMENT.PRIMARY.DARKEST};
  :focus {
    outline: none;
  }
  ::placeholder {
    font-size: 14px;
     font-weight: ${WEIGHTS.BOLD};
    color: ${(props) =>
      props.status === 'error' ? COLORS_BY_SENTIMENT.ERROR.NORMAL : COLORS.GREY.NORMAL};
  }
  :focus::placeholder {
  ${(props) =>
    props.isSuffix &&
    css`
      width: calc(100% - 48px);
    `}
`;

export const FormTextarea = styled.textarea<CommonProps>`
  border: none;
  outline: none;
  font-weight: ${WEIGHTS.BOLD};
  font-size: 14px;
  line-height: 17px;
  resize: vertical;
  width: 100%;
  padding: 0;
  background-color: ${(props) => (props.status === 'error' ? COLORS.RED.LIGHT : COLORS.WHITE)};
  color: ${(props) =>
    props.status === 'error'
      ? COLORS_BY_SENTIMENT.ERROR.NORMAL
      : COLORS_BY_SENTIMENT.PRIMARY.DARKEST};
  :focus {
    outline: none;
  }
  ::placeholder {
    font-size: 14px;
    font-weight: ${WEIGHTS.BOLD};
    color: ${(props) =>
      props.status === 'error' ? COLORS_BY_SENTIMENT.ERROR.NORMAL : COLORS.GREY.DARK};
  }
  :focus::placeholder {
    color: ${COLORS.GREY.NORMAL};
  }
`;
