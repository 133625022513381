import React from 'react';
import { LoaderContainer, LoaderWrapper } from './loader.styled';

export const Loader: React.FC = () => {
  return (
    <LoaderWrapper>
      <LoaderContainer>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </LoaderContainer>
    </LoaderWrapper>
  );
};
