import React, { FunctionComponent } from 'react';
import * as Components from './LayoutSubmenu.styled';

interface OwnProps {
  active: boolean;
  onClick: () => void;
}

type Props = OwnProps;

const LayoutSubmenu: FunctionComponent<Props> = (props) => {
  return (
    <Components.Container active={props.active} onClick={props.onClick}>
      {props.children}
    </Components.Container>
  );
};

export default LayoutSubmenu;
