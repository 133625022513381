import React, { FunctionComponent } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { EntityContext } from '../../../CapeMorris/context/entity-context/entity-context';
import { Button, Header, Loader } from '../../../CapeMorris/components';
import { PageBase } from '../../../CapeMorris/components/layout/page/page';
import { PageContent, PageTop } from '../../../CapeMorris/components/layout/page/page.styled';
import { Grid } from '@material-ui/core';
import { InventoryType } from '../../../@types/Inventory/Inventory';
import { Sidenote } from '../../../CapeMorris/components/Typography';
import InventoryShowDetails from './InventoryShow/InventoryShowDetails';
import InventoryShowAssets from './InventoryShow/InventoryShowAssets';
import { HistoryWidget } from '../../../CapeMorris/components/History';
import InventoryShowActivities from './InventoryShow/InventoryShowActivities';

interface OwnProps {}

type Props = OwnProps;

const InventoryShowScreen: FunctionComponent<Props> = (props) => {
  const { push } = useHistory();
  let { url } = useRouteMatch();
  const [entity, setEntity] = React.useState<InventoryType | undefined>();
  let { id } = useParams<{ id?: string }>();
  const { load, state } = React.useContext(EntityContext);
  const [historyReloadToken, setHistoryReloadToken] = React.useState<number | string>('');

  React.useEffect(() => {
    load(id).then((entity) => setEntity(entity));
  }, [id, load]);

  React.useEffect(() => {
    setEntity(state.entity);
  }, [state]);

  React.useEffect(() => {
    return () => {
      setEntity(undefined);
    };
  }, []);

  const handleAssetsChange = React.useCallback(
    (entity: InventoryType) => {
      setEntity(undefined);
      console.log(entity);
      setTimeout(() => setEntity(entity), 200);
      setHistoryReloadToken(Math.random());
    },
    [id, load],
  );

  return (
    <>
      {!entity && <Loader />}
      {entity && (
        <PageBase>
          <PageTop>
            <Grid container spacing={2} justify={'space-between'} alignItems={'center'}>
              <Grid item>
                <Grid container direction={'column'}>
                  <Grid item>
                    <Header>
                      {entity.vehicle.make} {entity.vehicle.model}
                    </Header>
                  </Grid>
                  <Grid item>
                    <Sidenote>
                      VIN: {entity.vehicle.vin}, Rejestracja: {entity.plates}
                    </Sidenote>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ marginLeft: 'auto' }}>
                <HistoryWidget
                  subject={entity.subject}
                  subjectId={entity.subjectId}
                  reloadToken={historyReloadToken}
                />
              </Grid>
              <Grid item>
                <Button text={'Edytuj'} onClick={() => push(`${url.replace('show', 'edit')}`)} />
              </Grid>
            </Grid>
          </PageTop>
          <PageContent>
            <Grid container spacing={4}>
              <Grid item md={6}>
                <Grid container spacing={4}>
                  <Grid item md={12}>
                    <InventoryShowDetails
                      entity={entity}
                      onStatusChange={() => setHistoryReloadToken(Math.random())}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <InventoryShowAssets onAssetsChange={handleAssetsChange} entity={entity} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6} xs={12}>
                <Grid container spacing={4}>
                  <Grid item md={12}>
                    <InventoryShowActivities entity={entity} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </PageContent>
        </PageBase>
      )}
    </>
  );
};

export default InventoryShowScreen;
