import React, { FunctionComponent } from 'react';
import HistoryIcon from '../../icons/History.icon';
import { Icon } from '../icon/icon';
import COLORS from '../../../assets/colors';
import * as Components from './HistoryWidget.styled';
import ModalContainer from '../modal/use-modal-container';
import { HistoryEntryType } from '../../@types/History/HistoryEntryType';
import { AxiosResponse } from 'axios';
import api from '../../services/api';
import HistoryModalBody from './HistoryModalBody';

interface OwnProps {
  subject: string;
  subjectId: number | string;
  reloadToken?: number | string;
}

type Props = OwnProps;

type ApiResponse = AxiosResponse<{
  'hydra:member': HistoryEntryType[];
}>;

const HistoryWidget: FunctionComponent<Props> = ({ subject, subjectId, ...props }) => {
  const { showModal } = ModalContainer.useContainer();
  const [entries, setEntries] = React.useState<HistoryEntryType[]>([]);

  const loadItems = React.useCallback(() => {
    api
      .get('/history_entries', {
        params: {
          subject: subject,
          subjectId: subjectId,
        },
      })
      .then((response: ApiResponse) => {
        setEntries(response.data['hydra:member']);
      });
  }, [subject, subjectId]);

  React.useEffect(() => {
    loadItems();
  }, [loadItems, props.reloadToken]);

  const handleClick = React.useCallback(() => {
    const body = <HistoryModalBody entries={entries} />;

    showModal({
      title: 'History',
      body: body,
      wide: true,
    });

    console.log(entries);
  }, [entries]);

  return (
    <Components.Container title="Historia edycji" onClick={handleClick}>
      <Icon
        icon={<HistoryIcon />}
        color={COLORS.RED.NORMAL}
        size={24}
        style={{ cursor: 'pointer' }}
      />
    </Components.Container>
  );
};

export default HistoryWidget;
