import React from 'react';
import ModalContainer from './use-modal-container';
import * as Modal from './modal.styled';
import { Header, Icon } from '..';
import { CloseIcon } from '../../../CapeMorris/icons/close.icon';
import COLORS from '../../../assets/colors';

const ModalBase: React.FC = () => {
  const { modals, hideModal } = ModalContainer.useContainer();
  if (!modals || modals.length === 0) {
    return null;
  }

  return (
    <Modal.Background>
      {modals.map((modal, idx) => (
        <Modal.Wrapper wide={modal.wide} key={idx}>
          <Modal.Title>
            {modal.title === 'string' ? <Header>{modal.title}</Header> : modal.title}
            <Modal.Close onClick={hideModal}>
              <Icon icon={<CloseIcon />} color={COLORS.BLACK} size={16} />
            </Modal.Close>
          </Modal.Title>
          <Modal.Body>{modal.body}</Modal.Body>
        </Modal.Wrapper>
      ))}
    </Modal.Background>
  );
};

export default ModalBase;
