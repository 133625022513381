import React from 'react';
import { LocaleContext } from './locale-context';

export const LocaleProvider: React.FC = ({ children }) => {
  const { locale, availableLocales } = React.useContext(LocaleContext);

  return (
    <LocaleContext.Provider value={{ locale, availableLocales }}>{children}</LocaleContext.Provider>
  );
};
