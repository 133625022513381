import React, { FunctionComponent } from 'react';
import TableRowAction from './TableRowAction';
import { RowActionType } from './table-property';
import VerticalDots from '../../../CapeMorris/icons/VerticalDots.icon';
import { Icon } from '..';
import { COLORS_BY_FUNCTION } from '../../../assets/colors';
import { TableAction } from './table.styled';
import Tooltip from '../tooltip';
import TableRowActionsMenu from './TableRowActionsMenu';

interface OwnProps {
  actions?: RowActionType[];
  item: any;
}

type Props = OwnProps;

const TableRowActions: FunctionComponent<Props> = ({ actions, item }) => {
  const handleActionClick = (event: React.MouseEvent, action: RowActionType) => {
    action.onClick(action.name, item);
    event.stopPropagation();
  };

  return (
    <div style={{ cursor: 'initial' }} onClick={(event) => event.stopPropagation()}>
      {actions
        ?.filter((action) => !action.hidden)
        .map((action, index) => (
          <TableRowAction
            key={index}
            {...action}
            label={typeof action.label === 'string' ? action.label : action.label(item)}
            onClick={(event) => handleActionClick(event, action)}
          />
        ))}
      {actions && actions.filter((a) => a.hidden).length > 0 && (
        <Tooltip
          content={
            <TableRowActionsMenu
              item={item}
              actions={actions ? actions.filter((a) => a.hidden) : []}
            />
          }
          persist>
          <TableAction>
            <Icon icon={<VerticalDots />} color={COLORS_BY_FUNCTION.ACTION} />
          </TableAction>
        </Tooltip>
      )}
    </div>
  );
};

export default TableRowActions;
