import React, { FunctionComponent } from 'react';
import * as Components from './Dropzone.styled';
import { Icon } from '..';
import UploadIcon from '../../../CapeMorris/icons/upload.icon';
import { COLORS_BY_SENTIMENT } from '../../../assets/colors';

interface OwnProps {
  isDragActive: boolean;
}

type Props = OwnProps;

const DefaultDropzoneContent: FunctionComponent<Props> = (props) => {
  return (
    <Components.Container active={props.isDragActive}>
      <Icon icon={<UploadIcon />} color={COLORS_BY_SENTIMENT.PRIMARY.NORMAL} size={60} />
      <Components.DragDropText>Przeciągnij i upuść pliki</Components.DragDropText>
      <div>lub</div>
      <Components.ChooseText>Wybierz pliki z komputera</Components.ChooseText>
    </Components.Container>
  );
};

export default DefaultDropzoneContent;
