import React, { CSSProperties } from 'react';
import { IconContainer } from './icon.styled';
import { COLORS_BY_SENTIMENT } from '../../../assets/colors';

type IconComponentProps = {
  icon: React.ReactElement;
  color?: string;
  size?: number;
  style?: CSSProperties;
  onClick?: () => void;
  title?: string;
};

export const Icon: React.FC<IconComponentProps> = ({
  icon,
  color = COLORS_BY_SENTIMENT.PRIMARY.NORMAL,
  size = 20,
  style,
  onClick,
  ...props
}) => {
  return (
    <IconContainer
      className="cc-icon"
      style={style}
      color={color}
      size={size}
      onClick={onClick}
      title={props.title}>
      {icon}
    </IconContainer>
  );
};
