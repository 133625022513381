import React, { FunctionComponent } from 'react';
import SnackContainer from '../../CapeMorris/components/snack-bar/use-snack-container';
import api from '../../CapeMorris/services/api';
import Form from '../../CapeMorris/components/form/form';
import { Button, TextInput } from '../../CapeMorris/components';
import COLORS from '../../assets/colors';

interface OwnProps {
  setCurrentForm: (form: 'login' | 'forgot-password' | 'change-password') => void;
}

type Props = OwnProps;

const ChangePassword: FunctionComponent<Props> = (props) => {
  const [state, setState] = React.useState<'idle' | 'working' | 'incorrect-password'>('idle');
  const { showSnack } = SnackContainer.useContainer();
  const onSubmit = (data: any) => {
    if (data.password !== data.confirmPassword) {
      setState('incorrect-password');
      return;
    }
    setState('working');
    api.post('/reset-password/assign', { ...data }).then(() => {
      setState('idle');
      showSnack({
        title: 'Success',
        message: 'Your password has been changed.',
      });
      props.setCurrentForm('login');
    });
  };
  return (
    <Form onSubmit={onSubmit} defaultValues={{}}>
      <TextInput
        style={{ marginBottom: 20, marginTop: 50 }}
        name="token"
        label={'Token'}
        placeholder={'TOKEN_FROM_EMAIL'}
        required
      />
      <TextInput name={'password'} label={'Password'} placeholder={'P@ssw0rd!'} required password />
      <TextInput
        name={'confirmPassword'}
        label={'Confirm password'}
        placeholder={'P@ssw0rd!'}
        required
        password
      />
      {state === 'incorrect-password' && (
        <span style={{ color: 'red' }}>Passwords are not the same</span>
      )}
      <Button
        type="submit"
        style={{ marginTop: 30 }}
        wide
        color={COLORS.BLUE.NORMAL}
        text={'Send e-mail'}
        waiting={state === 'working'}
      />
    </Form>
  );
};

export default ChangePassword;
