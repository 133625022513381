import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import { Button, Header, Loader } from '../../../CapeMorris/components';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { EntityContext } from '../../../CapeMorris/context/entity-context/entity-context';
import { PageBase } from '../../../CapeMorris/components/layout/page/page';
import { Sidenote } from '../../../CapeMorris/components/Typography';
import { PageContent, PageTop } from '../../../CapeMorris/components/layout/page/page.styled';
import { Vehicle } from '../../../@types/vehicle';
import { SingleDetail } from '../../../CapeMorris/components/Details';
import DateAsString from '../../../CapeMorris/components/Date/DateString';

interface OwnProps {}

type Props = OwnProps;

const VehicleShowScreen: FunctionComponent<Props> = (props) => {
  const { push } = useHistory();
  let { url } = useRouteMatch();
  const [entity, setEntity] = React.useState<Vehicle | undefined>();
  let { id } = useParams<{ id?: string }>();
  const { load, state } = React.useContext(EntityContext);

  React.useEffect(() => {
    load(id).then((entity) => setEntity(entity));
  }, [id, load]);

  React.useEffect(() => {
    setEntity(state.entity);
  }, [state]);

  React.useEffect(() => {
    return () => {
      setEntity(undefined);
    };
  }, []);
  return (
    <>
      {!entity && <Loader />}
      {entity && (
        <PageBase>
          <PageTop>
            <Grid container spacing={2} justify={'space-between'} alignItems={'center'}>
              <Grid item>
                <Grid container direction={'column'}>
                  <Grid item>
                    <Header>
                      {entity.make} {entity.model}
                    </Header>
                  </Grid>
                  <Grid item>
                    <Sidenote>VIN: {entity.vin}</Sidenote>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Button text={'Edytuj'} onClick={() => push(`${url.replace('show', 'edit')}`)} />
              </Grid>
            </Grid>
          </PageTop>
          <PageContent>
            <Grid container spacing={2}>
              <SingleDetail md={4} title={'Marka'}>
                {entity.make}
              </SingleDetail>
              <SingleDetail md={4} title={'Model'}>
                {entity.model}
              </SingleDetail>
              <SingleDetail md={4} title={'Rok produkcji'}>
                {entity.year}
              </SingleDetail>
              <SingleDetail md={4} title={'VIN'}>
                {entity.vin}
              </SingleDetail>
              <SingleDetail md={4} title={'Kraj pochodzenia'}>
                {entity.originCountry.value}
              </SingleDetail>
              <SingleDetail md={4} title={'Silnik'}>
                {entity.engine}
              </SingleDetail>
              <SingleDetail md={4} title={'Nadwozie'}>
                {entity.bodyType}
              </SingleDetail>
              <SingleDetail md={4} title={'Skrzynia biegów'}>
                {entity.transmission}
              </SingleDetail>
              <SingleDetail md={4} title={'Napęd'}>
                {entity.driveType}
              </SingleDetail>
              <SingleDetail md={4} title={'Pojemność silnika (cm3)'}>
                {entity.capacity}
              </SingleDetail>
              <SingleDetail md={4} title={'Moc silnika (KM)'}>
                {entity.power}
              </SingleDetail>
              <SingleDetail md={4} title={'Kolor nadwozia'}>
                {entity.exteriorColor}
              </SingleDetail>
              <SingleDetail md={4} title={'Kolor w środku'}>
                {entity.interiorColor}
              </SingleDetail>
              <SingleDetail md={4} title={'Utworzone przez'}>
                {entity.createdBy.name}
              </SingleDetail>
              <SingleDetail md={4} title={'Utworzono dnia'}>
                <DateAsString input={entity.createdAt} time />
              </SingleDetail>
            </Grid>
          </PageContent>
        </PageBase>
      )}
    </>
  );
};

export default VehicleShowScreen;
