import React, { FunctionComponent } from 'react';
import * as Components from './HistoryModalBody.styled';
import { HistoryEntryType } from '../../@types/History/HistoryEntryType';
import HistoryEntry from './HistoryEntry';

interface OwnProps {
  entries: HistoryEntryType[];
}

type Props = OwnProps;

const HistoryModalBody: FunctionComponent<Props> = ({ entries, ...props }) => {
  return (
    <Components.Container>
      {entries.map((entry, idx) => (
        <HistoryEntry entry={entry} key={`entry-${idx}`} />
      ))}
    </Components.Container>
  );
};

export default HistoryModalBody;
