import React, { FunctionComponent } from 'react';
import FormPage from '../../../CapeMorris/components/form-page/form-page';
import VehicleForm from './VehicleForm/VehicleForm';

interface OwnProps {}

type Props = OwnProps;

const VehicleFormScreen: FunctionComponent<Props> = (props) => {
  return (
    <FormPage title={'Dodaj nowy samochód'} editTitle={'Edytuj samochód'}>
      {(methods, isEdit) => <VehicleForm methods={methods} isEdit={isEdit} />}
    </FormPage>
  );
};

export default VehicleFormScreen;
