import React, { FunctionComponent } from 'react';
import { AssetsForm } from '../../../../CapeMorris/components/AssetsForm';
import Form from '../../../../CapeMorris/components/form/form';
import ModalFooter from '../../../../CapeMorris/components/modal/ModalFooter';
import { Button } from '../../../../CapeMorris/components';
import COLORS from '../../../../assets/colors';
import { InventoryType } from '../../../../@types/Inventory/Inventory';
import { AssetFormType } from '../../../../CapeMorris/@types';
import ModalContainer from '../../../../CapeMorris/components/modal/use-modal-container';
import SnackContainer from '../../../../CapeMorris/components/snack-bar/use-snack-container';
import api from '../../../../CapeMorris/services/api';
import { removeEmptyIds } from '../../../../CapeMorris/helpers/Entity';
import { AxiosResponse } from 'axios';

interface OwnProps {
  inventory: InventoryType;
  onSubmit: (entity: InventoryType) => void;
}

type Props = OwnProps;

type ApiResponse = AxiosResponse<InventoryType>;

const InventoryAssetsModal: FunctionComponent<Props> = ({ inventory, ...props }) => {
  const [waiting, setWaiting] = React.useState(false);
  const { hideModal } = ModalContainer.useContainer();
  const { showSnack } = SnackContainer.useContainer();
  const handleSubmit = React.useCallback(
    (data: { assets: AssetFormType[] }) => {
      setWaiting(true);
      let assets: any = [...inventory.assets.map((el) => el['@id']), ...data.assets];
      removeEmptyIds(assets);
      api
        .put(inventory['@id'].substr(4), {
          assets: assets,
        })
        .then((response: ApiResponse) => {
          props.onSubmit(response.data);
          hideModal();
          setWaiting(false);
          showSnack({
            title: 'Sukces',
            message: 'Dodano pliki',
          });
        })
        .catch(() => {
          setWaiting(false);
          hideModal();
          showSnack({
            type: 'error',
            title: 'Błąd',
            message: 'Wystąpił problem podczas dodawania plików',
          });
        });
    },
    [inventory],
  );
  return (
    <Form onSubmit={handleSubmit} defaultValues={{}}>
      <AssetsForm name="assets" />
      <ModalFooter>
        <Button text={'Zapisz'} color={COLORS.GREEN.NORMAL} type={'submit'} waiting={waiting} />
      </ModalFooter>
    </Form>
  );
};

export default InventoryAssetsModal;
