import React from 'react';
import { FormError, FormInput, FormInputWrapper, FormLabel, FormWrapper } from '../form.styled';
import { InputType } from '../../../@types';
import { useFormContext } from 'react-hook-form';
import { ERROR_MESSAGES } from '../error-messages';
import { FormHelper } from '../../../helpers/FormHelper';

type TextInputProps = InputType & {
  password?: boolean;
};

export const TextInput: React.FC<TextInputProps> = ({
  name,
  inputRef,
  password = false,
  label,
  placeholder,
  disabled = false,
  style,
  defaultValue,
  required = false,
  onChange,
}) => {
  const [status, setStatus] = React.useState<string>('pristine');
  const formMethods = useFormContext();
  const [error, setError] = React.useState<string | undefined>();
  const onFocus = () => setStatus('focus');
  const onBlur = () => {
    setStatus('pristine');
  };

  React.useEffect(() => {
    if (status === 'focus') {
      return;
    } else if (error) {
      setStatus('error');
    } else if (disabled) {
      setStatus('disabled');
    } else {
      setStatus('pristine');
    }
  }, [error, status, disabled]);

  React.useEffect(() => {
    if (!required) {
      return;
    }
    const error = FormHelper.checkError(formMethods.formState.errors, name);
    setError(error);
  }, [formMethods.formState, name, required]);

  return (
    <FormWrapper htmlFor={name} style={style}>
      <FormInputWrapper status={status}>
        <FormLabel status={status} required={required}>
          {label ? label : name}
        </FormLabel>
        <FormInput
          status={status}
          type={password ? 'password' : 'text'}
          name={name}
          id={name}
          onInput={(event) => (onChange ? onChange(formMethods.getValues(name)) : null)}
          ref={
            formMethods
              ? formMethods.register({ required: required && ERROR_MESSAGES.required })
              : inputRef
          }
          placeholder={placeholder ? placeholder : label}
          disabled={disabled}
          defaultValue={defaultValue}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </FormInputWrapper>
      {error && <FormError>{error}</FormError>}
    </FormWrapper>
  );
};
