import styled from 'styled-components';
import COLORS from '../../../assets/colors';
import { WEIGHTS } from '../../../assets/fonts';

type TagProps = {
  color: string;
};

export const TagContainer = styled.span<TagProps>`
  display: inline-block;
  background-color: ${(props) => props.color};
  padding: 6px 10px;
  border-radius: 20px;
  color: ${COLORS.WHITE};
  text-transform: lowercase;
  font-weight: ${WEIGHTS.BOLD};
  font-size: 11px;
  line-height: 120%;
  margin: auto 2px;
`;
