import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import Switch from '../../form/switch/Switch';

interface OwnProps {
  defaultValue?: boolean | undefined;
  name: string;
  label: string;
  onChange: (value: any) => void;
  size: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
}

type Props = OwnProps;

const SwitchFilter: FunctionComponent<Props> = (props) => {
  return (
    <Grid item md={props.size}>
      <Switch name={`filters.${props.name}`} label={props.label} onChange={props.onChange} />
    </Grid>
  );
};

export default SwitchFilter;
