import styled from 'styled-components';
import COLORS, { COLORS_BY_SENTIMENT } from '../../../assets/colors';
import { WEIGHTS } from '../../../assets/fonts';

type PageButtonType = {
  active?: boolean;
};

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 20px 15px;
`;

export const PageButton = styled.div<PageButtonType>`
  background-color: ${(p) =>
    p.active ? COLORS_BY_SENTIMENT.PRIMARY.DARKEST : COLORS_BY_SENTIMENT.PRIMARY.NORMAL};
  font-weight: ${WEIGHTS.BOLD};
  width: 25px;
  height: 25px;
  color: ${COLORS.WHITE};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(p) => (p.active ? 'normal' : 'pointer')};
  margin-right: 5px;
  &:hover {
    background-color: ${COLORS_BY_SENTIMENT.PRIMARY.DARKEST};
  }
`;
