import styled, { css } from 'styled-components';
import COLORS, { COLORS_BY_FUNCTION, COLORS_BY_SENTIMENT } from '../../../assets/colors';
import { WEIGHTS } from '../../../assets/fonts';

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const HeaderContainer = styled.div`
  padding: 25px 30px;
  border-bottom: 1px solid ${COLORS_BY_FUNCTION.BORDER};
  background-color: ${COLORS.WHITE};
  z-index: 14;
  @media screen and (max-width: 1440px) {
    padding: 15px 30px;
  }
`;

export const LayoutInline = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

type MenuProps = {
  closed?: boolean;
};
export const MenuContainer = styled.div<MenuProps>`
  position: relative;
  padding: 30px 14px;

  ${(props) =>
    props.closed &&
    css`
      padding: 30px 0;
      width: 0;
      overflow: hidden;
    `};

  transition: all 0.2s ease;
  background-color: ${COLORS_BY_SENTIMENT.PRIMARY.NORMAL};
`;

export const ContentContainer = styled.div`
  flex-grow: 2;
`;

type RowProps = {
  justify?: 'left' | 'space-between' | 'right';
};

export const Row = styled.div<RowProps>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: ${(props) => (props.justify ? props.justify : 'space-between')};
  align-items: center;
`;

type ColumnProps = {
  size?: number | null | undefined;
  right?: boolean;
};

export const Column = styled.div<ColumnProps>`
  width: ${(props) => (props.size ? props.size * 100 - 2 + '%' : '100%')};
  ${(props) =>
    props.right &&
    css`
      align-self: flex-end;
    `}
`;

export const Solid = styled.span`
  font-weight: ${WEIGHTS.BOLD};
`;

export type ColoredProps = {
  color?: string;
};

export const Colored = styled.span<ColoredProps>`
  color: ${(props) => (props.color ? props.color : COLORS_BY_SENTIMENT.PRIMARY.NORMAL)};
`;
