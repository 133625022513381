import React from 'react';
import { LoginFormContainer, LogoWrapper, Wrapper } from './login.styled';
import { Button, Logo } from '../../CapeMorris/components';
import COLORS from '../../assets/colors';
import LoginForm from './LoginForm';
import ForgotPassword from './ForgotPassword';
import ChangePassword from './ChangePassword';

export const Login: React.FC = () => {
  const [currentForm, setCurrentForm] =
    React.useState<'login' | 'forgot-password' | 'change-password'>('login');
  return (
    <Wrapper>
      <LoginFormContainer>
        <LogoWrapper>
          <Logo width={200} />
        </LogoWrapper>
        {currentForm === 'login' && <LoginForm />}
        {currentForm === 'forgot-password' && <ForgotPassword setCurrentForm={setCurrentForm} />}
        {currentForm === 'change-password' && <ChangePassword setCurrentForm={setCurrentForm} />}
        {currentForm === 'login' && (
          <Button
            style={{ marginTop: 30 }}
            inverted
            wide
            onClick={() => setCurrentForm('forgot-password')}
            color={COLORS.BLUE.NORMAL}
            text={'Forgot my password'}
          />
        )}
        {currentForm !== 'login' && (
          <Button
            style={{ marginTop: 30 }}
            inverted
            wide
            onClick={() => setCurrentForm('login')}
            color={COLORS.BLUE.NORMAL}
            text={'Log in'}
          />
        )}
        {currentForm === 'forgot-password' && (
          <Button
            style={{ marginTop: 30 }}
            inverted
            wide
            onClick={() => setCurrentForm('change-password')}
            color={COLORS.BLUE.NORMAL}
            text={'I have atoken'}
          />
        )}
      </LoginFormContainer>
    </Wrapper>
  );
};
