import styled from 'styled-components';

export const Container = styled.div`
  & > .cc-icon {
    transition: transform 0.2s ease-out;
  }
  & > .cc-icon:hover {
    transform: scale(1.1);
  }
  & > .cc-icon:active {
    transform: scale(0.9);
  }
`;
