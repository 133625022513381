import React from 'react';
import { ActiveIndicator, ActiveLink, Container, Links } from './menu.styled';
import { MenuItemProps } from './menu-item';
import { Link, useLocation } from 'react-router-dom';

type MenuProps = {
  items: React.ReactElement<MenuItemProps>[];
  linkOnMouseEnter: (item: React.ReactElement<MenuItemProps>) => void;
  linkOnClick: (item: React.ReactElement<MenuItemProps>) => void;
};

export const Menu: React.FC<MenuProps> = ({ items, linkOnMouseEnter, linkOnClick }) => {
  const location = useLocation();
  const [renderItems, setRenderItems] = React.useState(items);
  const [activeIndex, setActiveIndex] = React.useState(0);

  React.useEffect(() => {
    setRenderItems(
      items.map((item, index) => {
        if (
          (item.props.to === '/' && location.pathname === item.props.to) ||
          (item.props.to !== '/' && location.pathname.startsWith(item.props.to))
        ) {
          setActiveIndex(index);
          return (
            <Link key={item.props.name} to={item.props.to}>
              <ActiveLink
                key={item.props.name}
                onMouseEnter={() => linkOnMouseEnter(item)}
                onClick={() => linkOnClick(item)}>
                {item}
              </ActiveLink>
            </Link>
          );
        }
        return (
          <Link
            onClick={() => linkOnClick(item)}
            key={item.props.name}
            to={item.props.to}
            onMouseEnter={() => linkOnMouseEnter(item)}>
            {item}
          </Link>
        );
      }),
    );
  }, [location, items, linkOnClick, linkOnMouseEnter]);

  return (
    <Container>
      <ActiveIndicator style={{ zIndex: 1 }} index={activeIndex} />
      <Links style={{ zIndex: 10 }}>{renderItems}</Links>
    </Container>
  );
};
