import React, { CSSProperties } from 'react';
import { TagContainer } from './tag.styled';
import { COLORS_BY_SENTIMENT } from '../../../assets/colors';

type TagProps = {
  color?: string;
  style?: CSSProperties;
  onClick?: () => void;
};

export const Tag: React.FC<TagProps> = ({
  children,
  color = COLORS_BY_SENTIMENT.PRIMARY.NORMAL,
  style,
  onClick,
}) => {
  return (
    <TagContainer onClick={onClick} style={style} color={color}>
      {children}
    </TagContainer>
  );
};
