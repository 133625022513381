import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import { Header, NumberInput, TextareaInput, TextInput } from '../../../../CapeMorris/components';
import { UseFormMethods } from 'react-hook-form';
import api from '../../../../CapeMorris/services/api';
import { AxiosResponse } from 'axios';
import { Vehicle } from '../../../../@types/vehicle';
import VehicleForm from '../../Vehicle/VehicleForm/VehicleForm';
import Select from '../../../../CapeMorris/components/form/select/select';
import MoneyInput from '../../../../CapeMorris/components/form/money-input/MoneyInput';
import InventoryCustomerForm from './InventoryCustomerForm';
import { AssetsForm } from '../../../../CapeMorris/components/AssetsForm';
import { ServiceTypes } from '../../../../@types/Inventory/ServiceType';
import AssetSelect from '../../../../CapeMorris/components/asset-select/AssetSelect';
import DatePicker from '../../../../CapeMorris/components/form/DatePicker/DatePicker';

interface OwnProps {
  methods: UseFormMethods<any>;
  isEdit: boolean;
}

type Props = OwnProps;

const InventoryForm: FunctionComponent<Props> = ({ methods, isEdit }) => {
  const vinWatcher = methods.watch('_vin');
  const ownership = methods.watch('ownership');
  const vehicleWatcher = methods.watch('vehicle');
  const [vehicle, setVehicle] = React.useState<Vehicle | undefined | null>();

  React.useEffect(() => {
    if (!vinWatcher) {
      setVehicle(undefined);
    }
    if (isEdit || !vinWatcher) {
      return;
    }
    let timeout = setTimeout(async () => {
      void api
        .get('/vehicles', {
          params: { vin: vinWatcher },
        })
        .then((response: AxiosResponse<{ 'hydra:member': any }>) => {
          const data = response.data['hydra:member'];
          if (data.length > 0) {
            return data[0];
          }
          return null;
        })
        .then((vehicle: Vehicle | null) => {
          if (vehicle !== null) {
            methods.unregister(`vehicle.vin`);
            methods.unregister(`vehicle.make`);
            methods.unregister(`vehicle.model`);
            methods.unregister(`vehicle.year`);
            methods.unregister(`vehicle.originCountry`);
            methods.unregister(`vehicle.bodyType`);
            methods.unregister(`vehicle.driveType`);
            methods.unregister(`vehicle.engine`);
            methods.unregister(`vehicle.transmission`);
            methods.unregister(`vehicle.exteriorColor`);
            methods.unregister(`vehicle.interiorColor`);
          }
          setVehicle(vehicle);
        });
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [vinWatcher]);

  return (
    <Grid container spacing={4}>
      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Header header2>Dane samochodu</Header>
          </Grid>
          <Grid item md={6}>
            <TextInput
              name="_vin"
              label="VIN"
              placeholder="ZARYNFLWHPDTZ7856"
              required
              disabled={isEdit}
              defaultValue={isEdit ? vehicleWatcher.vin : undefined}
            />
          </Grid>
          {vehicle && (
            <>
              <Grid item md={6}>
                Samochód {vehicle.make} {vehicle.model}.
              </Grid>
              <Grid item md={6}>
                <input type="hidden" name="vehicle" value={vehicle['@id']} ref={methods.register} />
              </Grid>
            </>
          )}
          {vehicle === null && (
            <Grid item md={12}>
              <VehicleForm prefix="vehicle." vin={vinWatcher} />
            </Grid>
          )}
          <Grid item md={12}>
            <Header header2>Inne dane kontraktu</Header>
          </Grid>
          <Grid item md={6}>
            <AssetSelect load={false} name="mainPhoto" label="Obrazek wyróżniający" />
          </Grid>
          <Grid item md={6}>
            <NumberInput name={`mileage`} label="Przebieg" placeholder="1 548" suffix="km" />
          </Grid>
          <Grid item md={6}>
            <DatePicker
              name={`examinationDate`}
              label="Następny przegląd techniczny"
              placeholder="2022-01-01"
            />
          </Grid>
          <Grid item md={6}>
            <DatePicker
              name={`insuranceDate`}
              label="Ubezpieczenie ważne do"
              placeholder="2022-01-01"
            />
          </Grid>
          <Grid item md={6}>
            <Select name="employee" baseUrl={'/users'} label="Opiekun" required />
          </Grid>
          <Grid item md={6}>
            <Select
              name="ownership"
              label="Typ własności"
              options={[
                { value: 'own', label: 'Cars Collection' },
                { value: 'customer', label: 'Umowa komisowa' },
              ]}
              required
            />
          </Grid>
          <Grid item md={6}>
            <Select name="location" baseUrl={'/locations'} label="Miejsce postoju" />
          </Grid>
          <Grid item md={6}>
            <NumberInput name="position" label="Numer miejsca" placeholder="ex. 112" />
          </Grid>
          <Grid item md={6}>
            <TextInput
              name="plates"
              label="Numery rejestracyjne"
              placeholder="XX 123456"
              required
            />
          </Grid>
          <Grid item md={6}>
            <TextInput name="dekraCertificate" label="Certyfikat DEKRA" placeholder="111111111" />
          </Grid>
          <Grid item md={6}>
            <MoneyInput name="purchasePrice" label="Cena zakupu" required />
          </Grid>
          <Grid item md={6}>
            <MoneyInput name="salesPrice" label="Cena sprzedaży" required />
          </Grid>
          <Grid item md={6}>
            <Select
              name="salesType"
              label="Rodzaj sprzedaży"
              options={[
                { value: 'vat_23', label: 'VAT 23%' },
                { value: 'vat_margin', label: 'VAT Marża' },
                { value: 'leasing_transfer', label: 'Transfer leasingu' },
              ]}
              required
            />
          </Grid>
          <Grid item md={6}>
            <Select name="serviceType" label="Serwis" options={ServiceTypes} />
          </Grid>
          {ownership === 'customer' && (
            <Grid item md={12}>
              <InventoryCustomerForm />
            </Grid>
          )}
          <Grid item md={12} style={{ marginTop: 20 }}>
            <TextareaInput name="internalComment" label="Komentarz" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6}>
        <Header header2>Pliki</Header>
        <AssetsForm name={`assets`} />
      </Grid>
    </Grid>
  );
};

export default InventoryForm;
