import React, { FunctionComponent } from 'react';
import FormPage from "../../../CapeMorris/components/form-page/form-page";
import { Grid } from "@material-ui/core";
import { Header, TextInput } from "../../../CapeMorris/components";

interface OwnProps {}

type Props = OwnProps;

const LocationFormScreen: FunctionComponent<Props> = (props) => {
  return (
    <FormPage title={'Dodaj nowego klienta'} editTitle={'Edytuj klienta'} >
      {(methods, isEdit) => (
        <Grid container spacing={2}>
          <Grid item md={12}>
            <TextInput name="name" label="Nazwa" placeholder="Plac XYZ" required/>
          </Grid>
          <Grid item md={12}>
            <Header header2>Adres</Header>
          </Grid>
          <Grid item md={4}>
            <TextInput name="address.street" label="Ulica" placeholder="Niedźwiedzia 29A" required/>
          </Grid>
          <Grid item md={4}>
            <TextInput name="address.postCode" label="Kod pocztowy" placeholder="00-001" required/>
          </Grid>
          <Grid item md={4}>
            <TextInput name="address.city" label="Miasto" placeholder="Warszawa" required/>
          </Grid>
        </Grid>
      )}
    </FormPage>);
};

export default LocationFormScreen;
