import React from 'react';
import { COLORS_BY_SENTIMENT } from '../../../assets/colors';

type LogoProps = {
  width?: number;
  color?: string;
};

export const Logo: React.FC<LogoProps> = ({
  width = 36,
  color = COLORS_BY_SENTIMENT.PRIMARY.NORMAL,
}) => {
  return (
    <svg width={width} viewBox="0 0 195.1 12.6">
      <g>
        <path
          stroke={color}
          fill={color}
          d="M9.2,7.2l3.4,0.7c-0.5,2.9-2.9,4.7-6.2,4.7C2.8,12.6,0,9.8,0,6.3S2.8,0,6.5,0c3,0,5.6,1.8,6.2,4.6L9.2,5.3
		C8.9,3.9,7.9,3.1,6.5,3.1c-1.7,0-3,1.4-3,3.2s1.3,3.2,3,3.2C7.9,9.5,8.9,8.6,9.2,7.2z"
        />
        <path
          stroke={color}
          fill={color}
          d="M23.7,12.3L23,10.5h-4.7l-0.7,1.8h-3.7l4.8-12h3.8l4.8,12H23.7z M20.7,4l-1.3,3.6H22L20.7,4z"
        />
        <path
          stroke={color}
          fill={color}
          d="M36.8,12.3l-3.3-3.8H33v3.8h-3.4v-12H36c2.6,0,4.3,1.6,4.3,4c0,1.9-1.1,3.4-2.9,3.9l3.5,4.1H36.8z M35.3,5.7
		c1,0,1.6-0.5,1.6-1.3c0-0.8-0.6-1.3-1.6-1.3H33v2.6H35.3z"
        />
        <path
          stroke={color}
          fill={color}
          d="M53.1,3.5l-3.4,0.7C49.5,3,48.6,2.7,48,2.7c-0.8,0-1.3,0.4-1.3,1c0,0.3,0.1,0.6,0.7,0.8L49.8,5c2.2,0.6,3.4,1.6,3.4,3.6
		c0,2.8-2.6,4-5.1,4c-2.9,0-5.1-1.4-5.5-3.7l3.5-0.7c0.3,1.1,1,1.6,2,1.6c0.8,0,1.4-0.3,1.4-1c0-0.4-0.2-0.6-0.8-0.8l-2.5-0.6
		c-2-0.5-3.3-1.5-3.3-3.5C43,1.4,45.1,0,48,0S52.7,1.3,53.1,3.5z"
        />
        <path
          stroke={color}
          fill={color}
          d="M70.2,7.2l3.4,0.7c-0.5,2.9-2.9,4.7-6.2,4.7c-3.6,0-6.5-2.8-6.5-6.3S63.8,0,67.4,0c3,0,5.6,1.8,6.2,4.6l-3.4,0.7
		c-0.3-1.4-1.3-2.2-2.7-2.2c-1.7,0-3,1.4-3,3.2s1.3,3.2,3,3.2C68.8,9.5,69.8,8.6,70.2,7.2z"
        />
        <path
          stroke={color}
          fill={color}
          d="M89,6.3c0,3.5-2.8,6.3-6.5,6.3c-3.6,0-6.5-2.8-6.5-6.3C76,2.8,78.8,0,82.5,0C86.1,0,89,2.8,89,6.3z M85.5,6.3
		c0-1.8-1.3-3.2-3-3.2s-3,1.4-3,3.2c0,1.8,1.3,3.2,3,3.2S85.5,8.1,85.5,6.3z"
        />
        <path stroke={color} fill={color} d="M100.8,12.3h-9.1v-12h3.4v9.1h5.7V12.3z" />
        <path stroke={color} fill={color} d="M112.6,12.3h-9.1v-12h3.4v9.1h5.7V12.3z" />
        <path
          stroke={color}
          fill={color}
          d="M124.8,12.3h-9.6v-12h9.5v2.9h-6v1.8h3.2v2.6h-3.2v1.8h6.1V12.3z"
        />
        <path
          stroke={color}
          fill={color}
          d="M136.3,7.2l3.4,0.7c-0.5,2.9-2.9,4.7-6.2,4.7c-3.6,0-6.5-2.8-6.5-6.3S130,0,133.6,0c3,0,5.6,1.8,6.2,4.6l-3.4,0.7
		c-0.3-1.4-1.3-2.2-2.7-2.2c-1.7,0-3,1.4-3,3.2s1.3,3.2,3,3.2C135,9.5,136,8.6,136.3,7.2z"
        />
        <path stroke={color} fill={color} d="M148.8,3.2v9.1h-3.5V3.2h-3.8v-3h11v3H148.8z" />
        <path stroke={color} fill={color} d="M155.1,12.3v-12h3.4v12H155.1z" />
        <path
          stroke={color}
          fill={color}
          d="M174.2,6.3c0,3.5-2.8,6.3-6.5,6.3c-3.6,0-6.5-2.8-6.5-6.3c0-3.5,2.8-6.3,6.5-6.3C171.4,0,174.2,2.8,174.2,6.3z M170.7,6.3
		c0-1.8-1.3-3.2-3-3.2c-1.7,0-3,1.4-3,3.2c0,1.8,1.3,3.2,3,3.2C169.5,9.5,170.7,8.1,170.7,6.3z"
        />
        <path
          stroke={color}
          fill={color}
          d="M188.8,12.3h-2.9l-5.5-6.2v6.2h-3.4v-12h2.8l5.5,6.4V0.3h3.5V12.3z"
        />
        <path
          stroke={color}
          fill={color}
          d="M195.1,10.5c0,1-0.8,1.8-1.9,1.8s-1.9-0.8-1.9-1.8c0-1,0.8-1.9,1.9-1.9S195.1,9.6,195.1,10.5z"
        />
      </g>
    </svg>
  );
};
