import styled from 'styled-components';
import COLORS, { COLORS_BY_SENTIMENT } from '../../../../assets/colors';

export const Container = styled.div`
  color: ${COLORS.WHITE};
  position: relative;
  z-index: 11;
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-between;
  position: relative;
`;

export const ActiveLink = styled.div`
  svg {
    path {
      stroke: white;
      fill: white;
    }
  }
`;

type ActiveIndicatorProps = {
  index: number;
};

export const ActiveIndicator = styled.div<ActiveIndicatorProps>`
  background-color: ${COLORS_BY_SENTIMENT.PRIMARY.DARKEST};
  width: 52px;
  height: 52px;
  border-radius: 10px;
  position: absolute;
  top: ${(props) => props.index * 76}px;
  transition: top 0.2s ease-out;
`;
