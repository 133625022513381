import React, { ComponentType, FunctionComponent } from 'react';
import { DictValue } from '../../../@types/dictionary/dictionary';
import { InputType } from '../../../@types';
import { Container } from './select.styled';
import { useFormContext } from 'react-hook-form';
import { FormError, FormInputWrapper, FormLabel, FormWrapper } from '../form.styled';
import { ERROR_MESSAGES } from '../error-messages';
import { CreatableSelect, SelectWithBaseUrl, SelectWithOptions } from './internal-components';
import { FormHelper } from '../../../helpers/FormHelper';
import api from '../../../services/api';
import FormContent from '../form-content';
import { FormControlLabel } from '@material-ui/core';

export type SelectProps = InputType & {
  baseUrl?: string;
  options?: DictValue[];
  optionLabel?: string;
  optionValue?: string;
  isMulti?: boolean;
  onChange?: (option: any) => void;
  optionComponent?: ComponentType<any>;
  creatable?: boolean;
  onCreate?: (value: string, label: string) => void;
  selectStyles?: (hasError: boolean | undefined, required?: boolean) => any;
};

const Select: FunctionComponent<SelectProps> = ({
  placeholder,
  label,
  baseUrl,
  options,
  optionLabel = 'name',
  name,
  isMulti = false,
  required,
  onChange,
  optionComponent,
  creatable = false,
  optionValue = '@id',
  onCreate,
  ...props
}) => {
  const formMethods = useFormContext();
  const [defaultOptions] = React.useState<DictValue[]>([]);
  const watchValue = formMethods.watch(name);
  const [ready, setReady] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | undefined>();

  React.useEffect(() => {
    if (!isMulti) {
      console.log('register', name);
      formMethods.register(name, { required: required && ERROR_MESSAGES.required });
    } else {
      formMethods.register(name);
    }
  }, [name, formMethods.register, required]);

  const handleChange = React.useCallback(
    (value: any) => {
      if (!value) {
        formMethods.setValue(name, undefined);
        if (onChange) {
          onChange(undefined);
        }
        return;
      }
      if (value['__isNew__'] && baseUrl) {
        const data: any = {};
        data[optionLabel] = value.value;
        if (onCreate) {
          const item = onCreate(value.value, value.label);
          handleChange(item);
        } else {
          void api.post(baseUrl, data).then((response) => {
            const item = response.data;
            const value = {
              value: item[optionValue],
              label: item[optionLabel],
              meta: item,
            };
            handleChange(value);
          });
        }
        return;
      }
      if (onChange) {
        onChange(value);
      }
      if (!Array.isArray(value)) {
        formMethods.setValue(name, value.value);
        formMethods.clearErrors(name);
        return;
      }

      if (watchValue) {
        watchValue.forEach((el: any, index: number) => {
          formMethods.setValue(`${name}[${index}]`, '');
        });
      }
      value.forEach((element: any, index: number) => {
        if (element['__isNew__'] && baseUrl) {
          const data: any = {};
          data[optionLabel] = element.value;
          void api.post(baseUrl, data).then((response) => {
            const item = response.data;
            const newElement = {
              value: item[optionValue],
              label: item[optionLabel],
              meta: item,
            };
            value.splice(index, 1, newElement);
            handleChange(value);
          });
        }
        formMethods.register(`${name}[${index}]`);
        formMethods.setValue(`${name}[${index}]`, element.value);
        formMethods.clearErrors(name);
      });
    },
    [formMethods, watchValue, onChange, name, baseUrl, isMulti],
  );

  React.useEffect(() => {
    if (ready) {
      return;
    }
    if (watchValue === undefined) {
      setReady(true);
      return;
    }
    if (Array.isArray(watchValue)) {
      const values = watchValue.map((item: { '@id': string } & any) => {
        if (typeof item === 'string') {
          return item;
        }
        if (item.hasOwnProperty(optionValue)) {
          const value = {
            value: item[optionValue],
            label: item[optionLabel],
            meta: item,
          };
          defaultOptions.push(value);
          return value;
        } else {
          throw new Error(`Nie znaleziono ${optionLabel} w selekcie ${name}`);
        }
      });
      handleChange(values);
    } else {
      if (watchValue && watchValue.hasOwnProperty(optionValue)) {
        formMethods.setValue(name, watchValue[optionValue]);
        const value = {
          value: watchValue[optionValue],
          label: watchValue[optionLabel],
          meta: watchValue,
        };
        defaultOptions.push(value);
      } else if (watchValue && options) {
        const filtered = options.filter((o: DictValue) => o.value === watchValue);
        defaultOptions.push(filtered[0]);
      } else if (baseUrl && typeof watchValue === 'string') {
        defaultOptions.push({ value: watchValue, label: watchValue });
      }
    }
    setReady(true);
  }, [
    watchValue,
    setReady,
    formMethods,
    defaultOptions,
    name,
    optionLabel,
    options,
    ready,
    handleChange,
  ]);

  React.useEffect(() => {
    if (!required) {
      return;
    }
    const error = FormHelper.checkError(formMethods.formState.errors, name);
    setError(error);
  }, [formMethods.formState, name, required]);

  return (
    <>
      <FormLabel status={''} required={required}>
        {label}
      </FormLabel>
      {ready && options && (
        <SelectWithOptions
          optionComponent={optionComponent}
          name={name}
          isMulti={isMulti}
          placeholder={placeholder ? placeholder : label}
          optionLabel={optionLabel}
          optionValue={optionValue}
          error={error}
          defaultValue={watchValue ? (isMulti ? defaultOptions : defaultOptions[0]) : null}
          onChange={handleChange}
          options={options}
          required={required}
          selectStyles={props.selectStyles}
        />
      )}
      {ready && baseUrl && !creatable && (
        <SelectWithBaseUrl
          optionComponent={optionComponent}
          name={name}
          placeholder={placeholder ? placeholder : label}
          error={error}
          isMulti={isMulti}
          defaultValue={watchValue ? (isMulti ? defaultOptions : defaultOptions[0]) : null}
          optionLabel={optionLabel}
          optionValue={optionValue}
          baseUrl={baseUrl}
          onChange={handleChange}
          required={required}
          selectStyles={props.selectStyles}
        />
      )}
      {ready && baseUrl && creatable && (
        <CreatableSelect
          optionComponent={optionComponent}
          name={name}
          placeholder={placeholder ? placeholder : label}
          error={error}
          isMulti={isMulti}
          defaultValue={watchValue ? (isMulti ? defaultOptions : defaultOptions[0]) : null}
          optionLabel={optionLabel}
          optionValue={optionValue}
          baseUrl={baseUrl}
          onChange={handleChange}
          required={required}
          selectStyles={props.selectStyles}
        />
      )}
      {error && <FormError>{error}</FormError>}
    </>
  );
};

export default Select;
