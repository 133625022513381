import React, { FunctionComponent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

interface OwnProps {
  onSubmit: (data: any) => any;
  defaultValues: any;
}

type Props = OwnProps;

const Form: FunctionComponent<Props> = (props) => {
  const methods = useForm({ defaultValues: props.defaultValues, shouldUnregister: true });

  return (
    <FormProvider {...methods}>
      <form
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
        onSubmit={methods.handleSubmit(props.onSubmit)}>
        {props.children}
      </form>
    </FormProvider>
  );
};

export default Form;
