import React, { FunctionComponent } from 'react';
import { AssetFormType } from '../../@types';
import { useFormContext } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import { TextInput } from '../form/text-input/text-input';
import * as Components from './AssetForm.styled';
import Select from '../form/select/select';
interface OwnProps {
  name: string;
  defaultValue: AssetFormType;
}

type Props = OwnProps;

const AssetForm: FunctionComponent<Props> = ({ name, ...props }) => {
  const [fileName, setFileName] = React.useState('');

  React.useEffect(() => {
    setFileName(
      props.defaultValue.asset._file
        ? props.defaultValue.asset._file.name
        : props.defaultValue.asset.file
        ? props.defaultValue.asset.file.originalName
        : 'some error',
    );
  }, []);
  const { register } = useFormContext();
  return (
    <Grid item md={4}>
      <Components.Container>
        <input
          type="hidden"
          name={`${name}.asset.uploadedFile`}
          ref={register}
          defaultValue={props.defaultValue.asset.uploadedFile}
        />
        <input
          type="hidden"
          name={`${name}.id`}
          ref={register}
          defaultValue={props.defaultValue['@id']}
        />
        <input
          type="hidden"
          name={`${name}.asset.id`}
          ref={register}
          defaultValue={props.defaultValue.asset['@id']}
        />
        <Grid container spacing={2}>
          <Grid item md={12}>
            {fileName}
          </Grid>
          <Grid item md={12}>
            <TextInput
              name={`${name}.asset.name`}
              label="Nazwa"
              placeholder="np. Packshot przedstawiający wróbla"
              required
              defaultValue={props.defaultValue.asset.name}
            />
          </Grid>
          <Grid item md={12}>
            <TextInput
              name={`${name}.asset.description`}
              label="Short description"
              placeholder="Krótki opis, co zawiera plik"
              required
              defaultValue={props.defaultValue.asset.description}
            />
          </Grid>
          <Grid item md={12}>
            <Select
              label="Tagi"
              placeholder="Wybierz jeden lub kilka tagów"
              name={`${name}.asset.tags`}
              baseUrl={'/digital_asset_tags'}
              optionLabel="name"
              isMulti
              creatable
            />
          </Grid>
        </Grid>
      </Components.Container>
    </Grid>
  );
};

export default AssetForm;
