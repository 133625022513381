import React from 'react';
import { Button, Header, Tag, TextInput, NumberInput, TextareaInput } from '../../CapeMorris/components';
import Select from '../../CapeMorris/components/form/select/select';
import { Icon } from '../../CapeMorris/components';
import { HomeIcon } from '../../CapeMorris/icons/home.icon';
import { COLORS_BY_SENTIMENT } from '../../assets/colors';
import ModalContainer from '../../CapeMorris/components/modal/use-modal-container';
import { PageBase } from '../../CapeMorris/components/layout/page/page';
import { Loader } from '../../CapeMorris/components/loader/loader';

export const InterfaceGuide: React.FC = () => {
  const { showModal } = ModalContainer.useContainer();
  const onShowModal = () => {
    const modalBody = (
      <div>
        Some modal body with button.
        <Button text={'Modal button'} />
      </div>
    );
    showModal({
      title: <Header>Modal title</Header>,
      body: modalBody,
      footer: 'footer',
    });
  };

  return (
    <PageBase>
      <div>
        <div>
          <Header>Select</Header> <br />
          <Select name="employee" baseUrl="/employees" />
          <br />
          <Select name="employee" baseUrl="/employees" />
          <br />
        </div>
        <div>
          <Header>TextInputs</Header> <br />
          <TextareaInput
            name={'textarea1'}
            label={'Type here'}
            placeholder={'ex. ingredients'}
            rows={10}
          />
          <br />
          <TextareaInput
            name={'textarea1'}
            label={'Type here'}
            placeholder={'ex. ingredients'}
            disabled={true}
          />
          <br />
          <TextInput name={'input1'} label={'Label'} /> <br />
          <TextInput name={'input2'} label={'Nazwa inputu'} placeholder={'np. placeholder'} />{' '}
          <br />
          <TextInput name={'input3'} label={'np. placeholder'} disabled={true} />
          <br />
          <NumberInput name={'input3'} label={'Value'} placeholder={'np. 123'} />
          <br />
          <NumberInput
            name={'input3'}
            label={'Value'}
            placeholder={'np. 123'}
            disabled={true}
          />{' '}
          <br />
        </div>

        <Header>Headers</Header>
        <Header>Main header H1</Header>
        <code>{'<Header>Main header H1</Header>'}</code>
        <Header header2>Secondary header H2</Header>
        <code>{'<Header header2>Secondary header H1</Header>'}</code>
        <Header header3>Tertiary header H3</Header>
        <code>{'<Header header3>Tertiary header H1</Header>'}</code>
      </div>
      <div>
        <Header>Icons</Header>
        <Icon
          style={{ margin: 20, padding: 10 }}
          icon={<HomeIcon />}
          size={50}
          color={COLORS_BY_SENTIMENT.ERROR.NORMAL}
        />
        <code>{`
          <Icon
            style={{ margin: 20, padding: 10 }}
            icon={<HomeIcon />}
            size={50}
            color={COLORS_BY_SENTIMENT.ERROR.NORMAL}
          />
          `}</code>
        <br />
        <br />
        <span>
          All icons should be placed in <code>./src/CapeMorris/icons</code> and contains pure SVG.
        </span>
      </div>
      <div>
        <Header>Buttons</Header>
        <Button text={'Simple button'} /> <br />
        <Button text={'Inverted button'} inverted /> <br />
        <Button text={'Wide button'} wide /> <br />
        <Button text={'Red button'} color={'red'} /> <br />
        <Button text={'Icon button'} icon={<HomeIcon />} /> <br />
        <Button text={'Inverted Icon button'} icon={<HomeIcon />} inverted />
        <br />
      </div>
      <div>
        <Header>Modal</Header>
        <Button onClick={onShowModal} text={'Open modal'} /> <br />
        <code>{"import ModalContainer from '../../components/modal/use-modal-container';"}</code>
        <br />
        <code>{'// ..'}</code>
        <br />
        <code>{'const { showModal } = ModalContainer.useContainer();'}</code>
        <br />
        <code>{'const onShowModal = () => {'}</code>
        <br />
        <code>
          &nbsp;&nbsp;&nbsp;&nbsp;
          {"showModal({ title: <Header>Modal title</Header>, body: 'test', footer: 'footer' });"}
        </code>
        <br />
        <code>{'};'}</code>
        <br />
        <code>{'// ..'}</code>
        <br />
        <code>{"<Button onClick=onShowModal text={'Open modal'} />"}</code>
        <br />
      </div>
      <div>
        <Header>Loader</Header>
        <div style={{ position: 'relative', width: 200, height: 200 }}>
          <Loader />
        </div>
        <code>{'<Loader />'}</code> has to be put in container positioned with{' '}
        <code>position: relative</code>
      </div>
      <div>
        <Header>Tags</Header>
        <Tag>this is primary tag</Tag>
        <Tag color={COLORS_BY_SENTIMENT.ERROR.NORMAL}>Red tag</Tag>
      </div>
    </PageBase>
  );
};
