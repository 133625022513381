import styled, { css } from 'styled-components';
import COLORS, { COLORS_BY_SENTIMENT } from '../../../assets/colors';

type LinkProps = {
  active?: boolean;
};

export const LinkText = styled.div<LinkProps>`
  padding: 12px 15px;
  margin: 5px 0;
  color: ${COLORS.GREY.DARKEST};
  text-decoration: none;
  text-underline: none;
  border-radius: 10px;
  transition: all 0.2s ease;
  &:hover {
    background-color: ${COLORS_BY_SENTIMENT.PRIMARY.DARKEST};
    color: ${COLORS.WHITE};
  }

  ${(props) =>
    props.active &&
    css`
      color: ${COLORS.WHITE};
      background-color: ${COLORS_BY_SENTIMENT.PRIMARY.DARKEST};
    `}

  @media (max-height: 750px) {
    padding: 8px 11px;
  }
`;

export const ActiveLinkText = styled.div`
  padding: 12px 15px;
  margin: 5px 0;
  text-decoration: none;
  text-underline: none;
  border-radius: 10px;
`;

export const SubmenuLinkGroup = styled.div`
  margin-bottom: 20px;
`;
