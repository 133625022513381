import React, { ComponentType, FunctionComponent } from 'react';
import COLORS, { COLORS_BY_SENTIMENT } from '../../../../assets/colors';
import { selectStyles } from './select.styles';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import api from '../../../services/api';
import { ArrayResponseType } from '../../../@types';
import { Colored } from '../../layout/layout.styled';
import AsyncCreatable from 'react-select/async-creatable';
import { DictValue } from '../../../@types/dictionary/dictionary';

interface OwnProps {
  isMulti: boolean;
  placeholder?: string;
  defaultValue: any;
  onChange: (value: any) => void;
  name: string;
  error?: string;
  optionComponent?: ComponentType<any>;
  baseUrl?: string;
  required?: boolean;
  optionLabel: string;
  optionValue: string;
  selectStyles?: (hasError: boolean | undefined, required?: boolean) => any;
}

type Props = OwnProps;

export const SelectWithOptions: FunctionComponent<Props & { options: DictValue[] }> = (props) => {
  const [components, setComponents] = React.useState<any>({
    IndicatorSeparator: () => null,
  });

  React.useEffect(() => {
    if (props.optionComponent) {
      setComponents((old: {}) => ({ ...old, Option: props.optionComponent }));
    }
  }, [props.optionComponent]);

  return (
    <Select
      menuPlacement={'auto'}
      options={props.options}
      isMulti={props.isMulti}
      name={props.name}
      placeholder={props.placeholder}
      noOptionsMessage={() => 'Start typing to search...'}
      loadingMessage={() => 'Searching...'}
      cacheOptions={true}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      components={components}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: COLORS_BY_SENTIMENT.PRIMARY.NORMAL,
          primary75: COLORS_BY_SENTIMENT.PRIMARY.LIGHT,
          primary50: COLORS_BY_SENTIMENT.PRIMARY.LIGHTEST,
          primary25: COLORS_BY_SENTIMENT.PRIMARY.LIGHTEST,
        },
      })}
      styles={
        props.selectStyles
          ? props.selectStyles(!!props.error, props.required)
          : selectStyles(!!props.error, props.required)
      }
    />
  );
};

export const SelectWithBaseUrl: FunctionComponent<Props> = (props) => {
  const [components, setComponents] = React.useState<{}>({
    IndicatorSeparator: () => null,
  });

  React.useEffect(() => {
    if (props.optionComponent) {
      setComponents((old: {}) => ({ ...old, Option: props.optionComponent }));
    }
  }, [props.optionComponent]);

  const loadOptions = (inputValue: string, callback: Function) => {
    if (!props.baseUrl) {
      callback([]);
      return;
    }
    api
      .get(props.baseUrl, { params: { _search: inputValue } })
      .then((response: ArrayResponseType) => {
        callback(
          response.data['hydra:member'].map((i) => {
            return { value: i[props.optionValue], label: i[props.optionLabel], meta: i };
          }),
        );
      });
  };

  return (
    <AsyncSelect
      menuPlacement={'auto'}
      isMulti={props.isMulti}
      name={props.name}
      placeholder={props.placeholder}
      noOptionsMessage={() => 'Start typing to search...'}
      loadingMessage={() => 'Searching...'}
      cacheOptions={true}
      loadOptions={loadOptions}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      components={components}
      defaultOptions
      isClearable={!props.required}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: COLORS_BY_SENTIMENT.PRIMARY.NORMAL,
          primary75: COLORS_BY_SENTIMENT.PRIMARY.LIGHT,
          primary50: COLORS_BY_SENTIMENT.PRIMARY.LIGHTEST,
          primary25: COLORS_BY_SENTIMENT.PRIMARY.LIGHTEST,
        },
      })}
      styles={
        props.selectStyles
          ? props.selectStyles(!!props.error, props.required)
          : selectStyles(!!props.error, props.required)
      }
    />
  );
};

export const CreatableSelect: FunctionComponent<Props> = (props) => {
  const [components, setComponents] = React.useState<{}>({
    IndicatorSeparator: () => null,
  });

  React.useEffect(() => {
    if (props.optionComponent) {
      setComponents((old: {}) => ({ ...old, Option: props.optionComponent }));
    }
  }, [props.optionComponent]);

  const loadOptions = (inputValue: string, callback: Function) => {
    if (!props.baseUrl) {
      callback([]);
      return;
    }
    api
      .get(props.baseUrl, { params: { _search: inputValue } })
      .then((response: ArrayResponseType) => {
        callback(
          response.data['hydra:member'].map((i) => {
            return { value: i[props.optionValue], label: i[props.optionLabel], meta: i };
          }),
        );
      });
  };

  return (
    <AsyncCreatable
      menuPlacement={'auto'}
      isMulti={props.isMulti}
      name={props.name}
      placeholder={props.placeholder}
      noOptionsMessage={() => 'Start typing to search...'}
      loadingMessage={() => 'Searching...'}
      cacheOptions={true}
      loadOptions={loadOptions}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      components={components}
      defaultOptions
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: COLORS_BY_SENTIMENT.PRIMARY.NORMAL,
          primary75: COLORS_BY_SENTIMENT.PRIMARY.LIGHT,
          primary50: COLORS_BY_SENTIMENT.PRIMARY.LIGHTEST,
          primary25: COLORS_BY_SENTIMENT.PRIMARY.LIGHTEST,
        },
      })}
      styles={
        props.selectStyles
          ? props.selectStyles(!!props.error, props.required)
          : selectStyles(!!props.error, props.required)
      }
    />
  );
};
