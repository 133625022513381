import { UserType } from '../../@types';
import React from 'react';
//todo: move to context in base-admin
export type AuthStateType = {
  user: UserType | null;
  state: 'LOADING' | 'REFRESH' | 'LOGGED_OUT' | 'LOGGED_IN' | 'INVALID_CREDENTIALS';
};

export type AuthContextType = {
  state: AuthStateType;
  dispatch: React.Dispatch<any>;
  authenticate: (username: string, password: string) => any;
  logout: () => void;
  can: (role: string) => boolean;
};

export const AuthContext = React.createContext<AuthContextType>({
  state: { user: null, state: 'LOADING' },
  dispatch: () => {},
  authenticate: () => {},
  logout: () => {},
  can: () => false,
});
