import React, { FunctionComponent } from 'react';
import { TextInput } from '../../../CapeMorris/components';
import FormPage from '../../../CapeMorris/components/form-page/form-page';
import { Grid } from '@material-ui/core';

interface OwnProps {}
type Props = OwnProps;

const UsersForm: FunctionComponent<Props> = (props) => {
  return (
    <FormPage editTitle="Edytuj użytkownika" title="Dodaj użytkownika">
      {(methods) => (
        <Grid container spacing={2}>
          <Grid item md={6}>
            <TextInput
              name={'username'}
              label={'Login użytkownika'}
              placeholder={'j.kowalski'}
              required={true}
            />
          </Grid>
          <Grid item md={6}>
            <TextInput
              name={'profile.email'}
              label={'Adres e-mail'}
              placeholder={'np. j.kowalski@example.com'}
              required={true}
            />
          </Grid>
          <Grid item md={6}>
            <TextInput
              name={'profile.firstName'}
              label={'Imię'}
              placeholder={'np. Jan'}
              required={true}
            />
          </Grid>
          <Grid item md={6}>
            <TextInput
              name={'profile.lastName'}
              label={'Nazwisko'}
              placeholder={'np. Kowalski'}
              required={true}
            />
          </Grid>
          <Grid item md={6}>
            <TextInput
              name={'profile.position'}
              label={'Stanowisko'}
              placeholder={'Księgowy'}
              required={true}
            />
          </Grid>
          <Grid item md={6}>
            <TextInput
              name={'profile.phoneNumber'}
              label={'Numer telefonu'}
              placeholder={'np. +48 000 000 000'}
            />
          </Grid>
        </Grid>
      )}
    </FormPage>
  );
};

export default UsersForm;
