import React, { FunctionComponent } from 'react';
import { Header } from '../../CapeMorris/components';
import SubmenuLink from '../../CapeMorris/components/submenu';

interface OwnProps {}

type Props = OwnProps;

const InventorySubmenu: FunctionComponent<Props> = (props) => {
  return (
    <>
      <Header header2>Na placu</Header>
      <SubmenuLink to={'/inventory'}>Lista samochodów</SubmenuLink>
      <Header header2>Baza</Header>
      <SubmenuLink to={'/inventory/vehicles'}>Wszystkie samochody</SubmenuLink>
      <SubmenuLink to={'/inventory/locations'}>Miejsca</SubmenuLink>
    </>
  );
};

export default InventorySubmenu;
