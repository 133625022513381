import React, { CSSProperties } from 'react';
import {
  FormLabel,
  FormWrapper,
  FormError,
  FormInput,
  FormInputWrapper,
  FormInputSuffix,
} from '../form.styled';
import { InputType } from '../../../@types';
import { useFormContext } from 'react-hook-form';
import { ERROR_MESSAGES } from '../error-messages';
import { FormHelper } from '../../../helpers/FormHelper';

type NumberInputProps = InputType & {
  style?: CSSProperties;
  float?: boolean | number;
};

export const NumberInput: React.FC<NumberInputProps> = ({
  name,
  inputRef,
  float = false,
  label,
  placeholder,
  disabled = false,
  style,
  defaultValue,
  required = false,
  suffix,
  onChange,
}) => {
  const [status, setStatus] = React.useState<string>('pristine');
  const [error, setError] = React.useState<string | undefined>();
  const formMethods = useFormContext();

  const onFocus = () => setStatus('focus');
  const onBlur = () => {
    setStatus('pristine');
  };

  React.useEffect(() => {
    if (status === 'focus') {
      return;
    } else if (error) {
      setStatus('error');
    } else if (disabled) {
      setStatus('disabled');
    } else {
      setStatus('pristine');
    }
  }, [error, status, disabled]);

  React.useEffect(() => {
    if (!required) {
      return;
    }
    const error = FormHelper.checkError(formMethods.formState.errors, name);
    setError(error);
  }, [formMethods.formState, name, required]);

  return (
    <FormWrapper htmlFor={name} style={style}>
      <FormInputWrapper status={status}>
        <FormLabel required={required} status={status}>
          {label ? label : name}
        </FormLabel>
        <FormInput
          isSuffix={!!suffix}
          status={status}
          type={'number'}
          name={name}
          id={name}
          onInput={(event) => (onChange ? onChange(formMethods.getValues(name)) : null)}
          ref={
            formMethods
              ? formMethods.register({
                  min: 0,
                  valueAsNumber: true,
                  required: required && ERROR_MESSAGES.required,
                })
              : inputRef
          }
          placeholder={placeholder ? placeholder : label}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          step={float === false ? 1 : float === true ? '0.0000000000001' : 1 / Math.pow(10, float)}
          defaultValue={
            typeof defaultValue !== 'number' || isNaN(defaultValue) ? undefined : defaultValue
          }
        />
        {suffix && <FormInputSuffix>{suffix}</FormInputSuffix>}
      </FormInputWrapper>
      <FormError>{error}</FormError>
    </FormWrapper>
  );
};
