import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import { Header, TextareaInput, TextInput } from '../../../../CapeMorris/components';

interface OwnProps {
  prefix?: string;
}

type Props = OwnProps;

const CompanyForm: FunctionComponent<Props> = ({ prefix = '', ...props }) => {
  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <TextInput name={`${prefix}vatNumber`} label="NIP" placeholder="PL XXXXXXX" required />
      </Grid>
      <Grid item md={6}>
        <TextInput name={`${prefix}name`} label="Nazwa" placeholder="Acme Corp" required />
      </Grid>
      <Grid item md={6}>
        <TextInput
          name={`${prefix}email`}
          label="E-mail kontaktowy"
          placeholder="contact@example.com"
        />
      </Grid>
      <Grid item md={6}>
        <TextInput
          name={`${prefix}invoiceEmail`}
          label="E-mail do faktur"
          placeholder="invoices@example.com"
        />
      </Grid>
      <Grid item md={6}>
        <TextInput
          name={`${prefix}website`}
          label="Strona internetowa"
          placeholder="https://example.com"
        />
      </Grid>
      <Grid item md={6}>
        <TextInput
          name={`${prefix}industryBranch`}
          label="Gałąź gospodarki"
          placeholder="Budownictwo"
        />
      </Grid>
      <Grid item md={6}>
        <TextInput name={`${prefix}size`} label="Wielkość firmy" placeholder="20-30" />
      </Grid>
      <Grid item md={12}>
        <TextareaInput
          name={`${prefix}internalComment`}
          label="Komentarz"
          placeholder="Lorem ipsum"
        />
      </Grid>
      <Grid item md={12}>
        <Header header2>Adres</Header>
      </Grid>
      <Grid item md={4}>
        <TextInput
          name={`${prefix}address.street`}
          label="Ulica"
          placeholder="Niedźwiedzia 29A"
          required
        />
      </Grid>
      <Grid item md={4}>
        <TextInput
          name={`${prefix}address.postCode`}
          label="Kod pocztowy"
          placeholder="00-001"
          required
        />
      </Grid>
      <Grid item md={4}>
        <TextInput name={`${prefix}address.city`} label="Miasto" placeholder="Warszawa" required />
      </Grid>
    </Grid>
  );
};

export default CompanyForm;
