import React, { FunctionComponent } from 'react';
import Form from '../../CapeMorris/components/form/form';
import { ActivityType } from '../../@types/Activity/ActivityType';
import ModalFooter from '../../CapeMorris/components/modal/ModalFooter';
import { Button } from '../../CapeMorris/components';
import COLORS from '../../assets/colors';
import ModalContainer from '../../CapeMorris/components/modal/use-modal-container';
import SnackContainer from '../../CapeMorris/components/snack-bar/use-snack-container';
import api from '../../CapeMorris/services/api';
import { AxiosResponse } from 'axios';
import ActivityEditForm from './ActivityEditForm';

interface OwnProps {
  activity: ActivityType;
  onEdit: (activity: ActivityType) => void;
}

type Props = OwnProps;

type ApiResponse = AxiosResponse<ActivityType>;

const ActivityEditModal: FunctionComponent<Props> = ({ activity, ...props }) => {
  const { hideModal } = ModalContainer.useContainer();
  const { showSnack } = SnackContainer.useContainer();
  const [waiting, setWaiting] = React.useState(false);

  const handleSubmit = React.useCallback(
    (data: ActivityType) => {
      setWaiting(true);
      api
        .put(activity['@id'].substr(4), data)
        .then((response: ApiResponse) => {
          setWaiting(false);
          hideModal();
          props.onEdit(response.data);
          showSnack({
            title: 'Sukces',
            message: 'Zmieniono aktywność',
          });
        })
        .catch(() => {
          setWaiting(false);
          showSnack({
            title: 'Błąd',
            message: 'Wystąpił błąd komunikacji z serwererm',
            type: 'error',
          });
        });
    },
    [activity],
  );
  return (
    <Form onSubmit={handleSubmit} defaultValues={activity}>
      <ActivityEditForm />
      <ModalFooter>
        <Button text={'Anuluj'} inverted onClick={() => hideModal()} />
        <Button
          text={'Zapisz zmiany'}
          waiting={waiting}
          color={COLORS.GREEN.NORMAL}
          type="submit"
        />
      </ModalFooter>
    </Form>
  );
};

export default ActivityEditModal;
