import styled from 'styled-components';
import { WEIGHTS } from '../../../assets/fonts';
import COLORS, { COLORS_BY_SENTIMENT } from '../../../assets/colors';

type CommonProps = {
  hasError?: boolean;
};

export const Container = styled.div``;

export const AssetItem = styled.div`
  font-weight: ${WEIGHTS.SEMI_BOLD};
  font-size: 14px;
  display: flex;
  flex-direction: row;
`;
export const AssetItemAction = styled.div`
  font-weight: ${WEIGHTS.SEMI_BOLD};
  font-size: 14px;
  padding: 0 15px;
  cursor: pointer;
  color: ${COLORS_BY_SENTIMENT.PRIMARY.LIGHT};
`;
export const AssetAdd = styled.div`
  font-weight: ${WEIGHTS.SEMI_BOLD};
  font-size: 14px;
  padding: 10px 0;
  cursor: pointer;
  color: ${COLORS_BY_SENTIMENT.PRIMARY.LIGHT};
`;

export const Empty = styled.div<CommonProps>`
  cursor: pointer;
  font-weight: ${WEIGHTS.BOLD};
  font-size: 14px;
  color: ${(props) => (props.hasError ? COLORS_BY_SENTIMENT.ERROR.NORMAL : COLORS.GREY.DARK)};
`;
