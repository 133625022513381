import React from 'react';
import { EntityContext } from './entity-context';
import api from '../../services/api';
import { entityReducer } from './entity-reducer';
import { AxiosError, AxiosResponse } from 'axios';
import SnackContainer from '../../components/snack-bar/use-snack-container';
import { removeAllIds, removeEmptyIds, removeEmptyValues } from '../../helpers/Entity';
import { AuthContext } from '../auth-context/auth-context';

type Props = {
  baseUrl: string;
  rootPath: string;
};

type ResponseType = AxiosResponse;

export const EntityProvider: React.FC<Props> = ({ children, baseUrl, rootPath }) => {
  const { showSnack } = SnackContainer.useContainer();
  const { logout } = React.useContext(AuthContext);
  const [state, dispatch] = React.useReducer(entityReducer, {
    entity: undefined,
    processing: false,
  });

  const load = React.useCallback(
    (id: number, reload = false) => {
      if (!reload && state.entity && state.entity['@id'] === `/api/${baseUrl}/${id}`) {
        return state.entity;
      }
      return api
        .get(`${baseUrl}/${id}`)
        .then((response: ResponseType) => {
          dispatch({ type: 'SAVE_ENTITY', entity: response.data });
          return response.data;
        })
        .catch((error: AxiosError) => {
          const response = error.response;
          if (response?.status === 401) {
            logout();
          }
        });
    },
    [baseUrl],
  );

  const clone = React.useCallback(
    (id: number) => {
      if (state.entity && state.entity['@id'] === `/api/${baseUrl}/${id}`) {
        const entity = { ...state.entity };
        removeAllIds(entity);
        return entity;
      }
      return api
        .get(`${baseUrl}/${id}`)
        .then((response: ResponseType) => {
          dispatch({ type: 'SAVE_ENTITY', entity: response.data });
          removeAllIds(response.data);
          return response.data;
        })
        .catch((error: AxiosError) => {
          const response = error.response;
          if (response?.status === 401) {
            logout();
          }
        });
    },
    [baseUrl],
  );

  const save = React.useCallback((id: number | string, data: any) => {
    removeEmptyIds(data);
    removeEmptyValues(data);
    dispatch({ type: 'INIT_PROCESSING', entity: undefined });
    return api
      .put(`${baseUrl}/${id}`, data)
      .then((response: ResponseType) => {
        if (response.status >= 400) {
          showSnack({
            title: 'Błąd zapytania',
            message: response.data['hydra:description'],
            type: 'error',
          });
        } else {
          showSnack({
            title: 'Sukces',
            message: 'Obiekt został zmieniony',
            type: 'success',
          });
          dispatch({ type: 'SAVE_ENTITY', entity: response.data });
          return response.data;
        }
        return Promise.reject(response.data['hydra:description']);
      })
      .catch((error: AxiosError) => {
        const response = error.response;
        if (response?.status === 401) {
          logout();
        }
        showSnack({
          title: 'Błąd zapytania',
          message: response
            ? response.data['hydra:description']
            : 'Wystąpił nieoczekiwany błąd komunikacji z serwerem [2]',
          type: 'error',
        });
        return Promise.reject(
          response
            ? response.data['hydra:description']
            : 'Wystąpił nieoczekiwany błąd komunikacji z serwerem [2]',
        );
      });
  }, []);
  const create = React.useCallback((data: any) => {
    removeEmptyIds(data);
    removeEmptyValues(data);
    dispatch({ type: 'INIT_PROCESSING', entity: undefined });
    return api
      .post(baseUrl, data)
      .then((response: ResponseType) => {
        if (response.status >= 400) {
        } else {
          dispatch({ type: 'SAVE_ENTITY', entity: response.data });
          showSnack({
            title: 'Sukces',
            message: 'Obiekt został utworzony',
            type: 'success',
          });
          return response.data;
        }
        return Promise.reject(response.data['hydra:description']);
      })
      .catch((error: AxiosError) => {
        const response = error.response;
        if (response?.status === 401) {
          logout();
        }
        showSnack({
          title: 'Błąd zapytania',
          message: response
            ? response.data['hydra:description']
            : 'Wystąpił nieoczekiwany błąd komunikacji z serwerem [2]',
          type: 'error',
        });
        return Promise.reject(
          response
            ? response.data['hydra:description']
            : 'Wystąpił nieoczekiwany błąd komunikacji z serwerem [2]',
        );
      });
  }, []);

  return (
    <EntityContext.Provider value={{ state, load, clone, save, create, rootPath }}>
      {children}
    </EntityContext.Provider>
  );
};
