import React from 'react';
import { createContainer } from 'unstated-next';

export type ModalType = {
  title: React.ReactNode | string;
  body: React.ReactNode | string;
  footer?: React.ReactNode | string;
  wide?: boolean;
};

type ModalContainerType = {
  modals: ModalType[];
  showModal: (modal: ModalType) => void;
  hideModal: () => void;
};

export const useModalContainer = (): ModalContainerType => {
  const [content, setContent] = React.useState<ModalType[]>([]);

  const showModal = (modal: ModalType) => setContent((content) => [...content, modal]);
  const hideModal = () => {
    let newContent = content.splice(0, content.length - 1);
    setContent(newContent);
  };

  return {
    modals: content,
    showModal,
    hideModal,
  };
};

const ModalContainer = createContainer<ModalContainerType>(useModalContainer);
export default ModalContainer;
