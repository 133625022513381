import styled, { css } from 'styled-components';
import COLORS, { COLORS_BY_FUNCTION, COLORS_BY_SENTIMENT } from '../../../assets/colors';
import { WEIGHTS } from '../../../assets/fonts';

type Props = {
  active: boolean;
};

export const Container = styled.div<Props>`
  border: 2px dashed ${COLORS_BY_FUNCTION.BORDER};
  border-radius: 8px;
  background-color: ${COLORS.GREY.LIGHTEST};
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: ${COLORS.GREY.DARK};
  & > * {
    text-align: center;
  }
  ${(props) =>
    props.active &&
    css`
      background-color: ${COLORS_BY_SENTIMENT.PRIMARY.LIGHTEST};
      border-color: ${COLORS_BY_SENTIMENT.PRIMARY.NORMAL};
    `}
`;

export const DragDropText = styled.div`
  font-weight: ${WEIGHTS.BOLD};
  font-size: 16px;
  margin: 15px auto;
`;
export const ChooseText = styled.div`
  font-weight: ${WEIGHTS.BOLD};
  color: ${COLORS_BY_SENTIMENT.PRIMARY.NORMAL};
  border: 1px solid ${COLORS_BY_SENTIMENT.PRIMARY.NORMAL};
  border-radius: 60px;
  padding: 12px 25px;
  margin: 15px auto;
`;
