import React, { FunctionComponent } from 'react';
import { TableProperty } from '../../../CapeMorris/components/table/table-property';
import IdTemplate from '../../../CapeMorris/components/table/cell-templates/id-template';
import StringTemplate from '../../../CapeMorris/components/table/cell-templates/string-template';
import TablePage from '../../../CapeMorris/components/table-page/table-page';
import { useHistory, useRouteMatch } from 'react-router-dom';

interface OwnProps {}

type Props = OwnProps;

const CustomerListScreen: FunctionComponent<Props> = (props) => {
  const { push } = useHistory();
  let { url } = useRouteMatch();
  const onCreate = () => {
    push(`${url}/create`);
  };
  const [properties] = React.useState<TableProperty[]>([
    {
      name: 'id',
      visibleName: 'ID',
      template: IdTemplate,
    },
    {
      name: 'firstName',
      visibleName: 'Nazwa',
      template: StringTemplate,
      transformValue: (v, o) => `${o.firstName} ${o.lastName}`,
    },
    {
      name: 'email',
      visibleName: 'E-mail',
      template: StringTemplate,
    },
    {
      name: 'phoneNumber',
      visibleName: 'Numer telefonu',
      template: StringTemplate,
    },
  ]);

  return (
    <TablePage
      onCreate={onCreate}
      title={'Właściciele pojazdów'}
      name={'właściciela pojazdu'}
      url={'/customers'}
      properties={properties}
    />
  );
};

export default CustomerListScreen;
