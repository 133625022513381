import React, { FunctionComponent } from 'react';
import { ActivityType, ActivityTypes } from '../../@types/Activity/ActivityType';
import { Grid } from '@material-ui/core';
import { SingleDetail } from '../../CapeMorris/components/Details';
import {
  ActivityActions,
  ActivityContainer,
  ActivityVehicle,
  FloatingActivityActions,
} from './Activity.styled';
import { parseDate } from '../../CapeMorris/helpers/date';
import placeholder from '../../assets/images/car-placeholder.png';
import { createFullApiUrl } from '../../CapeMorris/services/api';
import { Icon } from '../../CapeMorris/components';
import EditIcon from '../../CapeMorris/icons/edit.icon';
import COLORS, { COLORS_BY_SENTIMENT } from '../../assets/colors';
import DeleteIcon from '../../CapeMorris/icons/delete.icon';
import ModalContainer from '../../CapeMorris/components/modal/use-modal-container';
import ActivityEditModal from './ActivityEditModal';
import ActivityDeleteModal from './ActivityDeleteModal';
import { AuthContext } from '../../CapeMorris/context/auth-context/auth-context';

interface OwnProps {
  activity: ActivityType;
  includeVehicle?: boolean;
}

type Props = OwnProps;

const Activity: FunctionComponent<Props> = ({ includeVehicle = false, ...props }) => {
  const [src, setSrc] = React.useState<string | any>(placeholder);
  const [activity, setActivity] = React.useState<ActivityType>(props.activity);
  const [deleted, setDeleted] = React.useState(false);
  const { showModal } = ModalContainer.useContainer();
  const {
    state: { user },
    can,
  } = React.useContext(AuthContext);

  const canEdit = React.useMemo(() => {
    return can('ROLE_ADMIN') || user?.id === activity.createdBy.id;
  }, [activity, user]);

  React.useEffect(() => {
    setActivity(props.activity);
  }, [props.activity]);

  React.useEffect(() => {
    if (activity.inventory?.mainPhoto?.file?.id) {
      setSrc(createFullApiUrl('/files/' + activity.inventory.mainPhoto.file.id + '/thumbnail'));
    }
  }, []);

  const handleEditClick = React.useCallback(() => {
    showModal({
      title: 'Edytuj aktywność',
      body: <ActivityEditModal activity={activity} onEdit={setActivity} />,
    });
  }, []);

  const handleDelete = React.useCallback(() => {
    setDeleted(true);
  }, [activity]);

  const handleDeleteClick = React.useCallback(() => {
    showModal({
      title: 'Na pewno?',
      body: <ActivityDeleteModal onDelete={handleDelete} activity={activity} />,
    });
  }, [activity]);

  return (
    <Grid item md={12} style={{ display: deleted ? 'none' : 'initial' }}>
      <ActivityContainer background={!includeVehicle}>
        {!includeVehicle && canEdit && (
          <FloatingActivityActions>
            <Icon
              icon={<EditIcon />}
              color={COLORS_BY_SENTIMENT.PRIMARY.DARKEST}
              onClick={handleEditClick}
            />
            <Icon
              icon={<DeleteIcon />}
              color={COLORS_BY_SENTIMENT.PRIMARY.DARKEST}
              onClick={handleDeleteClick}
            />
          </FloatingActivityActions>
        )}
        <Grid container spacing={2}>
          {includeVehicle && activity.vehicle && (
            <Grid item md={12}>
              <ActivityVehicle>
                <Grid container md={12}>
                  <Grid item md={true}>
                    {activity.vehicle.make} {activity.vehicle.model} {activity.vehicle.year},{' '}
                    {activity.plates}
                  </Grid>
                  <Grid item>
                    {canEdit && (
                      <ActivityActions>
                        <Icon icon={<EditIcon />} color={COLORS.WHITE} onClick={handleEditClick} />
                        <Icon
                          icon={<DeleteIcon />}
                          color={COLORS.WHITE}
                          onClick={handleDeleteClick}
                        />
                      </ActivityActions>
                    )}
                  </Grid>
                </Grid>
              </ActivityVehicle>
            </Grid>
          )}
          <Grid item md={12}>
            <Grid container direction="row" spacing={2}>
              {includeVehicle && (
                <Grid item md={2}>
                  <img src={src} style={{ width: '100%' }} />
                </Grid>
              )}
              <Grid item md={true}>
                <Grid container spacing={2}>
                  <SingleDetail
                    title={`Komentarz dodany przez ${activity.createdBy.name}, ${parseDate(
                      activity.createdAt,
                      true,
                    )}`}
                    md={12}
                    style={{ maxWidth: 500, overflowWrap: 'break-word' }}>
                    {activity.comment}
                  </SingleDetail>
                  <SingleDetail title="Typ" md={3}>
                    {ActivityTypes[activity.type]}
                  </SingleDetail>
                  {activity.type === 'potential_client' && (
                    <>
                      <SingleDetail title="Numer telefonu" md={3}>
                        {activity.phoneNumber}
                      </SingleDetail>
                      <SingleDetail title="E-mail" md={3}>
                        {activity.email}
                      </SingleDetail>
                      <SingleDetail title="Inna sprzedaż?" md={3}>
                        {activity.otherSalePotential ? 'TAK' : 'NIE'}
                      </SingleDetail>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ActivityContainer>
    </Grid>
  );
};

export default Activity;
