import { orange } from '@material-ui/core/colors';

export type Color = {
  LIGHTEST: string;
  LIGHT: string;
  NORMAL: string;
  DARK: string;
  DARKEST: string;
};

const COLORS = {
  GREEN: {
    LIGHTEST: '#EEFAEA',
    LIGHT: '#EEFAEA',
    NORMAL: '#54C92B',
    DARK: '#54C92B',
    DARKEST: '#54C92B',
  },
  YELLOW: {
    LIGHTEST: '#FFFAE6',
    LIGHT: '#FFFAE6',
    NORMAL: '#FFC700',
    DARK: '#CC9F00',
    DARKEST: '#CC9F00',
  },
  ORANGE: {
    NORMAL: 'orange',
  },
  PINK: {
    LIGHTEST: '#FCFBFB',
    LIGHT: '#FEB3A9',
    NORMAL: '#EB796B',
    DARK: '#E55E4D',
    DARKEST: '#B84F42',
  },
  BLUE: {
    LIGHTEST: '#E7F0FD',
    LIGHT: '#809FFF',
    NORMAL: '#0C65EA',
    DARK: '#0009B7',
    DARKEST: '#00089B',
  },
  GREY: {
    LIGHTEST: '#fcfbfb',
    LIGHT: '#f7f6f6',
    NORMAL: '#e0e0e0',
    DARK: '#787878',
    DARKEST: '#666666',
  },
  WHITE: '#ffffff',
  RED: {
    LIGHTEST: '#FFEAEA',
    LIGHT: '#FFEAEA',
    NORMAL: '#FA3131',
    DARK: '#B91B1B',
    DARKEST: '#6D0505',
  },
  BLACK: '#0C0C0C',
};

export const COLORS_BY_SENTIMENT = {
  PRIMARY: COLORS.GREY,
  DISABLED: '#D5D5D5',
  ERROR: COLORS.RED,
  WARNING: COLORS.YELLOW,
  INFO: COLORS.BLUE,
  SUCCESS: COLORS.GREEN,
};

export const COLORS_BY_FUNCTION = {
  BORDER: COLORS.GREY.NORMAL,
  ACTION: COLORS.GREY.DARK,
};

export default COLORS;
