import React, { FunctionComponent } from 'react';
import { COLORS_BY_SENTIMENT } from '../../../assets/colors';

type LogoProps = {
  width?: number;
  color?: string;
};

const LogoShort: FunctionComponent<LogoProps> = ({
  width = 200,
  color = COLORS_BY_SENTIMENT.PRIMARY.DARKEST,
  ...props
}) => {
  return (
    <svg width={width} height="auto" viewBox="0 0 52 19.4" x={0} y={0}>
      <path
        stroke={color}
        fill={color}
        d="M14.2,11.1l5.3,1c-0.8,4.5-4.5,7.3-9.5,7.3c-5.6,0-10-4.3-10-9.7C0,4.3,4.4,0,10,0c4.6,0,8.7,2.7,9.5,7.1l-5.3,1.1
		C13.7,6,12.1,4.8,10,4.8c-2.6,0-4.6,2.1-4.6,4.9c0,2.8,2,4.9,4.6,4.9C12.1,14.6,13.7,13.3,14.2,11.1z"
      />
      <path
        stroke={color}
        fill={color}
        d="M37.4,11.1l5.3,1c-0.8,4.5-4.5,7.3-9.5,7.3c-5.6,0-10-4.3-10-9.7c0-5.4,4.4-9.7,10-9.7c4.6,0,8.7,2.7,9.5,7.1l-5.3,1.1
		c-0.5-2.2-2.1-3.4-4.2-3.4c-2.6,0-4.6,2.1-4.6,4.9c0,2.8,2,4.9,4.6,4.9C35.4,14.6,36.9,13.3,37.4,11.1z"
      />
      <path
        stroke={color}
        fill={color}
        d="M52,16.3c0,1.6-1.2,2.8-2.9,2.8s-2.9-1.2-2.9-2.8c0-1.5,1.2-2.9,2.9-2.9S52,14.7,52,16.3z"
      />
    </svg>
  );
};

export default LogoShort;
