import React from 'react';
import { CellTemplate } from '../table-property';
import AddressType from "../../../@types/address";

const AddressTemplate: CellTemplate<AddressType> = (props) => {
  return (
    <div>
      <div>{props.value.street}</div>
      <div>{props.value.postCode} {props.value.city}</div>
    </div>
  );
};

export default AddressTemplate;
