import React, { CSSProperties, FunctionComponent } from 'react';
import { InputType } from '../../../@types';
import { useFormContext } from 'react-hook-form';
import * as Components from './Switch.styled';
import { FormHelper } from '../../../helpers/FormHelper';
import { FormError } from '../form.styled';
import { ERROR_MESSAGES } from '../error-messages';

type SwitchProps = InputType & {
  defaultChecked?: boolean | undefined;
};

const Switch: FunctionComponent<SwitchProps> = (props) => {
  const [error, setError] = React.useState<string | undefined>();
  const formMethods = useFormContext();
  const valueWatch = formMethods.watch(props.name);

  React.useEffect(() => {
    if (!props.required) {
      return;
    }
    const error = FormHelper.checkError(formMethods.formState.errors, props.name);
    setError(error);
  }, [formMethods.formState, props.name, props.required]);

  React.useEffect(() => {
    if (props.onChange) {
      props.onChange(valueWatch);
    }
  }, [valueWatch]);

  return (
    <Components.Container>
      <Components.SwitchLabel>
        <Components.HiddenCheckbox
          defaultChecked={props.defaultChecked}
          type="checkbox"
          name={props.name}
          ref={formMethods.register({ required: props.required && ERROR_MESSAGES.required })}
        />
        <Components.SwitchWrapper checked={valueWatch}>
          <Components.StyledCheckbox checked={valueWatch} />
        </Components.SwitchWrapper>
        <Components.SwitchText>{props.label}</Components.SwitchText>
      </Components.SwitchLabel>
      <FormError>{error}</FormError>
    </Components.Container>
  );
};

export default Switch;
