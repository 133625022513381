import { UserType } from '../../CapeMorris/@types';
import { InventoryType } from '../Inventory/Inventory';
import { Vehicle } from '../vehicle';

export type ActivityType = {
  createdBy: UserType;
  createdAt: string | Date;
  comment: string;
  id: string;
  type: 'owner' | 'potential_client' | 'other';
  email?: string;
  phoneNumber?: string;
  otherSalePotential: boolean;
  vehicle?: Vehicle;
  inventory?: InventoryType;
  plates?: string | undefined;
  '@id': string;
};

export const ActivityTypes = {
  owner: 'Właściciel',
  potential_client: 'Potencjalny klient',
  other: 'Inne',
};
