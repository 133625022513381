import React, { FunctionComponent } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { TableProperty } from '../../../CapeMorris/components/table/table-property';
import IdTemplate from '../../../CapeMorris/components/table/cell-templates/id-template';
import StringTemplate from '../../../CapeMorris/components/table/cell-templates/string-template';
import TablePage from '../../../CapeMorris/components/table-page/table-page';
import MoneyTemplate from '../../../CapeMorris/components/table/cell-templates/MoneyTemplate';
import StatusTemplate from '../../../components/CellTemplates/StatusTemplate';
import { FilterType } from '../../../CapeMorris/@types';
import StatusFilter from '../../../components/Inventory/StatusFilter';
import ImageTemplate from '../../../CapeMorris/components/table/cell-templates/ImageTemplate';

interface OwnProps {}

type Props = OwnProps;

export const InventoryListProperties = [
  {
    name: 'mainPhoto',
    visibleName: 'Photo',
    template: ImageTemplate,
  },
  {
    name: 'id',
    visibleName: 'ID',
    template: IdTemplate,
  },
  {
    name: 'vehicle.make',
    visibleName: 'Marka',
    template: StringTemplate,
  },
  {
    name: 'vehicle.model',
    visibleName: 'Model',
    template: StringTemplate,
  },
  {
    name: 'vehicle.year',
    visibleName: 'Rok produkcji',
    template: StringTemplate,
  },
  {
    name: 'plates',
    visibleName: 'Rejstracja',
    template: StringTemplate,
  },
  {
    name: 'status',
    visibleName: 'Status',
    template: StatusTemplate,
  },
  {
    name: 'salesPrice',
    visibleName: 'Cena sprzedaży',
    template: MoneyTemplate,
  },
];

const InventoryListScreen: FunctionComponent<Props> = (props) => {
  const { push } = useHistory();
  let { url } = useRouteMatch();
  const onCreate = () => {
    push(`${url}/create`);
  };

  const [filters] = React.useState<FilterType[]>([
    {
      name: 'status',
      type: 'custom',
      customComponent: StatusFilter,
      label: 'Aktywny',
    },
  ]);

  return (
    <TablePage
      onCreate={onCreate}
      title={'Samochody na stanie'}
      name={'samochód'}
      url={'/vehicle_inventories'}
      properties={InventoryListProperties}
      canArchive={false}
      filters={filters}
    />
  );
};

export default InventoryListScreen;
