import React, { FunctionComponent } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { EntityProvider } from '../../CapeMorris/context/entity-context/entity-provider';
import CustomerListScreen from './Customer/CustomerListScreen';
import CompanyListScreen from './Company/CompanyListScreen';
import CompanyFormScreen from './Company/CompanyFormScreen';
import CustomerFormScreen from './Customer/CustomerFormScreen';
import CompanyShowScreen from './Company/CompanyShowScreen';
import CustomerShowScreen from './Customer/CustomerShowScreen';

interface OwnProps {}

type Props = OwnProps;

const CustomerBaseScreen: FunctionComponent<Props> = (props) => {
  let { path } = useRouteMatch();
  return (
    <>
      <EntityProvider baseUrl="/customers" rootPath={path}>
        <Switch>
          <Route exact path={path}>
            <CustomerListScreen />
          </Route>
          <Route exact path={`${path}/create`}>
            <CustomerFormScreen />
          </Route>
          <Route exact path={`${path}/:id/edit`}>
            <CustomerFormScreen />
          </Route>
          <Route exact path={`${path}/:id/show`}>
            <CustomerShowScreen />
          </Route>
        </Switch>
      </EntityProvider>
      <EntityProvider baseUrl="/companies" rootPath={`${path}/companies`}>
        <Switch>
          <Route exact path={`${path}/companies`}>
            <CompanyListScreen />
          </Route>
          <Route exact path={`${path}/companies/create`}>
            <CompanyFormScreen />
          </Route>
          <Route exact path={`${path}/companies/:id/edit`}>
            <CompanyFormScreen />
          </Route>
          <Route exact path={`${path}/companies/:id/show`}>
            <CompanyShowScreen />
          </Route>
        </Switch>
      </EntityProvider>
    </>
  );
};

export default CustomerBaseScreen;
