import React, { FunctionComponent } from 'react';
import Select, { SelectProps } from '../select/select';

const DictionarySelect: FunctionComponent<SelectProps> = (props) => {
  return (
    <Select
      {...props}
      optionValue={'value'}
      optionLabel={'label'}
      onCreate={(v: string, l: string) => ({
        value: v,
        label: v,
      })}
    />
  );
};
export default DictionarySelect;
