import React, { FunctionComponent, ReactElement } from 'react';
import { HistoryEntryType } from '../../@types/History/HistoryEntryType';
import * as Components from './HistoryEntry.styled';
import { Line, Sidenote } from '../Typography';
import DateString from '../Date/DateString';
import { Grid } from '@material-ui/core';
import { SingleDetail } from '../Details';
import { Colored } from '../layout/layout.styled';
import COLORS from '../../../assets/colors';

interface OwnProps {
  entry: HistoryEntryType;
}

type Props = OwnProps;

const HistoryEntry: FunctionComponent<Props> = ({ entry, ...props }) => {
  const [changes, setChanges] = React.useState<ReactElement[]>([]);

  React.useEffect(() => {
    if (!entry.changeSet) {
      setChanges([]);
      return;
    }
    let changes = [];
    for (let key in entry.changeSet) {
      changes.push(
        <SingleDetail key={key} title={key}>
          <Colored color={COLORS.GREEN.NORMAL}>{entry.changeSet[key].before}</Colored> &gt;{' '}
          <Colored color={COLORS.GREEN.NORMAL}>{entry.changeSet[key].after}</Colored>
        </SingleDetail>,
      );
    }
    setChanges(changes);
  }, [entry]);

  return (
    <Components.Container>
      <Line>
        {entry.author.name} {entry.readableAction}
      </Line>
      <Sidenote>
        on <DateString input={entry.createdAt} time />
      </Sidenote>
      <Grid container spacing={2} style={{ marginTop: 15 }}>
        {changes}
      </Grid>
    </Components.Container>
  );
};

export default HistoryEntry;
