import styled from 'styled-components';
import COLORS, { COLORS_BY_SENTIMENT } from '../../../assets/colors';
import { WEIGHTS } from '../../../assets/fonts';

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
`;

export const TableHead = styled.thead`
  color: ${COLORS_BY_SENTIMENT.PRIMARY.DARKEST};
  th {
    height: 60px;
    font-weight: ${WEIGHTS.BOLD};
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    padding-left: 15px;
    text-align: left;
  }
`;
export const TableRow = styled.tr`
  border-radius: 5px;
  cursor: pointer;
  td {
    height: 52px;
    padding: 0;
    border-bottom: 8px solid ${COLORS.WHITE};
    & > div {
      display: flex;
      align-items: center;
      padding-left: 15px;
      height: 100%;
      width: 100%;
      background-color: ${COLORS.GREY.LIGHTEST};
      transition: background-color 0.2s ease-out;
    }
  }
  td:first-child > div {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  td:last-child > div {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  &:hover > td > div {
    background-color: ${COLORS.GREY.LIGHT};
  }
`;

export const TableAction = styled.div`
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
  &:hover {
    background-color: ${COLORS_BY_SENTIMENT.PRIMARY.LIGHT};
  }
  &:active {
    transform: scale(0.9);
  }
`;
