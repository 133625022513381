import api from '../services/api';
import { AxiosResponse } from 'axios';
import { AssetType } from '../@types';

export const loadDigitalAssets = (filters?: any): Promise<AssetType[]> => {
  console.log(filters);
  return api
    .get('/digital_assets', { params: { ...filters } })
    .then((response: AxiosResponse<{ 'hydra:member': AssetType[] }>) => {
      return response.data['hydra:member'];
    });
};
