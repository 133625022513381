import React, { FunctionComponent } from 'react';
import SnackContainer from './use-snack-container';
import * as Components from './SnackBar.typed';
import { Icon } from '..';
import SuccessIcon from '../../../CapeMorris/icons/success.icon';
import CloseIcon from '../../../CapeMorris/icons/close.icon';
import * as Modal from '../modal/modal.styled';
import COLORS, { Color, COLORS_BY_SENTIMENT } from '../../../assets/colors';
import ErrorIcon from '../../../CapeMorris/icons/error.icon';
import InfoIcon from '../../../CapeMorris/icons/info.icon';
import WarningIcon from '../../../CapeMorris/icons/warning.icon';
import { Grid } from '@material-ui/core';

interface OwnProps {}

type Props = OwnProps;

const SnackBarColors = {
  success: COLORS_BY_SENTIMENT.SUCCESS,
  error: COLORS_BY_SENTIMENT.ERROR,
  info: COLORS_BY_SENTIMENT.INFO,
  warning: COLORS_BY_SENTIMENT.WARNING,
};

const SnackBar: FunctionComponent<Props> = (props) => {
  const { snack, dismissSnack } = SnackContainer.useContainer();
  const [color, setColor] = React.useState<Color>(COLORS_BY_SENTIMENT.SUCCESS);
  const [icon, setIcon] = React.useState<React.ReactElement>(<SuccessIcon />);

  React.useEffect(() => {
    if (!snack) {
      return;
    }
    if (snack.type) {
      setColor(SnackBarColors[snack.type]);
    } else {
      setColor(COLORS_BY_SENTIMENT.SUCCESS);
    }
  }, [snack]);

  React.useEffect(() => {
    switch (snack?.type) {
      case 'error':
        setIcon(<ErrorIcon />);
        break;
      case 'info':
        setIcon(<InfoIcon />);
        break;
      case 'warning':
        setIcon(<WarningIcon />);
        break;
      case 'success':
      default:
        setIcon(<SuccessIcon />);
    }
  }, [snack]);

  return (
    <Components.Container>
      {snack && (
        <Components.Item>
          <Components.IconWrapper color={color.LIGHT}>
            <Icon icon={icon} color={color.NORMAL} size={24} />
          </Components.IconWrapper>
          <Components.Wrapper>
            <Grid container direction={'column'}>
              <Components.Title color={color.NORMAL}>{snack.title}</Components.Title>
              <Components.Message color={color.NORMAL}>{snack.message}</Components.Message>
            </Grid>
            <Modal.Close onClick={dismissSnack}>
              <Icon icon={<CloseIcon />} size={16} color={COLORS.BLACK} />
            </Modal.Close>
          </Components.Wrapper>
        </Components.Item>
      )}
    </Components.Container>
  );
};

export default SnackBar;
