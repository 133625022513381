import React, { CSSProperties, FunctionComponent } from 'react';
import * as Components from './SearchBar.styled';
import Select from '../../form/select/select';
import InventoryOption from '../../../../components/Inventory/InventoryOption';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import IriHelper from '../../../helpers/iri-helper';
import { selectStyles } from '../../form/select/select.styles';
import { WEIGHTS } from '../../../../assets/fonts';
import COLORS, { COLORS_BY_FUNCTION, COLORS_BY_SENTIMENT } from '../../../../assets/colors';
interface OwnProps {}

type Props = OwnProps;

const customSelectStyles = (hasError: boolean | undefined, required?: boolean) => {
  let result = selectStyles(hasError, required);
  return {
    ...result,
    control: (styles: CSSProperties) => ({
      ...styles,
      fontSize: 14,
      fontWeight: WEIGHTS.BOLD,
      color: hasError ? COLORS_BY_SENTIMENT.ERROR.NORMAL : COLORS_BY_SENTIMENT.PRIMARY.DARKEST,
      backgroundColor: hasError ? COLORS.RED.LIGHT : COLORS.WHITE,
      borderColor: hasError ? COLORS_BY_SENTIMENT.ERROR.NORMAL : COLORS_BY_FUNCTION.BORDER,
      borderWidth: 0,
      borderRadius: 0,
      cursor: 'pointer',
      borderBottomWidth: 0,
      padding: 0,
      margin: 0,
      boxShadow: 'none',
    }),
  };
};

const SearchBar: FunctionComponent<Props> = (props) => {
  const { watch, setValue } = useFormContext();
  const { push } = useHistory();
  const [show, setShow] = React.useState(true);

  const watcher = watch('inventory');

  React.useEffect(() => {
    if (!watcher) {
      return;
    }
    const id = IriHelper.iriToId(watcher);
    push(`/inventory/${id}/show`);
    setValue('inventory', '');
    setShow(false);
    setTimeout(() => setShow(true), 200);
  }, [watcher]);

  return (
    <Components.Container>
      {show && (
        <Select
          name={'inventory'}
          placeholder="Wyszukaj samochód"
          baseUrl="/vehicle_inventories"
          selectStyles={customSelectStyles}
          optionComponent={InventoryOption}
        />
      )}
    </Components.Container>
  );
};

export default SearchBar;
