import React, { FunctionComponent } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import UsersList from './screens/UsersList';
import UsersForm from './screens/UsersForm';
import UsersShow from './screens/UsersShow';
import { EntityProvider } from '../../CapeMorris/context/entity-context/entity-provider';

interface OwnProps {}

type Props = OwnProps;

const UsersScreen: FunctionComponent<Props> = (props) => {
  let { path } = useRouteMatch();

  return (
    <EntityProvider baseUrl="/users" rootPath={path}>
      <Switch>
        <Route exact path={path}>
          <UsersList />
        </Route>
        <Route exact path={`${path}/create`}>
          <UsersForm />
        </Route>
        <Route exact path={`${path}/:id/edit`}>
          <UsersForm />
        </Route>
        <Route exact path={`${path}/:id/show`}>
          <UsersShow />
        </Route>
      </Switch>
    </EntityProvider>
  );
};

export default UsersScreen;
