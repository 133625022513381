import React, { FunctionComponent } from 'react';
import Form from '../../CapeMorris/components/form/form';
import { Button, TextInput } from '../../CapeMorris/components';
import COLORS from '../../assets/colors';
import api from '../../CapeMorris/services/api';
import SnackContainer from '../../CapeMorris/components/snack-bar/use-snack-container';

interface OwnProps {
  setCurrentForm: (form: 'login' | 'forgot-password' | 'change-password') => void;
}

type Props = OwnProps;

const ForgotPassword: FunctionComponent<Props> = (props) => {
  const [state, setState] = React.useState<'idle' | 'working'>('idle');
  const { showSnack } = SnackContainer.useContainer();
  const onSubmit = (data: any) => {
    setState('working');
    api.post('/reset-password', { ...data }).then(() => {
      setState('idle');
      showSnack({
        title: 'Check mailbox',
        message: 'An email with further information has been sent to you',
      });
      props.setCurrentForm('change-password');
    });
  };
  return (
    <Form onSubmit={onSubmit} defaultValues={{}}>
      <TextInput
        style={{ marginBottom: 20, marginTop: 50 }}
        name="username"
        label={'Username'}
        placeholder={'j.snow'}
        required
      />
      <Button
        type="submit"
        style={{ marginTop: 30 }}
        wide
        color={COLORS.BLUE.NORMAL}
        text={'Send e-mail'}
        waiting={state === 'working'}
      />
    </Form>
  );
};

export default ForgotPassword;
