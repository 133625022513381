import React, { FunctionComponent } from 'react';
import { Header } from '../../../../CapeMorris/components';
import { InventoryType } from '../../../../@types/Inventory/Inventory';
import { Grid } from '@material-ui/core';
import { ActivitiesForm, ActivitiesList } from '../../../../components/Activities';
import { ActivityType } from '../../../../@types/Activity/ActivityType';

interface OwnProps {
  entity: InventoryType;
}

type Props = OwnProps;

const InventoryShowActivities: FunctionComponent<Props> = ({ entity, ...props }) => {
  const [activities, setActivities] = React.useState<ActivityType[]>([]);

  React.useEffect(() => {
    setActivities(entity.activities);
  }, [entity]);

  React.useEffect(() => {
    return () => {
      setActivities([]);
    };
  }, []);
  return (
    <>
      <Header header2>Aktywności</Header>
      <Grid container spacing={2}>
        <ActivitiesForm
          entityId={entity['@id']}
          onCreate={(comment) => setActivities((a) => [comment, ...a])}
        />
      </Grid>
      <ActivitiesList activities={activities} />
    </>
  );
};

export default InventoryShowActivities;
