import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import { NumberInput, TextInput } from '../../../../CapeMorris/components';
import Select from '../../../../CapeMorris/components/form/select/select';
import { BodyTypes, DriveTypes, Engines, Transmissions } from '../../../../@types/vehicle';
import DictionarySelect from '../../../../CapeMorris/components/form/DictionarySelect/DictionarySelect';
import { useFormContext, UseFormMethods } from 'react-hook-form';
import AssetSelect from '../../../../CapeMorris/components/asset-select/AssetSelect';

interface OwnProps {
  prefix?: string;
  vin?: string;
  methods?: UseFormMethods<any>;
  isEdit?: boolean;
}

type Props = OwnProps;

const VehicleForm: FunctionComponent<Props> = (props) => {
  const { register } = useFormContext();
  const [prefix] = React.useState<string | undefined>(props.prefix ?? '');

  return (
    <>
      <Grid container spacing={2}>
        {props.vin && (
          <input
            defaultValue={props.vin}
            ref={register}
            name={`${prefix}vin`}
            required
            type="hidden"
          />
        )}

        {!props.vin && (
          <>
            <Grid item md={5}>
              <TextInput
                name={`${prefix}vin`}
                label="Numer VIN"
                placeholder="ZARYNFLWHPDTZ7856"
                required
              />
            </Grid>
            <Grid item md={5} />
          </>
        )}
        <Grid item md={5}>
          <DictionarySelect
            name={`${prefix}make`}
            label={'Marka'}
            baseUrl="/dictionaries/make"
            placeholder="porshe"
            required
            creatable={true}
          />
        </Grid>
        <Grid item md={5}>
          <DictionarySelect
            name={`${prefix}model`}
            label={'Model'}
            baseUrl="/dictionaries/model"
            required
            creatable={true}
          />
        </Grid>
        <Grid item md={2}>
          <NumberInput name={`${prefix}year`} label="Rok produkcji" placeholder="2020" required />
        </Grid>
        <Grid item md={6}>
          <DictionarySelect
            name={`${prefix}originCountry`}
            label={'Kraj pochodzenia'}
            baseUrl="/dictionaries/country"
            required
          />
        </Grid>
        <Grid item md={6} />
        <Grid item md={3}>
          <Select name={`${prefix}bodyType`} label="Nadwozie" required options={BodyTypes} />
        </Grid>
        <Grid item md={3}>
          <Select name={`${prefix}engine`} label="Rodzaj silnika" required options={Engines} />
        </Grid>
        <Grid item md={3}>
          <Select
            name={`${prefix}transmission`}
            label="Skrzynia biegów"
            required
            options={Transmissions}
          />
        </Grid>
        <Grid item md={3}>
          <Select name={`${prefix}driveType`} label="Napęd" required options={DriveTypes} />
        </Grid>
        <Grid item md={3}>
          <NumberInput
            name={`${prefix}capacity`}
            label="Pojemność"
            placeholder="2843"
            suffix="cm3"
          />
        </Grid>
        <Grid item md={3}>
          <NumberInput name={`${prefix}power`} label="Moc silnika" placeholder="210" suffix="KM" />
        </Grid>
        <Grid item md={6}>
          <TextInput
            name={`${prefix}exteriorColor`}
            label="Kolor nadwozia"
            placeholder="Sky blue"
            required
          />
        </Grid>
        <Grid item md={6}>
          <TextInput
            name={`${prefix}interiorColor`}
            label="Kolor środka"
            placeholder="Czarny"
            required
          />
        </Grid>
        <Grid item md={12}>
          <Select
            label="Dodatkowe wyposażenie"
            placeholder="Wybierz dodatkowe wyposażenie"
            name={`${prefix}.additionalEquipments`}
            baseUrl={'/additional_equipments'}
            optionLabel="name"
            isMulti
            creatable
          />
        </Grid>
      </Grid>
    </>
  );
};

export default VehicleForm;
