import styled from 'styled-components';
import { WEIGHTS } from '../../../assets/fonts';
import COLORS, { COLORS_BY_FUNCTION } from '../../../assets/colors';

export const AssetPreview = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  overflow: hidden;
  position: relative;
  img {
    height: 100%;
  }
`;
export const AssetName = styled.div`
  font-weight: ${WEIGHTS.BOLD};
  font-size: 14px;
  padding: 18px 0;
  border-bottom: 1px solid ${COLORS_BY_FUNCTION.BORDER};
`;
export const AssetProps = styled.div`
  padding: 18px 0;
`;
export const AssetPropName = styled.span`
  font-weight: ${WEIGHTS.LIGHT};
  font-size: 14px;
  padding-right: 8px;
`;
export const AssetPropValue = styled.span`
  font-weight: ${WEIGHTS.SEMI_BOLD};
  font-size: 14px;
`;
export const AssetIcon = styled.div`
  height: 120px;
  max-width: 40%;
  svg {
    max-height: 100%;
  }
`;
export const AssetDeleteIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  &:hover .cc-icon svg {
    path {
      stroke: ${COLORS.RED.NORMAL};
      fill: ${COLORS.RED.NORMAL};
    }
  }
`;
