import React, {FunctionComponent} from 'react';
import Form from '../../CapeMorris/components/form/form';
import {Button, TextInput} from '../../CapeMorris/components';
import COLORS from '../../assets/colors';
import {FormError} from '../../CapeMorris/components/form/form.styled';
import {AuthContext} from '../../CapeMorris/context/auth-context/auth-context';

interface OwnProps {
}

type Props = OwnProps;

const LoginForm: FunctionComponent<Props> = (props) => {
  const {authenticate, state} = React.useContext(AuthContext);

  const onSubmit = (data: any) => {
    console.log(data);
    if (data.username && data.password) {
      console.log(data);
      authenticate(data.username, data.password);
    }
  };
  return (
    <Form onSubmit={onSubmit} defaultValues={{}}>
      <TextInput
        style={{marginBottom: 20, marginTop: 50}}
        name="username"
        label={'Username'}
        placeholder={'j.snow'}
        required
      />
      <TextInput name={'password'} label={'Password'} placeholder={'P@ssw0rd!'}
                 required password/>
      <Button
        type="submit"
        style={{marginTop: 30}}
        wide
        color={COLORS.BLUE.NORMAL}
        text={'Log in'}
      />
      {state.state === 'INVALID_CREDENTIALS' &&
      <FormError>Invalid credentials</FormError>}
    </Form>
  );
};

export default LoginForm;
