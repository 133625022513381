import styled from 'styled-components';
import { COLORS_BY_SENTIMENT } from "../../../../assets/colors";

export const Label = styled.div`
  color: ${COLORS_BY_SENTIMENT.PRIMARY.DARKEST};
  font-size: 10px;
  line-height: 12px;
  padding-bottom: 8px;
  letter-spacing: 0.6px;
`;
export const Content = styled.div`
  font-size: 12px;
  line-height: 14px;
`;
