import styled from 'styled-components';
import { WEIGHTS } from '../../../assets/fonts';
import COLORS, { COLORS_BY_SENTIMENT } from '../../../assets/colors';

export const H1 = styled.h1`
  font-weight: ${WEIGHTS.BOLD};
  color: ${COLORS.BLACK};
  font-size: 36px;
  line-height: 45px;
  padding: 0;
  margin: 0;
`;

export const H2 = styled.h2`
  font-size: 20px;
  line-height: 25px;
  color: ${COLORS.BLACK};
  font-weight: ${WEIGHTS.BOLD};
  margin-bottom: 20px;
`;

export const H3 = styled.h3`
  font-size: 16px;
  font-weight: ${WEIGHTS.BOLD};
`;
