import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { EntityContext } from '../../../CapeMorris/context/entity-context/entity-context';
import { PageBase } from '../../../CapeMorris/components/layout/page/page';
import { PageContent, PageTop } from '../../../CapeMorris/components/layout/page/page.styled';
import { UserType } from "../../../CapeMorris/@types";

interface OwnProps {}
type Props = OwnProps;

const UsersShow: FunctionComponent<Props> = (props) => {
  const [entity, setEntity] = React.useState<UserType | undefined>();
  let { id } = useParams<{id?: string}>();
  const { load } = React.useContext(EntityContext);

  React.useEffect(() => {
    load(id).then((entity) => setEntity(entity));
    console.log(id);
  }, [id, load]);

  return (
    <PageBase>
      <PageTop>Użytkownik {entity?.username}</PageTop>
      <PageContent>
        <div>Dane użytkownika</div>
        <p>Imię: {entity?.profile.firstName}</p>
        <p>Nazwisko: {entity?.profile.lastName}</p>
        <p>Stanowsiko: {entity?.profile.position}</p>
        <p>Email: {entity?.profile.email}</p>
        <p>Telefon: {entity?.profile.phoneNumber}</p>
      </PageContent>
    </PageBase>
  );
};

export default UsersShow;
