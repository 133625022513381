import styled from 'styled-components';
import COLORS, { COLORS_BY_SENTIMENT } from '../../../assets/colors';
import { WEIGHTS } from '../../../assets/fonts';

export const Container = styled.div`
  position: relative;
`;
export const DropzoneOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${COLORS_BY_SENTIMENT.PRIMARY.LIGHT}bb;
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
`;
export const DropzoneText = styled.div`
  color: ${COLORS.WHITE};
  font-weight: ${WEIGHTS.BOLD};
  font-size: 24px;
`;
