import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Dashboard } from './dashboard';
import { Login } from './login';
import { AuthProvider } from '../CapeMorris/context/auth-context/auth-provider';
import { AuthContext } from '../CapeMorris/context/auth-context/auth-context';
import { Splash } from './splash/splash';
import { InterfaceGuide } from './interface-guide/interface-guide';
import ModalBase from '../CapeMorris/components/modal/modal-base';
import ModalContainer from '../CapeMorris/components/modal/use-modal-container';
import { Layout } from '../CapeMorris/components';
import { MenuItem } from '../CapeMorris/components/layout/menu/menu-item';
import { PeopleIcon } from '../CapeMorris/icons/people.icon';
import UsersScreen from './users/users';
import { LocaleProvider } from '../CapeMorris/context/locale-context/locale-provider';
import SnackContainer from '../CapeMorris/components/snack-bar/use-snack-container';
import SnackBar from '../CapeMorris/components/snack-bar/SnackBar';
import CustomerBaseScreen from './Customer/CustomerBaseScreen';
import BuildingIcon from '../icons/BuildingIcon';
import CustomerSubmenu from './Customer/CustomerSubmenu';
import InventoryBaseScreen from './Inventory/InventoryBaseScreen';
import SteeringWheelIcon from '../icons/SteeringWheelIcon';
import InventorySubmenu from './Inventory/InventorySubmenu';
import { HomeIcon } from '../CapeMorris/icons/home.icon';

const AnonymousRouter: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route path="*">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </Router>
  );
};

const AuthenticatedRouter: React.FC = () => {
  const menuItems = [
    <MenuItem key="home" icon={<HomeIcon />} to="/" name="Dashboard" />,
    <MenuItem
      key="inventory"
      icon={<SteeringWheelIcon />}
      to="/inventory"
      name="Samochody"
      submenu={<InventorySubmenu />}
    />,
    <MenuItem
      key="customers"
      icon={<BuildingIcon />}
      to="/customers"
      name="Klienci"
      submenu={<CustomerSubmenu />}
    />,
    <MenuItem key="users" icon={<PeopleIcon />} name="Użytkownicy" to="/users" />,
  ];

  return (
    <Router>
      <Layout menuItems={menuItems}>
        <Switch>
          <Route exact path="/">
            <Dashboard />
          </Route>
          <Route exact path="/gui">
            <InterfaceGuide />
          </Route>
          <Route path="/users">
            <UsersScreen />
          </Route>
          <Route path="/customers">
            <CustomerBaseScreen />
          </Route>
          <Route path="/inventory">
            <InventoryBaseScreen />
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Layout>
    </Router>
  );
};

function App(): any {
  return (
    <LocaleProvider>
      <SnackContainer.Provider>
        <AuthProvider>
          <ModalContainer.Provider>
            <AuthContext.Consumer>
              {({ state }) =>
                state.state === 'LOGGED_IN' ? (
                  <AuthenticatedRouter />
                ) : state.state === 'LOADING' ? (
                  <Splash />
                ) : (
                  <AnonymousRouter />
                )
              }
            </AuthContext.Consumer>
            <ModalBase />
          </ModalContainer.Provider>
        </AuthProvider>
        <SnackBar />
      </SnackContainer.Provider>
    </LocaleProvider>
  );
}

export default App;
