import React, { FunctionComponent } from 'react';

interface OwnProps {}

type Props = OwnProps;

const Line: FunctionComponent<Props> = (props) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {props.children}
    </div>
  );
};

export default Line;
