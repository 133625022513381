import React from 'react';

export const InfoIcon: React.FC = () => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 0C5.3832 0 0 5.38327 0 12.0001C0 18.6169 5.3832 24 12 24C18.6168 24 24 18.6169 24 12.0001C24 5.38327 18.6168 0 12 0ZM12 21.8182C6.58618 21.8182 2.18182 17.4138 2.18182 12.0001C2.18182 6.58633 6.58618 2.18182 12 2.18182C17.4138 2.18182 21.8182 6.58633 21.8182 12.0001C21.8182 17.4138 17.4137 21.8182 12 21.8182Z"
        fill="#0C65EA"
        strokeWidth={1}
      />
      <path
        d="M11.9998 5.09094C11.1979 5.09094 10.5455 5.74374 10.5455 6.54614C10.5455 7.34782 11.1979 8.00003 11.9998 8.00003C12.8017 8.00003 13.454 7.34782 13.454 6.54614C13.454 5.74374 12.8017 5.09094 11.9998 5.09094Z"
        fill="#0C65EA"
        strokeWidth={1}
      />
      <path
        d="M11.9999 10.1818C11.3974 10.1818 10.909 10.6703 10.909 11.2727V17.8182C10.909 18.4207 11.3974 18.9091 11.9999 18.9091C12.6023 18.9091 13.0908 18.4207 13.0908 17.8182V11.2727C13.0908 10.6703 12.6023 10.1818 11.9999 10.1818Z"
        fill="#0C65EA"
        strokeWidth={1}
      />
    </svg>
  );
};

export default InfoIcon;
