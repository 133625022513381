import React, { CSSProperties } from 'react';
import { FormError, FormInputWrapper, FormLabel, FormTextarea, FormWrapper } from '../form.styled';
import { InputType } from '../../../@types';
import { useFormContext } from 'react-hook-form';
import { ERROR_MESSAGES } from '../error-messages';

type TextareaProps = InputType & {
  rows?: number;
  style?: CSSProperties;
  required?: boolean;
};

export const TextareaInput: React.FC<TextareaProps> = ({
  name,
  inputRef,
  label,
  placeholder,
  disabled = false,
  rows = 4,
  style,
  defaultValue,
  required = false,
}) => {
  const [status, setStatus] = React.useState<string>('pristine');
  const [error, setError] = React.useState<string>('');
  const formMethods = useFormContext();

  const onFocus = () => setStatus('focus');
  const onBlur = () => {
    if (error) {
      setStatus('error');
    } else {
      setStatus('pristine');
    }
  };

  React.useEffect(() => {
    if (status === 'focus') {
      return;
    } else if (error) {
      setStatus('error');
    } else if (disabled) {
      setStatus('disabled');
    } else {
      setStatus('pristine');
    }
  }, [error, status, disabled]);

  React.useEffect(() => {
    //todo: refactor. DRY
    if (!required) {
      return;
    }
    const parts = name.split('.');
    const error = parts.reduce((object: any, currentName) => {
      if (typeof object === 'string' || object === undefined) {
        return object;
      }
      if (object.hasOwnProperty(currentName)) {
        return object[currentName];
      }
      return undefined;
    }, formMethods.errors);

    if (error && error.hasOwnProperty('message')) {
      setError(error['message']);
    }
  }, [formMethods.errors, required, name]);

  return (
    <FormWrapper htmlFor={name} style={style}>
      <FormInputWrapper status={status}>
        <FormLabel status={status}>{label ? label : name}</FormLabel>
        <FormTextarea
          status={status}
          name={name}
          id={name}
          ref={
            formMethods
              ? formMethods.register({ required: required && ERROR_MESSAGES.required })
              : inputRef
          }
          placeholder={placeholder ? placeholder : label}
          disabled={disabled}
          rows={rows}
          onFocus={onFocus}
          onBlur={onBlur}
          defaultValue={defaultValue}
        />
      </FormInputWrapper>
      <FormError>{error}</FormError>
    </FormWrapper>
  );
};
