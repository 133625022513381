import React, { FunctionComponent } from 'react';
import { PageBase } from '../layout/page/page';
import { Button, Header } from '..';
import Table from '../table/table';
import { RowActionType, TableProperty } from '../table/table-property';
import { Row } from '../layout/layout.styled';
import pluralize from 'typescript-pluralize';
import { PageContent, PageTop } from '../layout/page/page.styled';
import EditIcon from '../../../CapeMorris/icons/edit.icon';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import IriHelper from '../../helpers/iri-helper';
import { FilterType } from '../../@types';
import api from '../../services/api';
import SnackContainer from '../snack-bar/use-snack-container';

interface OwnProps {
  name: string;
  title?: string;
  url: string;
  properties: TableProperty[];
  rowActions?: RowActionType[];
  canCreate?: boolean;
  canArchive?: boolean;
  canEdit?: boolean;
  canClone?: boolean;
  onCreate?: () => void;
  onItemClick?: (item: any) => void;
  filters?: FilterType[];
  reloadToken?: any;
}

type Props = OwnProps;

const TablePage: FunctionComponent<Props> = ({
  canEdit = true,
  canClone = true,
  canArchive = true,
  ...props
}) => {
  const { push } = useHistory();
  let { url } = useRouteMatch();
  const { showSnack } = SnackContainer.useContainer();
  const [rowActions, setRowActions] = React.useState<RowActionType[]>([]);

  const [reloadToken, setReloadToken] = React.useState(props.reloadToken);
  const { state: locationState } = useLocation<{ _filters: any }>();
  React.useEffect(() => {
    if (canEdit) {
      rowActions.push({
        name: 'edit',
        label: 'Edytuj',
        icon: <EditIcon />,
        onClick: (name: string, object: any) => {
          const id = IriHelper.iriToId(object['@id']);
          push(`${url}/${id}/edit`);
        },
      });
    }
    if (canClone) {
      rowActions.push({
        name: 'clone',
        label: 'Klonuj',
        icon: <EditIcon />,
        hidden: true,
        onClick: (name: string, object: any) => {
          const id = IriHelper.iriToId(object['@id']);
          push(`${url}/create`, { cloneId: id });
        },
      });
    }
    if (canArchive) {
      rowActions.push({
        name: 'archive',
        label: (item) => (item.deletedAt ? 'Przywróć' : 'Archiwum'),
        icon: <EditIcon />,
        hidden: true,
        onClick: (name: string, object: any) => {
          if (!object.deletedAt) {
            api.delete(object['@id'].substr(4)).then(() => {
              setReloadToken(Math.random());
              showSnack({
                type: 'success',
                title: 'Sukces',
                message: 'Obiekt został zarchiwizowany',
              });
            });
          } else {
            api.put(object['@id'].substr(4), { deletedAt: null }).then(() => {
              setReloadToken(Math.random());
              showSnack({
                type: 'success',
                title: 'Sukces',
                message: 'Obiekt został przywrócony',
              });
            });
          }
        },
      });
    }
  }, []);

  React.useEffect(() => {
    setReloadToken(props.reloadToken);
  }, [props.reloadToken]);

  const handleRowClick = (item: any) => {
    if (props.onItemClick) {
      props.onItemClick(item);
    } else {
      const id = IriHelper.iriToId(item['@id']);
      push(`${url}/${id}/show`);
    }
  };

  return (
    <PageBase>
      <PageTop>
        <Row>
          <Header>{props.title ?? pluralize.plural(props.name)}</Header>
          {typeof props.onCreate === 'function' ? (
            <Button onClick={props.onCreate} text={`Dodaj ${props.name.toLowerCase()}`} />
          ) : null}
        </Row>
      </PageTop>
      <PageContent disableMargin>
        <Table
          canArchive={canArchive}
          reloadToken={reloadToken}
          predefinedFilters={locationState?._filters ?? undefined}
          url={props.url}
          properties={props.properties}
          rowActions={rowActions}
          onItemClick={handleRowClick}
          filters={props.filters}
        />
      </PageContent>
    </PageBase>
  );
};

export default TablePage;
