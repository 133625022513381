import React from 'react';
import { CellTemplate } from '../table-property';
import MoneyAsString from '../../Money/MoneyAsString';
import { Money } from '../../../@types';

const MoneyTemplate: CellTemplate<Money> = (props) => {
  return <MoneyAsString money={props.value} />;
};

export default MoneyTemplate;
