import React, { FunctionComponent } from 'react';
import * as Components from './TableFilters.styled';
import { FormProvider, useForm } from 'react-hook-form';
import { CustomComponentPropsType, FilterType } from '../../../@types';
import TextFilter from './TextFilter';
import SelectFilter from './SelectFilter';
import { Grid } from '@material-ui/core';
import IriHelper from '../../../helpers/iri-helper';
import SwitchFilter from './SwitchFilter';
import StatusFilter from '../../../../components/Inventory/StatusFilter';

interface OwnProps {
  onUpdate: (filters: any) => void;
  filters: FilterType[];
  defaultValues?: any;
  archive?: boolean;
  searchFilter: boolean;
}

type Props = OwnProps;

const TableFilters: FunctionComponent<Props> = (props) => {
  const formMethods = useForm({
    defaultValues: props.defaultValues,
  });
  const [filters, setFilters] = React.useState<FilterType[]>([]);
  const [_timeout, _setTimeout] = React.useState<any>();

  React.useEffect(() => {
    if (props.searchFilter) {
      setFilters([{ name: '_search', type: 'text', label: 'Wyszukiwarka' }, ...props.filters]);
    } else {
      setFilters(props.filters);
    }

    if (props.archive) {
      setFilters((f) => [...f, { name: '_archived', type: 'switch', label: 'Archiwum' }]);
    }
  }, [props.filters, props.archive]);

  const handleChange = React.useCallback(() => {
    if (_timeout) {
      clearTimeout(_timeout);
    }
    const timeout = setTimeout(() => {
      const values = { ...formMethods.getValues()['filters'] };
      console.log(values);
      const result: any = {};
      for (let key in values) {
        let value = values[key];
        if (typeof values[key] === 'string') {
          if (values[key].startsWith('/api')) {
            value = IriHelper.iriToId(values[key]);
          } else if (value.includes(',')) {
            value = values[key].split(',');
          } else {
            value = values[key];
          }
        } else if (typeof values[key] === 'boolean') {
          value = values[key] ? 1 : 0;
        }

        result[key.split('__').join('.')] = value;
      }
      props.onUpdate(result);
    }, 400);
    _setTimeout(timeout);
  }, [_timeout, formMethods, props]);
  return (
    <Components.Container>
      <FormProvider {...formMethods}>
        <Grid container spacing={2}>
          {filters.map((filter) => {
            let Component = undefined;
            const defaultValue =
              props.defaultValues && props.defaultValues[filter.name]
                ? props.defaultValues[filter.name]
                : undefined;
            const name = filter.name.split('.').join('__');
            switch (filter.type) {
              case 'text':
                Component = (
                  <TextFilter
                    size={true}
                    key={`filter-${filter.name}`}
                    defaultValue={defaultValue}
                    name={name}
                    label={filter.label}
                    onChange={handleChange}
                  />
                );
                break;
              case 'select':
                Component = (
                  <SelectFilter
                    size={true}
                    key={`filter-${filter.name}`}
                    name={name}
                    label={filter.label}
                    onChange={handleChange}
                    baseUrl={filter.baseUrl}
                    optionLabel={filter.optionLabel}
                    options={filter.options}
                  />
                );
                break;
              case 'switch':
                Component = (
                  <SwitchFilter
                    size={true}
                    key={`filter-${filter.name}`}
                    name={name}
                    label={filter.label}
                    onChange={handleChange}
                  />
                );
                break;
              case 'custom':
                if (filter.customComponent) {
                  Component = React.createElement<CustomComponentPropsType>(
                    filter.customComponent,
                    {
                      onChange: handleChange,
                      size: true,
                      name: filter.name,
                      label: filter.label,
                      key: `filter-${filter.name}`,
                    },
                  );
                }
                break;
            }
            return Component;
          })}
        </Grid>
      </FormProvider>
    </Components.Container>
  );
};

export default TableFilters;
