import styled, { css } from 'styled-components';
import { COLORS_BY_FUNCTION, COLORS_BY_SENTIMENT } from '../../../assets/colors';
import { WEIGHTS } from '../../../assets/fonts';

type TooltipProps = {
  show?: boolean;
};

export const TooltipContainer = styled.div<TooltipProps>`
  background-color: ${COLORS_BY_SENTIMENT.PRIMARY.LIGHTEST};
  border: 1px solid ${COLORS_BY_FUNCTION.BORDER};
  padding: 5px;
  border-radius: 7px;
  font-size: 12px;
  font-weight: ${WEIGHTS.SEMI_BOLD};
  display: none;
  ${(props) =>
    props.show &&
    css`
      display: block;
    `}
`;
