import React, { FunctionComponent } from 'react';
import { CURRENCIES, DEFAULT_CURRENCY, Money } from '../../@types';

interface OwnProps {
  money: Money;
}

type Props = OwnProps;

const MoneyAsString: FunctionComponent<Props> = ({ money, ...props }) => {
  return (
    <>
      {(money.value / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}{' '}
      {CURRENCIES[money.currency ?? DEFAULT_CURRENCY]}
    </>
  );
};

export default MoneyAsString;
