import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import * as Modal from './modal.styled';

interface OwnProps {}

type Props = OwnProps;

const ModalFooter: FunctionComponent<Props> = (props) => {
  return (
    <Modal.Footer>
      <Grid container justify="flex-end">
        {props.children}
      </Grid>
    </Modal.Footer>
  );
};

export default ModalFooter;
