import styled from 'styled-components';
import COLORS from '../../assets/colors';

export const Wrapper = styled.div`
  background-color: #f2f2f2;
  width: 100vw;
  height: 100vh;
  display: flex;
`;

export const LoginFormContainer = styled.div`
  background-color: ${COLORS.WHITE};
  width: 28vw;
  height: 65vh;
  border-radius: 5px;
  margin: auto 10vw;
  align-content: center;
  padding: 40px 50px;

  @media screen and (max-width: 640px) {
    width: 100vw;
    margin: auto 2vw;
    height: 80vh;
    padding: 40px 30px;
  }
  @media screen and (min-width: 1660px) {
    max-width: 473px;
    max-height: 664px;
    padding: 40px 50px;
  }
`;

export const LogoWrapper = styled.div``;
