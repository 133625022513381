import styled from 'styled-components';
import COLORS from '../../../../assets/colors';

export const Container = styled.div`
  background-color: ${COLORS.WHITE};
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  align-content: center;
  align-items: center;
`;

export const Burger = styled.div`
  margin-right: 24px;
  cursor: pointer;
  transition: transform 0.2s ease;
  :active {
    transform: scale(0.9);
  }
`;

export const UserContainer = styled.div`
  margin-left: auto;
  justify-self: flex-end;
  display: flex;
  flex-direction: row;
`;
