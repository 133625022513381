import React from 'react';
import { AuthContext } from '../../context/auth-context/auth-context';
import { UserName } from './user-indicator.styled';

export const UserIndicator: React.FC = () => {
  const { state } = React.useContext(AuthContext);
  const { user } = state;
  return (
    <div>
      <UserName>
        {user?.profile?.firstName} {user?.profile?.lastName}
      </UserName>
    </div>
  );
};
