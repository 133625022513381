import React, { FunctionComponent } from 'react';
import { HydraView } from '../../@types';
import { PageButton, PaginationContainer } from './pagination.styled';
import { COLORS_BY_SENTIMENT } from '../../../assets/colors';

interface OwnProps {
  view: HydraView | undefined;
  currentPage: number;
  totalItems: number;
  handlePageClick: (page: number) => void;
}

type Props = OwnProps;

const Pagination: FunctionComponent<Props> = (props) => {
  const [pages, setPages] = React.useState<number[]>([]);

  React.useEffect(() => {
    if (props.view === undefined) {
      setPages([]);
      return;
    }
    const view = props.view;
    const pages = [];
    const first = view['hydra:first'] ? getPageFromView(view['hydra:first']) : null;
    const previous = view['hydra:previous'] ? getPageFromView(view['hydra:previous']) : null;
    const current = getPageFromView(view['@id']);
    const next = view['hydra:next'] ? getPageFromView(view['hydra:next']) : null;
    const last = view['hydra:last'] ? getPageFromView(view['hydra:last']) : null;

    if (first && first !== current) {
      pages.push(first);
    }

    if (previous && previous !== first) {
      pages.push(previous);
    }

    pages.push(current);

    if (next && next !== last) {
      pages.push(next);
    }
    if (last && last !== current) {
      pages.push(last);
    }
    setPages(pages);
  }, [props.view]);

  const getPageFromView = (viewItem: string): number => {
    let parts = viewItem.split('?');
    parts = parts[1].split('&');
    parts = parts.filter((p) => p.startsWith('page='));
    if (parts.length === 0) {
      return 1;
    }

    return parseInt(parts[0].split('=')[1]);
  };
  return (
    <PaginationContainer>
      {pages.map((page) => (
        <PageButton
          key={page}
          onClick={() => props.handlePageClick(page)}
          active={page === props.currentPage}>
          {page}
        </PageButton>
      ))}
      <div style={{ marginLeft: '3em' }}>
        Total items:{' '}
        <span style={{ color: COLORS_BY_SENTIMENT.PRIMARY.DARK }}>{props.totalItems}</span>
      </div>
    </PaginationContainer>
  );
};

export default Pagination;
