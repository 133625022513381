import styled from 'styled-components';

type IconContainerType = {
  size: number;
  color: string;
};

export const IconContainer = styled.div<IconContainerType>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;

  svg {
    width: 100%;
    height: 100%;
    path,
    rect,
    polygon {
      stroke: ${(props) => props.color};
      fill: ${(props) => props.color};
    }
  }
`;
