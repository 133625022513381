import React, { FunctionComponent } from 'react';
import { CellTemplate } from '../table-property';
import { AssetType } from '../../../@types';
import placeholder from '../../../../assets/images/car-placeholder.png';
import { createFullApiUrl } from '../../../services/api';
interface OwnProps {}

type Props = OwnProps;

const ImageTemplate: CellTemplate<AssetType> = ({ value, ...props }) => {
  const [src, setSrc] = React.useState<string | any>(placeholder);
  React.useEffect(() => {
    if (value) {
      setSrc(createFullApiUrl('/files/' + value.file.id + '/preview'));
    }
  }, []);
  return (
    <div style={{ height: 50 }}>
      <img src={src} style={{ width: 'auto', height: '100%' }} />
    </div>
  );
};

export default ImageTemplate;
