import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENTRY_POINT ?? '',
  withCredentials: true,
  auth: {
    username: 'capemorris',
    password: 'Cape1239!@#',
  },
});

export const createFullApiUrl = (path: string) => {
  if (path[0] !== '/') {
    path = '/' + path;
  }
  return (process.env.REACT_APP_API_ENTRY_POINT ?? '') + path;
};

export default api;
