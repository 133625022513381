import React, { FunctionComponent } from 'react';
import api from '../../services/api';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { RowActionType, TableProperty } from './table-property';
import TableItem from './table-item';
import { Table as StyledTable, TableHead } from './table.styled';
import { FilterType, HydraView } from '../../@types';
import Pagination from './pagination';
import TableFilters from './filters';
import SortIcon from '../../../CapeMorris/icons/sort.icon';
import { AuthContext } from '../../context/auth-context/auth-context';
import { Loader } from '../loader/loader';

interface OwnProps {
  url: string;
  properties: TableProperty[];
  rowActions?: RowActionType[];
  filters?: FilterType[];
  onItemClick?: (item: any) => void;
  predefinedFilters?: any;
  perPage?: number;
  reloadToken?: any;
  canArchive?: boolean;
  searchFilter?: boolean;
}

type Props = OwnProps;

type StateType = {
  perPage: number;
  page: number;
  $promise: null | Promise<AxiosResponse>;
  items: null | any[];
  view?: HydraView;
  totalItems: number;
  sortBy: null | string;
  sortDirection: undefined | 'asc' | 'desc';
  filterValues: any;
};

type ResponseType = AxiosResponse<{
  'hydra:member': any[];
  '@context': string;
  'hydra:totalItems': number;
  'hydra:view': HydraView;
}>;

const Table: FunctionComponent<Props> = ({
  url,
  properties,
  rowActions,
  onItemClick,
  filters,
  predefinedFilters,
  perPage = 50,
  reloadToken,
  canArchive = false,
  searchFilter = true,
}) => {
  const [state, setState] = React.useState<StateType>({
    perPage: perPage,
    page: 1,
    $promise: null,
    items: null,
    sortBy: null,
    sortDirection: undefined,
    totalItems: 0,
    filterValues: {},
  });
  const { logout } = React.useContext(AuthContext);
  const [cancelToken, setCancelToken] = React.useState<any | undefined>();
  const [waiting, setWaiting] = React.useState(false);

  React.useEffect(() => {
    const waitingTimeout = setTimeout(() => setWaiting(true), 300);
    if (cancelToken) {
      cancelToken.cancel();
    }
    const params: any = {
      perPage: state.perPage,
      page: state.page,
      ...state.filterValues,
    };
    if (state.sortBy && state.sortDirection) {
      params[`order[${state.sortBy}]`] = state.sortDirection;
    }

    const cancelTokenSource = axios.CancelToken.source();
    setCancelToken(cancelTokenSource);
    api
      .get(url, {
        params: params,
        cancelToken: cancelTokenSource.token,
      })
      .then((response: ResponseType) => {
        clearTimeout(waitingTimeout);
        setWaiting(false);
        setState((s) => {
          return {
            ...s,
            items: response.data['hydra:member'],
            view: response.data['hydra:view'],
            totalItems: response.data['hydra:totalItems'],
          };
        });
      })
      .catch((error: AxiosError) => {
        const response = error.response;
        setWaiting(false);
        if (response?.status === 401) {
          logout();
        }
      });
  }, [
    state.perPage,
    state.page,
    url,
    state.sortDirection,
    state.sortBy,
    state.filterValues,
    reloadToken,
  ]);

  const updatePage = (page: number) => {
    setState({
      ...state,
      page: page,
    });
  };

  React.useEffect(() => {
    if (!predefinedFilters) {
      return;
    }
    setState((state) => ({
      ...state,
      filterValues: {
        ...predefinedFilters,
      },
    }));
  }, [predefinedFilters]);

  const order = React.useCallback(
    (prop: TableProperty) => {
      if (!prop.sortable) {
        return;
      }
      const name = typeof prop.sortable === 'string' ? prop.sortable : prop.name;
      if (state.sortBy !== name) {
        setState((state) => ({
          ...state,
          sortBy: name,
          sortDirection: 'asc',
        }));
      } else if (state.sortDirection === 'asc') {
        setState((state) => ({
          ...state,
          sortDirection: 'desc',
        }));
      } else {
        setState((state) => ({
          ...state,
          sortDirection: undefined,
          sortBy: null,
        }));
      }
    },
    [state.sortBy, state.sortDirection],
  );

  return (
    <>
      <TableFilters
        archive={canArchive}
        defaultValues={predefinedFilters}
        onUpdate={(filterValues) => setState({ ...state, filterValues })}
        filters={filters ? filters : []}
        searchFilter={searchFilter}
      />
      <StyledTable>
        <TableHead>
          <tr>
            {properties.map((prop) => (
              <th
                key={`th-${prop.name}`}
                onClick={() => order(prop)}
                style={{ cursor: prop.sortable ? 'pointer' : 'normal' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {prop.visibleName}{' '}
                  {prop.sortable && (
                    <SortIcon
                      style={{ marginLeft: 5 }}
                      direction={
                        state.sortBy ===
                        (typeof prop.sortable === 'string' ? prop.sortable : prop.name)
                          ? state.sortDirection
                          : undefined
                      }
                    />
                  )}
                </div>
              </th>
            ))}
            <th>Akcje</th>
          </tr>
        </TableHead>
        <tbody style={{ minHeight: 200, position: 'relative' }}>
          {waiting && (
            <tr>
              <td colSpan={properties.length}>
                <Loader />
              </td>
            </tr>
          )}
          {state.items &&
            state.items.map((i) => (
              <TableItem
                item={i}
                properties={properties}
                key={`row-${i['@id']}`}
                onClick={() => onItemClick && onItemClick(i)}
                actions={rowActions}
              />
            ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={properties.length}>
              <Pagination
                totalItems={state.totalItems}
                view={state.view}
                currentPage={state.page}
                handlePageClick={updatePage}
              />
            </td>
          </tr>
        </tfoot>
      </StyledTable>
    </>
  );
};

export default Table;
//todo: move to base admin
