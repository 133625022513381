import React, { FunctionComponent } from 'react';
import FormPage from '../../../CapeMorris/components/form-page/form-page';
import CustomerForm from './CustomerFormScreen/CustomerForm';

interface OwnProps {}

type Props = OwnProps;

const CustomerFormScreen: FunctionComponent<Props> = (props) => {
  return (
    <FormPage title={'Dodaj nowego klienta'} editTitle={'Edytuj klienta'}>
      {(methods, isEdit) => <CustomerForm />}
    </FormPage>
  );
};

export default CustomerFormScreen;
