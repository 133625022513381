import React, { FunctionComponent } from 'react';
import * as Components from './AssetPreview.styled';

interface OwnProps {
  name: string;
  value: string | React.ReactElement | null | undefined;
}

type Props = OwnProps;

const Property: FunctionComponent<Props> = (props) => {
  return (
    <Components.AssetProp>
      <Components.AssetPropName>{props.name}</Components.AssetPropName>
      <Components.AssetPropValue>{props.value}</Components.AssetPropValue>
    </Components.AssetProp>
  );
};

export default Property;
