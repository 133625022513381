import styled from 'styled-components';
import { COLORS_BY_SENTIMENT } from '../../../../assets/colors';
import { WEIGHTS } from '../../../../assets/fonts';

export const MenuTooltip = styled.div<MenuTooltipProps>`
  position: absolute;
  left: 52px;
  color: white;
  background-color: ${COLORS_BY_SENTIMENT.PRIMARY.DARKEST};
  padding: 5px 11px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: ${WEIGHTS.SEMI_BOLD};
  opacity: 0;
  transition: opacity 0.2s ease;
  visibility: hidden;

  :before {
    background-color: ${COLORS_BY_SENTIMENT.PRIMARY.DARKEST};
    width: 10px;
    height: 10px;
    display: block;
    content: ' ';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    border-radius: 2px;
  }
`;

export const MenuLink = styled.div`
  width: 52px;
  height: 52px;
  cursor: pointer;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 26px;
  :active {
    svg {
      transform: scale(0.9);
    }
  }
  :hover ${MenuTooltip} {
    visibility: visible;
    opacity: 1;
  }
  svg {
    transition: transform 0.2s ease;
    path {
      transition: all 0.2s ease;
    }
  }
`;

type MenuTooltipProps = {
  show?: boolean;
};
