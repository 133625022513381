import styled, { keyframes } from 'styled-components';
import COLORS, { COLORS_BY_SENTIMENT } from '../../../assets/colors';
import { fadeIn } from 'react-animations';

const animation = keyframes`${fadeIn}`;

export const Background = styled.div`
  animation: 1 0.2s ${animation};
  z-index: 20;
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${COLORS_BY_SENTIMENT.PRIMARY.NORMAL}bb;
  backdrop-filter: blur(2px);
`;

type WrapperProps = {
  wide?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${(props) => (props.wide ? '90vw' : '40vw')};
  max-height: 90vh;
  display: block;
  background-color: ${COLORS.WHITE};
  border-radius: 16px;
  overflow: hidden;
`;

export const Title = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  padding: 24px 30px;
  border-bottom: 1px solid #efefef;
`;

export const Close = styled.div`
  cursor: pointer;
  transition: transform 0.2s ease;
  padding: 10px;
  &:hover {
    transform: rotate(90deg);
  }
  &:active {
    transform: scale(0.8) rotate(90deg);
  }
`;

export const Body = styled.div`
  padding: 24px 30px;
  max-height: 60vh;
  overflow-y: auto;
`;

export const Footer = styled.div`
  padding: 24px 30px;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
`;
