import styled from 'styled-components';
import { COLORS_BY_SENTIMENT } from '../../../../assets/colors';
import { WEIGHTS } from '../../../../assets/fonts';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  background-color: ${COLORS_BY_SENTIMENT.PRIMARY.LIGHTEST};
`;

type PageTabProps = {
  active?: boolean;
};

export const PageTab = styled.div<PageTabProps>`
  bottom: 123px;
  font-weight: ${WEIGHTS.BOLD};
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  cursor: ${(props) => (props.active ? 'normal' : 'pointer')};
  margin-right: 60px;
  padding: 15px 0;
  border-bottom: 3px solid;
  border-color: ${(props) => (props.active ? COLORS_BY_SENTIMENT.PRIMARY.NORMAL : 'transparent')};
`;
