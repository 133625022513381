import React, { FunctionComponent } from 'react';
import { parseDate } from '../../helpers/date';

interface OwnProps {
  input: string | Date;
  time?: boolean;
}

type Props = OwnProps;

const DateAsString: FunctionComponent<Props> = ({ input, time = false, ...props }) => {
  return <>{input && parseDate(input, time)}</>;
};

export default DateAsString;
