import React from 'react';

export type EntityType = {
  '@id': string;
} & any;

export type EntityStateType = {
  entity: EntityType | undefined;
  processing: boolean;
};

export type EntityContextType = {
  state: EntityStateType;
  create: (entity: any) => Promise<EntityType>;
  save: (id: number | string, entity: any) => Promise<EntityType>;
  load: (entity: any, reload?: boolean) => Promise<EntityType>;
  clone: (entity: any) => Promise<EntityType>;
  rootPath: string;
};

export const EntityContext = React.createContext<EntityContextType>({
  state: {
    entity: undefined,
    processing: false,
  },
  create: (entity: any) => Promise.reject('undefied'),
  save: (id: number | string, entity: any) => Promise.reject('undefied'),
  load: (id: number) => Promise.reject('undefied'),
  clone: (id: number) => Promise.reject('undefied'),
  rootPath: '/',
});
