import React from 'react';
import { CellTemplate } from '../table-property';
import IriHelper from '../../../helpers/iri-helper';
import { WEIGHTS } from '../../../../assets/fonts';

const IdTemplate: CellTemplate<number | string> = (props) => {
  return (
    <span style={{ fontWeight: WEIGHTS.BOLD }}>
      # {typeof props.value === 'number' ? props.value : IriHelper.iriToId(props.value)}
    </span>
  );
};

export default IdTemplate;
//todo: move to base-admin
