import styled from 'styled-components';
import { COLORS_BY_SENTIMENT } from '../../../../assets/colors';

type ContainerProps = {
  active: boolean;
};

export const Container = styled.div<ContainerProps>`
  background-color: ${COLORS_BY_SENTIMENT.PRIMARY.LIGHTEST};
  transition: 0.2s ease;
  transition-property: width, right, padding-left, padding-right;
  width: ${(props) => (props.active ? '300px' : 0)};
  right: ${(props) => (props.active ? '-300px' : 0)};
  padding-left: ${(props) => (props.active ? '30px' : 0)};
  padding-right: ${(props) => (props.active ? '30px' : 0)};
  height: 100%;
  overflow-y: ${(props) => (props.active ? 'scroll' : 'hidden')};
  position: absolute;
  top: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 9;
  padding-top: 30px;
`;
