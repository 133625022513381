import styled from 'styled-components';
import COLORS, { COLORS_BY_SENTIMENT } from '../../../../assets/colors';
import { WEIGHTS } from '../../../../assets/fonts';

type SwitchProps = {
  checked?: boolean;
};

export const Container = styled.div`
  min-height: 48px;
  display: flex;
  align-content: center;
  align-items: center;
`;

export const SwitchLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  min-height: 20px;
  height: auto;
  padding-left: 45px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  opacity: 0;
  position: absolute;
  white-space: nowrap;
  width: 0;
`;

export const SwitchWrapper = styled.div<SwitchProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 38px;
  height: 20px;
  cursor: pointer;
`;

export const StyledCheckbox = styled.p<SwitchProps>`
  position: relative;
  width: 38px;
  height: 20px;
  padding: 0 3px;
  background-color: ${(props) => (props.checked ? COLORS.GREEN.NORMAL : '#ccc')};
  border-radius: 34px;
  transition: 0.4s;
  &::before {
    position: absolute;
    content: '';
    height: 13px;
    width: 13px;
    left: ${(props) => (props.checked ? '22px' : '4px')};
    bottom: 50%;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    transform: translate(0, 50%);
  }
`;

export const SwitchText = styled.span`
  font-size: 13px;
  font-weight: ${WEIGHTS.SEMI_BOLD};
  color: ${COLORS.GREY.DARK};
`;
