import React, { FunctionComponent } from 'react';
import { RowActionType } from './table-property';
import InlineAction from '../inline-action/InlineAction';
import { Grid } from '@material-ui/core';

interface OwnProps {
  actions: RowActionType[];
  item: any;
}

type Props = OwnProps;

const TableRowActionsMenu: FunctionComponent<Props> = (props) => {
  const handleActionClick = (action: RowActionType) => {
    action.onClick(action.name, props.item);
  };
  return (
    <Grid container justify={'center'} spacing={1}>
      {props.actions.map((action, index) => (
        <Grid item md={12} key={index}>
          <InlineAction
            onClick={() => handleActionClick(action)}
            text={typeof action.label === 'string' ? action.label : action.label(props.item)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default TableRowActionsMenu;
