import React, { FunctionComponent } from 'react';
import { TextInput } from '../..';
import { Grid } from '@material-ui/core';

interface OwnProps {
  defaultValue?: string;
  name: string;
  label: string;
  onChange: (value: any) => void;
  size: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
}

type Props = OwnProps;

const TextFilter: FunctionComponent<Props> = (props) => {
  return (
    <Grid item md={props.size}>
      <TextInput
        defaultValue={props.defaultValue}
        name={`filters.${props.name}`}
        label={props.label}
        onChange={props.onChange}
      />
    </Grid>
  );
};

export default TextFilter;
