import styled, { css } from 'styled-components';
import COLORS, { COLORS_BY_SENTIMENT } from '../../../assets/colors';
import { WEIGHTS } from '../../../assets/fonts';
import { darken, lighten } from 'polished';

type ButtonWrapperProps = {
  color: string;
  inverted?: boolean;
  wide?: boolean;
  disabled?: boolean;
  noPadding: boolean;
  small: boolean;
};

export const ButtonWrapper = styled.button<ButtonWrapperProps>`
  position: relative;
  background-color: ${(props) => (!props.inverted ? props.color : 'transparent')};
  color: ${(props) => (props.inverted ? props.color : COLORS.WHITE)};
  border: none;
  border-radius: ${(props) => (props.small ? '20px' : '60px')};
  padding: ${(props) => (props.noPadding ? 0 : props.small ? '6px 10px' : '10px 30px')};
  width: ${(props) => props.wide && '100%'};
  min-height: ${(props) => (props.small ? '25px' : '44px')};
  line-height: 0;
  cursor: pointer;
  outline: none;
  font-weight: ${WEIGHTS.BOLD};
  font-size: ${(props) => (props.small ? '11px' : '14px')};
  letter-spacing: 1px;
  transition: background-color 0.1s ease, transform 0.1s ease;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${(props) =>
      !props.inverted ? darken(0.05, props.color) : lighten(0.5, props.color)};
  }
  &:active {
    background-color: ${(props) =>
      !props.inverted ? darken(0.1, props.color) : lighten(0.45, props.color)};
    outline: none;
    transform: scale(0.99);
  }

  ${(props) =>
    props.disabled &&
    props.inverted &&
    css`
      pointer-events: none;
      color: ${COLORS_BY_SENTIMENT.DISABLED};
    `};
  ${(props) =>
    props.disabled &&
    !props.inverted &&
    css`
      pointer-events: none;
      background-color: ${COLORS_BY_SENTIMENT.DISABLED};
    `};
`;
