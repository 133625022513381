import React, { FunctionComponent } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { TableProperty } from '../../../CapeMorris/components/table/table-property';
import IdTemplate from '../../../CapeMorris/components/table/cell-templates/id-template';
import StringTemplate from '../../../CapeMorris/components/table/cell-templates/string-template';
import TablePage from '../../../CapeMorris/components/table-page/table-page';
import DictionaryTemplate from '../../../CapeMorris/components/table/cell-templates/DictionaryTemplate';
import ImageTemplate from '../../../CapeMorris/components/table/cell-templates/ImageTemplate';

interface OwnProps {}

type Props = OwnProps;

const VehicleListScreen: FunctionComponent<Props> = (props) => {
  const { push } = useHistory();
  let { url } = useRouteMatch();
  const onCreate = () => {
    push(`${url}/create`);
  };
  const [properties] = React.useState<TableProperty[]>([
    {
      name: 'vin',
      visibleName: 'VIN',
      template: IdTemplate,
    },
    {
      name: 'make',
      visibleName: 'Marka',
      template: DictionaryTemplate,
    },
    {
      name: 'model',
      visibleName: 'Model',
      template: DictionaryTemplate,
    },
    {
      name: 'year',
      visibleName: 'Rok produkcji',
      template: StringTemplate,
    },
  ]);
  return (
    <TablePage
      onCreate={onCreate}
      title={'Wszystkie samochody'}
      name={'samochód'}
      url={'/vehicles'}
      properties={properties}
    />
  );
};

export default VehicleListScreen;
