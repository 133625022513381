import React, { FunctionComponent } from 'react';
import { useFormContext, UseFormMethods } from 'react-hook-form';

interface OwnProps {
  children: (methods: UseFormMethods<any>) => any;
}

type Props = OwnProps;

const FormContent: FunctionComponent<Props> = ({ children }) => {
  const methods = useFormContext();
  return <>{children(methods)}</>;
};

export default FormContent;
