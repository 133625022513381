import React, { forwardRef, FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import BaseDatePicker from 'react-datepicker';
import { InputType } from '../../../@types';
import 'react-datepicker/dist/react-datepicker.css';
import { FormInput, FormInputWrapper, FormLabel } from '../form.styled';
import { parseDate } from '../../../helpers/date';

type Props = InputType;

const CustomInput = forwardRef<{}, Props>((props: any, ref) => {
  return (
    <FormInputWrapper style={{ cursor: 'pointer' }} onClick={props.onClick} status={'ok'}>
      <FormLabel status={'ok'}>{props.label}</FormLabel>
      <FormInput
        onChange={(v) => v}
        style={{ cursor: 'pointer' }}
        status={'ok'}
        value={props.value}
        placeholder={props.placeholder ?? props.label}
      />
    </FormInputWrapper>
  );
});

const DatePicker: FunctionComponent<Props> = (props) => {
  const { control, register, watch, setValue } = useFormContext();
  const [ready, setReady] = React.useState(true);
  const t = watch(props.name);

  return (
    <>
      {ready && (
        <Controller
          control={control}
          name={props.name}
          render={(field) => {
            return (
              <BaseDatePicker
                placeholderText={props.placeholder ?? props.label}
                onChange={(date) => {
                  if (Array.isArray(date)) {
                    return date;
                  } else {
                    field.onChange(date?.toISOString());
                  }
                }}
                selected={field.value ? new Date(field.value) : null}
                showYearDropdown={true}
                isClearable={!props.required}
                customInput={<CustomInput {...props} />}
                dateFormat={'yyyy-MM-dd'}
              />
            );
          }}
        />
      )}
    </>
  );
};
export default DatePicker;
