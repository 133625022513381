import COLORS, { COLORS_BY_FUNCTION, COLORS_BY_SENTIMENT } from '../../../../assets/colors';
import { CSSProperties } from 'react';
import { WEIGHTS } from '../../../../assets/fonts';

export const selectStyles = (hasError: boolean | undefined, required?: boolean) => ({
  container: (styles: CSSProperties) => ({
    ...styles,
  }),
  control: (styles: CSSProperties) => ({
    ...styles,
    fontSize: 14,
    fontWeight: WEIGHTS.BOLD,
    color: hasError ? COLORS_BY_SENTIMENT.ERROR.NORMAL : COLORS_BY_SENTIMENT.PRIMARY.DARKEST,
    backgroundColor: hasError ? COLORS.RED.LIGHT : COLORS.WHITE,
    borderColor: hasError ? COLORS_BY_SENTIMENT.ERROR.NORMAL : COLORS_BY_FUNCTION.BORDER,
    borderWidth: 0,
    borderRadius: 0,
    cursor: 'pointer',
    borderBottomWidth: 1,
    padding: 0,
    margin: 0,
    boxShadow: 'none',
  }),
  valueContainer: (styles: CSSProperties) => ({
    ...styles,
    paddingLeft: 0,
  }),
  singleValue: (styles: CSSProperties) => ({
    ...styles,
    color: COLORS_BY_SENTIMENT.PRIMARY.DARKEST,
    paddingLeft: 0,
    marginLeft: 0,
  }),
  input: (styles: CSSProperties) => ({
    ...styles,
    fontSize: 14,
    padding: 0,
    margin: 0,
    color: hasError ? COLORS_BY_SENTIMENT.ERROR.NORMAL : COLORS_BY_SENTIMENT.PRIMARY.DARKEST,
    fontWeight: WEIGHTS.BOLD,
  }),
  placeholder: (styles: CSSProperties) => ({
    ...styles,
    fontSize: 14,
    fontWeight: WEIGHTS.BOLD,
    color: hasError ? COLORS_BY_SENTIMENT.ERROR.NORMAL : COLORS.GREY.NORMAL,
    padding: 0,
    margin: 0,
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    minHeight: 48,
    padding: 0,
    borderRadius: 0,
    backgroundColor: hasError ? COLORS.RED.LIGHT : COLORS.WHITE,
  }),
  menuList: (styles: CSSProperties) => ({
    ...styles,
    backgroundColor: COLORS.WHITE,
    borderRadius: 0,
    boxShadow: 'box-shadow: 0px 2px 4px rgba(0, 0, 0, .2)',
    padding: 0,
  }),
  option: (styles: CSSProperties) => ({
    ...styles,
    transition: 'all .3s ease-in-out',
    transitionProperty: 'color, background-color',
    fontSize: 14,
    cursor: 'pointer',
    '&:hover': {
      color: COLORS.WHITE,
      backgroundColor: COLORS_BY_SENTIMENT.PRIMARY.NORMAL,
    },
  }),
});
