import React, { FunctionComponent } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { LinkText } from './SubmenuLink.styled';

interface OwnProps {
  to: string;
}

type Props = OwnProps;

const SubmenuLink: FunctionComponent<Props> = (props) => {
  let match = useRouteMatch({
    path: props.to,
    exact: true,
  });

  return (
    <>
      {!match ? (
        <Link to={props.to} style={{ textDecoration: 'none' }}>
          <LinkText>{props.children}</LinkText>
        </Link>
      ) : (
        <LinkText active>{props.children}</LinkText>
      )}
    </>
  );
};

export default SubmenuLink;
