import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import { Button, Header, Tag } from '../../../../CapeMorris/components';
import { InventoryType } from '../../../../@types/Inventory/Inventory';
import Assets from '../../../digital-assets/components/Assets';
import COLORS from '../../../../assets/colors';
import ModalContainer from '../../../../CapeMorris/components/modal/use-modal-container';
import InventoryAssetsModal from './InventoryAssetsModal';
import { AssetTagType, AssetType } from '../../../../CapeMorris/@types';
import api from '../../../../CapeMorris/services/api';
import { AxiosResponse } from 'axios';

interface OwnProps {
  entity: InventoryType;
  onAssetsChange: (entity: InventoryType) => void;
}

type Props = OwnProps;

const InventoryShowAssets: FunctionComponent<Props> = ({ entity, ...props }) => {
  const { showModal } = ModalContainer.useContainer();
  const [tags, setTags] = React.useState<string[]>([]);
  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
  const [filteredAssets, setFilteredAssets] = React.useState<{ asset: AssetType }[]>(entity.assets);

  const handleAdd = React.useCallback(() => {
    showModal({
      title: 'Nowe pliki',
      body: <InventoryAssetsModal onSubmit={props.onAssetsChange} inventory={entity} />,
      wide: true,
    });
  }, [entity]);

  React.useEffect(() => {
    let _tags: string[] = [];
    setFilteredAssets(entity.assets);
    entity.assets.forEach((asset) => {
      asset.asset.tags.forEach((tag) => {
        if (!_tags.includes(tag.name)) {
          _tags.push(tag.name);
        }
      });
    });
    setTags(_tags);
  }, [entity.assets]);

  const isSelected = React.useCallback(
    (tag: string) => {
      return selectedTags.includes(tag);
    },
    [selectedTags],
  );

  const handleTagClick = React.useCallback(
    (tag: string) => {
      const idx = selectedTags.findIndex((t) => t === tag);
      if (idx === -1) {
        setSelectedTags((t) => [...t, tag]);
      } else {
        let tags = selectedTags;
        tags.splice(idx, 1);
        setSelectedTags([...tags]);
      }
    },
    [selectedTags],
  );

  React.useEffect(() => {
    if (selectedTags.length === 0) {
      setFilteredAssets(entity.assets);
    } else {
      let filtered = entity.assets.filter((asset) => {
        let tags = asset.asset.tags.map((t) => t.name);
        for (let i in selectedTags) {
          if (tags.includes(selectedTags[i])) {
            return true;
          }
        }
        return false;
      });
      setFilteredAssets([...filtered]);
    }
  }, [selectedTags]);

  const handleDelete = React.useCallback(
    (asset: AssetType) => {
      const idx = entity.assets.findIndex((a) => a.asset['@id'] === asset['@id']);
      if (idx === -1) {
        return;
      }
      const entityAsset = entity.assets[idx];
      api.delete(entityAsset['@id'].substr(4)).then((response) => {
        entity.assets.splice(idx, 1);
        props.onAssetsChange(entity);
      });
    },
    [entity.assets],
  );
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <Grid container spacing={2} alignContent={'center'}>
          <Grid item>
            <Header header2>Powiązane pliki</Header>
          </Grid>
          <Grid item>
            <Button color={COLORS.GREEN.NORMAL} text={'Dodaj'} small onClick={handleAdd} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12}>
        {tags.map((tag) => (
          <Tag
            key={tag}
            color={isSelected(tag) ? COLORS.BLUE.NORMAL : undefined}
            style={{ cursor: 'pointer' }}
            onClick={() => handleTagClick(tag)}>
            {tag}
          </Tag>
        ))}
      </Grid>
      <Grid item md={12} xs={12}>
        <Assets
          onDelete={handleDelete}
          assets={filteredAssets.map((a) => a.asset)}
          canUpload={false}
        />
      </Grid>
    </Grid>
  );
};

export default InventoryShowAssets;
