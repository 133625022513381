import styled, { keyframes } from 'styled-components';
import { COLORS_BY_SENTIMENT } from '../../../assets/colors';

const animation1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;
const animation2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
`;
const animation3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: ${COLORS_BY_SENTIMENT.PRIMARY.LIGHTEST};
  backdrop-filter: blur(2);
  z-index: 10;
`;

export const LoaderContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  & > div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${COLORS_BY_SENTIMENT.PRIMARY.DARKEST};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  & > div:nth-child(1) {
    left: 8px;
    animation: ${animation1} 0.6s infinite;
  }
  & > div:nth-child(2) {
    left: 8px;
    animation: ${animation2} 0.6s infinite;
  }
  & > div:nth-child(3) {
    left: 32px;
    animation: ${animation2} 0.6s infinite;
  }
  & > div:nth-child(4) {
    left: 56px;
    animation: ${animation3} 0.6s infinite;
  }
`;
