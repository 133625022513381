import React, { FunctionComponent } from 'react';
import { Header } from '../../CapeMorris/components';
import SubmenuLink from '../../CapeMorris/components/submenu';

interface OwnProps {}

type Props = OwnProps;

const CustomerSubmenu: FunctionComponent<Props> = (props) => {
  return (
    <>
      <Header header2>Klienci</Header>
      <SubmenuLink to={'/customers'}>Właściciele pojazdów</SubmenuLink>
      <Header header2>Firmy</Header>
      <SubmenuLink to={'/customers/companies'}>Firmy klientów</SubmenuLink>
    </>
  );
};

export default CustomerSubmenu;
