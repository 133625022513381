import React, { FunctionComponent } from 'react';
import Form from '../../../../CapeMorris/components/form/form';
import CompanyForm from '../../Company/CompanyForm/CompanyForm';
import ModalFooter from '../../../../CapeMorris/components/modal/ModalFooter';
import { Button } from '../../../../CapeMorris/components';
import COLORS from '../../../../assets/colors';
import ModalContainer from '../../../../CapeMorris/components/modal/use-modal-container';
import SnackContainer from '../../../../CapeMorris/components/snack-bar/use-snack-container';
import api from '../../../../CapeMorris/services/api';

interface OwnProps {}

type Props = OwnProps;

const CustomerCompanyModal: FunctionComponent<Props> = (props) => {
  const [waiting, setWaiting] = React.useState(false);
  const { hideModal } = ModalContainer.useContainer();
  const { showSnack } = SnackContainer.useContainer();
  const handleSubmit = React.useCallback((data: any) => {
    setWaiting(true);
    if (data.birthDate === '') {
      delete data.birthDate;
    }
    api
      .post('/companies', data)
      .then(() => {
        setWaiting(false);
        hideModal();
        showSnack({
          title: 'Sukces',
          message: 'Dodano poprawnie nowego firmę',
        });
      })
      .catch(() => {
        setWaiting(false);
        hideModal();
        showSnack({
          type: 'error',
          title: 'Błąd',
          message: 'Wystąpił problem podczas dodawania firmy',
        });
      });
  }, []);
  return (
    <Form onSubmit={handleSubmit} defaultValues={{}}>
      <CompanyForm />

      <ModalFooter>
        <Button text={'Zapisz'} type="submit" color={COLORS.GREEN.NORMAL} waiting={waiting} />
      </ModalFooter>
    </Form>
  );
};

export default CustomerCompanyModal;
