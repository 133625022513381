import { Vehicle } from '../vehicle';
import { AssetType, Money, UserType } from '../../CapeMorris/@types';
import { LocationType } from './Location';
import { CustomerType } from '../Customer/CustomerType';
import { CompanyType } from '../Customer/CompanyType';
import { ActivityType } from '../Activity/ActivityType';
import { DictValue } from '../../CapeMorris/@types/dictionary/dictionary';
import { ServiceType } from './ServiceType';

export type StatusType = 'on_sale' | 'sold' | 'preparation' | 'booked' | 'withdrawn' | 'at_owners';

export type InventoryType = {
  vehicle: Vehicle;
  uuid: string;
  id: number | string;
  '@id': string;
  salesPrice: Money;
  purchasePrice: Money;
  salesType: string;
  plates: string;
  status: StatusType;
  ownership: string;
  employee: UserType;
  location?: LocationType;
  position?: number;
  dekraCertificate?: string;
  internalComment?: string;
  createdBy: UserType;
  createdAt: string | Date;
  owner: CustomerType;
  company: CompanyType;
  assets: {
    '@id': string;
    asset: AssetType;
  }[];
  subject: string;
  subjectId: string;
  possibleStatuses: string[];
  activities: ActivityType[];
  serviceType: ServiceType;
  mileage: number;
  mainPhoto?: AssetType;
  insuranceDate: Date | string | null;
  examinationDate: Date | string | null;
};

export const Statuses = {
  on_sale: 'Dostępny',
  sold: 'Sprzedany',
  preparation: 'W przygotowaniu',
  booked: 'Zarezerwowany',
  withdrawn: 'Wycofany',
  at_owners: 'U właściciela',
};
export const StatusOptions: DictValue[] = [
  { value: 'on_sale', label: 'Dostępny' },
  { value: 'sold', label: 'Sprzedany' },
  { value: 'preparation', label: 'W przygotowaniu' },
  { value: 'booked', label: 'Zarezerwowany' },
  { value: 'withdrawn', label: 'Wycofany' },
  { value: 'at_owners', label: 'U właściciela' },
];
