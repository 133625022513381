import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import Select from '../../CapeMorris/components/form/select/select';
import PotentialClientForm from './PotentialClientForm';
import { TextareaInput } from '../../CapeMorris/components';

interface OwnProps {}

type Props = OwnProps;

const ActivityEditForm: FunctionComponent<Props> = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <TextareaInput
          name={'comment'}
          label="Komentarz"
          placeholder={'Wpisz opis aktywności'}
          required
        />
      </Grid>
      <Grid item md={4}>
        <Select
          name={'type'}
          label={'Typ kontaktu'}
          placeholder={'Typ aktywności'}
          options={[
            { value: 'owner', label: 'Kontakt: Właściciel' },
            { value: 'potential_client', label: 'Kontakt: Klient' },
            { value: 'other', label: 'Inne' },
          ]}
          required
        />
      </Grid>
      <PotentialClientForm />
    </Grid>
  );
};

export default ActivityEditForm;
