import React, { CSSProperties } from 'react';
import { H1, H2, H3 } from './header.styled';

type HeaderProps = {
  header2?: boolean;
  header3?: boolean;
  style?: CSSProperties;
};

export const Header: React.FC<HeaderProps> = ({ header2, header3, children, style }) => {
  if (header3) {
    return <H3 style={style}>{children}</H3>;
  }
  if (header2) {
    return <H2 style={style}>{children}</H2>;
  }

  return <H1 style={style}>{children}</H1>;
};
