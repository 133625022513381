import React, { CSSProperties } from 'react';
import { ButtonWrapper } from './button.styled';
import COLORS, { COLORS_BY_SENTIMENT } from '../../../assets/colors';
import { Icon } from '../icon/icon';

type ButtonProps = {
  color?: string;
  text: string;
  inverted?: boolean;
  wide?: boolean;
  style?: CSSProperties;
  disabled?: boolean;
  onClick?: () => any;
  icon?: React.ReactElement;
  type?: 'button' | 'submit' | 'reset';
  noPadding?: boolean;
  small?: boolean;
  waiting?: boolean;
};

export const Button: React.FC<ButtonProps> = ({
  color = COLORS_BY_SENTIMENT.PRIMARY.DARK,
  text,
  inverted = false,
  wide = false,
  disabled = false,
  style,
  onClick,
  icon,
  type = 'button',
  noPadding = false,
  small = false,
  waiting = undefined,
}) => {
  const iconColor = inverted ? color : COLORS.WHITE;
  const [internalWaiting, setWaiting] = React.useState(false);
  const handleClick = React.useCallback(() => {
    if (onClick) {
      setWaiting(true);
      const result = onClick();
      Promise.resolve(result).then(() => {
        setWaiting(false);
      });
    }
  }, [onClick]);

  React.useEffect(() => {
    if (waiting === undefined) {
      return;
    }
    setWaiting(waiting);
  }, [waiting]);

  return (
    <ButtonWrapper
      small={small}
      noPadding={noPadding}
      type={type}
      onClick={handleClick}
      style={style}
      inverted={inverted}
      color={color}
      wide={wide}
      disabled={disabled}>
      {internalWaiting && <span style={{ position: 'absolute' }}>Czekam...</span>}
      <span style={{ visibility: internalWaiting ? 'hidden' : 'visible' }}>
        {icon && <Icon style={{ marginRight: 6 }} icon={icon} size={20} color={iconColor} />}
        {text}
      </span>
    </ButtonWrapper>
  );
};
