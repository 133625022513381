export type Money = {
  value: number;
  currency?: 'PLN' | 'EUR' | 'USD';
};

export const DEFAULT_CURRENCY = 'PLN';

export const CURRENCIES = {
  PLN: 'zł',
  EUR: '€',
  USD: '$',
};

//todo: move to base-admin
