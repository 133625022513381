import React, { FunctionComponent } from 'react';
import { usePopper } from 'react-popper';
import { TooltipContainer } from './Tooltip.styled';

interface OwnProps {
  content: string | React.ReactElement;
  persist?: boolean;
}

type Props = OwnProps;

const Tooltip: FunctionComponent<Props> = (props) => {
  const [targetRef, setTargetRef] = React.useState<any>();
  const [popperRef, setPopperRef] = React.useState<any>();
  const [arrowRef, setArrowRef] = React.useState<any>();
  const [show, setShow] = React.useState(false);

  const { styles, attributes, update } = usePopper(targetRef, popperRef, {
    modifiers: [
      { name: 'arrow', options: { element: arrowRef } },
      { name: 'padding', options: { padding: [0, 5] } },
    ],
  });

  const hidePopper = () => {
    setShow(false);
  };

  const showPopper = () => {
    setShow(true);
    if (update) {
      update();
    }
  };

  return (
    <div onMouseEnter={showPopper} onMouseLeave={() => hidePopper()}>
      <div ref={setTargetRef}>{props.children}</div>
      <TooltipContainer
        onClick={hidePopper}
        show={show}
        ref={setPopperRef}
        style={styles.popper}
        {...attributes.popper}>
        {props.content}
        <div ref={setArrowRef} style={styles.arrow} />
      </TooltipContainer>
    </div>
  );
};

export default Tooltip;
