import React, { FunctionComponent } from 'react';
import FormPage from '../../../CapeMorris/components/form-page/form-page';
import CompanyForm from './CompanyForm/CompanyForm';

interface OwnProps {}

type Props = OwnProps;

const CompanyFormScreen: FunctionComponent<Props> = (props) => {
  return (
    <FormPage title={'Dodaj nową firmę'} editTitle={'Edytuj firmę'}>
      {(methods, isEdit) => <CompanyForm />}
    </FormPage>
  );
};

export default CompanyFormScreen;
