import React, { FunctionComponent } from 'react';
import ModalFooter from '../../CapeMorris/components/modal/ModalFooter';
import { Button } from '../../CapeMorris/components';
import ModalContainer from '../../CapeMorris/components/modal/use-modal-container';
import { ActivityType } from '../../@types/Activity/ActivityType';
import api from '../../CapeMorris/services/api';
import SnackContainer from '../../CapeMorris/components/snack-bar/use-snack-container';
import COLORS from '../../assets/colors';

interface OwnProps {
  activity: ActivityType;
  onDelete: (activity: ActivityType) => void;
}

type Props = OwnProps;

const ActivityDeleteModal: FunctionComponent<Props> = ({ activity, onDelete, ...props }) => {
  const { hideModal } = ModalContainer.useContainer();
  const { showSnack } = SnackContainer.useContainer();
  const [waiting, setWaiting] = React.useState(false);

  const handleConfirm = React.useCallback(() => {
    setWaiting(true);
    api
      .delete(activity['@id'].substr(4))
      .then(() => {
        setWaiting(false);
        showSnack({
          title: 'Sukces',
          message: 'Usunięto aktywność',
        });
        hideModal();
        onDelete(activity);
      })
      .catch(() => {
        setWaiting(false);
        showSnack({
          title: 'Błąd',
          message: 'Wystąpił błąd komunikacji z serwererm',
          type: 'error',
        });
      });
  }, [activity]);

  return (
    <>
      <div>Czy na pewno chcesz usunąć tę aktywność?</div>
      <ModalFooter>
        <Button text={'Nie usuwaj'} inverted onClick={() => hideModal()} />
        <Button text={'Usuń'} waiting={waiting} color={COLORS.RED.NORMAL} onClick={handleConfirm} />
      </ModalFooter>
    </>
  );
};

export default ActivityDeleteModal;
