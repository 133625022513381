import React, { CSSProperties, FunctionComponent } from 'react';
import { StyledSidenote } from './Sidenote.styled';

interface OwnProps {
  style?: CSSProperties;
}

type Props = OwnProps;

const Sidenote: FunctionComponent<Props> = (props) => {
  return <StyledSidenote style={props.style}>{props.children}</StyledSidenote>;
};

export default Sidenote;
