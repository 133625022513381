import React, { FunctionComponent } from 'react';
import { InputType } from '../../../@types';
import { NumberInput } from '../..';
import { useFormContext } from 'react-hook-form';
import { ERROR_MESSAGES } from '../error-messages';

type Props = InputType & {
  currency?: string;
};

const MoneyInput: FunctionComponent<Props> = (props) => {
  const formMethods = useFormContext();
  const watch = formMethods.watch(`${props.name}.value`);

  React.useEffect(() => {
    formMethods.setValue(`_${props.name}`, watch / 100);
  }, [watch]);

  return (
    <>
      <input
        type="hidden"
        name={`${props.name}.value`}
        ref={
          formMethods
            ? formMethods.register({
              min: 0,
              valueAsNumber: true,
              required: props.required && ERROR_MESSAGES.required,
            })
            : props.inputRef
        }
      />
      <input
        ref={formMethods?.register()}
        type="hidden"
        name={`${props.name}.currency`}
        value={props.currency ?? 'PLN'}
      />
      <NumberInput
        required={props.required}
        suffix={props.currency ?? 'PLN'}
        float={2}
        name={`_${props.name}`}
        label={props.label}
        placeholder={props.placeholder}
        onChange={(v) => formMethods.setValue(`${props.name}.value`, v * 100)}
      />
    </>
  );
};

export default MoneyInput;
