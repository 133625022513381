import styled from 'styled-components';
import COLORS, { COLORS_BY_SENTIMENT } from '../../../assets/colors';
import { WEIGHTS } from '../../../assets/fonts';

type ColorProps = {
  color: string;
};

export const Container = styled.div`
  position: fixed;
  bottom: 30px;
  left: 30px;
  z-index: 999;
`;
export const Item = styled.div`
  width: 640px;
  height: 70px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.GREY.LIGHT};
  border-radius: 5px;
`;
export const IconWrapper = styled.div<ColorProps>`
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.color};
  border-radius: 5px;
`;
export const Wrapper = styled.div`
  width: calc(100% - 70px);
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 30px;
`;
export const Title = styled.div<ColorProps>`
  color: ${(props) => props.color};
  font-size: 14px;
  font-weight: ${WEIGHTS.BOLD};
`;
export const Message = styled.div<ColorProps>`
  color: ${(props) => props.color};
  font-size: 14px;
  font-weight: ${WEIGHTS.REGULAR};
`;
