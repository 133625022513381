import React, { FunctionComponent } from 'react';
import { Icon } from '..';
import { TableAction } from './table.styled';
import Tooltip from '../tooltip/Tooltip';
import { COLORS_BY_FUNCTION } from '../../../assets/colors';

interface OwnProps {
  name: string;
  label: string;
  icon: React.ReactElement;
  onClick?: (event: React.MouseEvent) => void;
}

type Props = OwnProps;

const TableRowAction: FunctionComponent<Props> = (props) => {
  return (
    <Tooltip content={props.label}>
      <TableAction onClick={props.onClick}>
        <Icon icon={props.icon} color={COLORS_BY_FUNCTION.ACTION} />
      </TableAction>
    </Tooltip>
  );
};

export default TableRowAction;
