import React, { FunctionComponent } from 'react';
import { TextButton } from './InlineAction.styled';

interface OwnProps {
  onClick: (event: React.MouseEvent) => void;
  text: string;
}

type Props = OwnProps;

const InlineAction: FunctionComponent<Props> = (props) => {
  return <TextButton onClick={props.onClick}>{props.text}</TextButton>;
};

export default InlineAction;
