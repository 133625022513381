import styled from 'styled-components';
import COLORS from '../../../assets/colors';
import { WEIGHTS } from '../../../assets/fonts';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: ${COLORS.BLACK}bb;
`;

export const PreviewContainer = styled.div`
  width: 70vw;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;

  img {
    max-height: 100%;
    max-width: 100%;
  }
`;
export const FileIconContainer = styled.div`
  width: 200px;
`;

export const Panel = styled.div`
  background-color: ${COLORS.WHITE};
  width: 30vw;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 40px 30px;
`;

export const AssetFilename = styled.div`
  font-size: 14px;
  font-weight: ${WEIGHTS.REGULAR};
  text-wrap: normal;
`;

export const AssetProps = styled.div`
  padding-top: 30px;
`;
export const AssetProp = styled.div`
  padding-bottom: 30px;
  font-size: 14px;
`;
export const AssetPropName = styled.div`
  font-weight: ${WEIGHTS.BOLD};
`;
export const AssetPropValue = styled.div``;
export const Close = styled.div`
  position: absolute;
  right: 0;
  top: 15px;
`;

export const PreviousButton = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transition: all 0.2s ease-out;
  transform: rotate(90deg);
  &:hover {
    transform: rotate(90deg) scale(1.1);
  }
  cursor: pointer;
  padding: 20px;
`;
export const NextButton = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transition: all 0.2s ease-out;

  transform: rotate(270deg);
  &:hover {
    transform: rotate(270deg) scale(1.1);
  }
  cursor: pointer;
  padding: 20px;
`;
