import { EntityStateType, EntityType } from './entity-context';

export type BaseAction = {
  type: 'INIT_PROCESSING' | 'SAVE_ENTITY';
  entity: EntityType | undefined;
};

export const entityReducer = (
  previousState: EntityStateType,
  action: BaseAction,
): EntityStateType => {
  switch (action.type) {
    case 'INIT_PROCESSING': {
      return {
        entity: undefined,
        processing: true,
      };
    }
    case 'SAVE_ENTITY': {
      return {
        entity: action.entity,
        processing: false,
      };
    }
  }
};
