import styled from 'styled-components';
import { COLORS_BY_SENTIMENT } from '../../../assets/colors';
import { WEIGHTS } from '../../../assets/fonts';

export const TextButton = styled.div`
  display: inline-block;
  color: ${COLORS_BY_SENTIMENT.PRIMARY.DARK};
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: ${WEIGHTS.SEMI_BOLD};
  &:hover {
    color: ${COLORS_BY_SENTIMENT.PRIMARY.DARKEST};
  }
  &:active {
    transform: scale(0.9);
    color: ${COLORS_BY_SENTIMENT.PRIMARY.DARK};
  }
`;
