import React, { FunctionComponent } from 'react';
import { AssetType, InputType } from '../../@types';
import { useFormContext } from 'react-hook-form';
import * as AssetSelectComponents from './AssetSelect.styled';
import { FormError, FormInputWrapper, FormLabel } from '../form/form.styled';
import ModalContainer from '../modal/use-modal-container';
import AssetsModal from './AssetsModal';
import { Colored } from '../layout/layout.styled';
import { FormHelper } from '../../helpers/FormHelper';
import { ERROR_MESSAGES } from '../form/error-messages';

type AssetSelectProps = InputType & {
  isMulti?: boolean;
  load?: boolean;
};

const AssetSelect: FunctionComponent<AssetSelectProps> = (props) => {
  const formMethods = useFormContext();
  const { showModal, hideModal } = ModalContainer.useContainer();
  const [selected, setSelected] = React.useState<AssetType[]>([]);
  const [ready, setReady] = React.useState(false);
  const watchValue = formMethods.watch(props.name);
  const [error, setError] = React.useState<string | undefined>();

  const confirmSelection = React.useCallback(
    (assets: AssetType[]) => {
      hideModal();
      if (!props.isMulti) {
        if (!!assets[0]) {
          setSelected(() => [assets[0]]);
        }
      } else {
        setSelected((old) => [...old, ...assets]);
      }
    },
    [hideModal, props.isMulti],
  );

  const handleSelect = React.useCallback(
    (assets: AssetType[]) => {
      confirmSelection(assets);
    },
    [confirmSelection],
  );

  const startSelecting = React.useCallback(() => {
    showModal({
      title: 'Wybierz zasób',
      body: <AssetsModal isMulti={props.isMulti} onSelect={handleSelect} load={props.load} />,
      wide: true,
    });
  }, [props.isMulti, showModal, handleSelect]);

  React.useEffect(() => {
    if (!watchValue) {
      setReady(true);
      return;
    }
    if (Array.isArray(watchValue)) {
      if (typeof watchValue[0] === 'string') {
        return;
      }
      setSelected(() => [...watchValue]);
    } else {
      if (typeof watchValue === 'string') {
        return;
      }
      setSelected(() => [watchValue]);
    }
    setReady(true);
  }, [watchValue]);

  const handleMultiSelect = React.useCallback(
    (selected: AssetType[]) => {
      if (Array.isArray(watchValue)) {
        watchValue.forEach((value, index) => {
          const name = `${props.name}[${index}]`;
          formMethods.unregister(name);
        });
      }
      if (selected.length === 0) {
        formMethods.setValue(props.name, undefined);
      }
      selected.forEach((asset, index) => {
        const name = `${props.name}[${index}]`;
        formMethods.register(name);
        formMethods.setValue(name, asset['@id']);
      });
    },
    [watchValue, formMethods, props.name],
  );

  const handleSingleSelect = React.useCallback(
    (selected: AssetType | undefined) => {
      if (!selected) {
        formMethods.setValue(props.name, undefined);
        return;
      }
      formMethods.setValue(props.name, selected['@id']);
      formMethods.clearErrors(props.name);
    },
    [props.name, formMethods],
  );

  React.useEffect(() => {
    if (!props.isMulti) {
      handleSingleSelect(selected.length ? selected[0] : undefined);
    } else {
      handleMultiSelect(selected);
    }
  }, [selected, props.isMulti]);

  const removeAsset = (asset: AssetType) => {
    const index = selected.findIndex((el) => el['@id'] === asset['@id']);
    const copy = [...selected];
    copy.splice(index, 1);
    setSelected(() => [...copy]);
  };

  React.useEffect(() => {
    if (!props.required) {
      return;
    }
    const error = FormHelper.checkError(formMethods.formState.errors, props.name);
    setError(error);
  }, [formMethods.formState, props.name, props.required]);

  React.useEffect(() => {}, [error]);

  return (
    <AssetSelectComponents.Container>
      {ready && (
        <input
          type="hidden"
          name={props.name}
          ref={formMethods.register({ required: props.required && ERROR_MESSAGES.required })}
        />
      )}
      <FormInputWrapper status={!!error ? 'error' : 'ok'}>
        <FormLabel status="ok">{props.label ? props.label : props.name}</FormLabel>
        {selected.length === 0 && (
          <AssetSelectComponents.Empty onClick={startSelecting} hasError={!!error}>
            Nie wybrano żadnego elementu. <Colored>Kliknij, by wybrać.</Colored>
          </AssetSelectComponents.Empty>
        )}
        {selected.length > 0 &&
          selected.map((asset, index) => (
            <AssetSelectComponents.AssetItem key={index}>
              {asset.name}
              {(props.isMulti || !props.required) && (
                <AssetSelectComponents.AssetItemAction onClick={() => removeAsset(asset)}>
                  Usuń
                </AssetSelectComponents.AssetItemAction>
              )}
              {!props.isMulti && (
                <AssetSelectComponents.AssetItemAction onClick={startSelecting}>
                  Zmień
                </AssetSelectComponents.AssetItemAction>
              )}
            </AssetSelectComponents.AssetItem>
          ))}
        {props.isMulti && (
          <AssetSelectComponents.AssetAdd onClick={startSelecting}>
            Dodaj
          </AssetSelectComponents.AssetAdd>
        )}
      </FormInputWrapper>
      <FormError>{error}</FormError>
    </AssetSelectComponents.Container>
  );
};

export default AssetSelect;
