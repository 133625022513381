import React, { FunctionComponent } from 'react';
import * as Components from './AssetPreview.styled';
import { Button, Header, Icon, Tag } from '../../../CapeMorris/components';
import { AssetType } from '../../../CapeMorris/@types';
import { parseDate } from '../../../CapeMorris/helpers/date';
import { parseSize } from '../../../CapeMorris/helpers/FIleSize';
import Property from './Property';
import { FileIcon } from 'react-file-icon';
import { createFullApiUrl } from '../../../CapeMorris/services/api';
import { CloseIcon } from '../../../CapeMorris/icons/close.icon';
import InlineAction from '../../../CapeMorris/components/inline-action/InlineAction';
import { useHistory } from 'react-router-dom';
import { ArrowIcon } from '../../../CapeMorris/icons/arrow.icon';
import COLORS from '../../../assets/colors';

interface OwnProps {
  asset: AssetType;
  onClose: () => void;
  onPrev?: () => void;
  onNext?: () => void;
}

type Props = OwnProps;

const AssetPreview: FunctionComponent<Props> = ({ asset, onClose, ...props }) => {
  const { push } = useHistory();

  const previewUrl = React.useMemo(() => {
    return createFullApiUrl(`/files/${asset.file.id}/preview`);
  }, [asset]);
  return (
    <Components.Container>
      <Components.PreviewContainer>
        <Components.PreviousButton onClick={() => props.onPrev && props.onPrev()}>
          <Icon icon={<ArrowIcon />} color={COLORS.WHITE} size={45} />
        </Components.PreviousButton>
        <Components.NextButton onClick={() => props.onNext && props.onNext()}>
          <Icon icon={<ArrowIcon />} color={COLORS.WHITE} size={45} />
        </Components.NextButton>
        {!asset.image && (
          <Components.FileIconContainer>
            <FileIcon extension={asset.extension} />
          </Components.FileIconContainer>
        )}
        {asset.image && <img src={previewUrl} alt={asset.name} />}
      </Components.PreviewContainer>
      <Components.Panel>
        <Components.Close>
          <Button text={''} icon={<CloseIcon />} inverted onClick={onClose} />
        </Components.Close>
        <Header header2>{asset.name}</Header>
        <Components.AssetFilename>{asset.file.originalName}</Components.AssetFilename>
        <a href={createFullApiUrl(`/files/${asset.file.id}/download`)} download>
          Pobierz
        </a>

        <Components.AssetProps>
          <Property name="Opis" value={asset.description} />
          <Property name="Rozmiar pliku" value={parseSize(asset.file.size)} />
          {asset.file.dimensions && <Property name="Wymiary pliku" value={asset.file.dimensions} />}
          <Property
            name="Tagi"
            value={
              <>
                {asset.tags.map((t) => (
                  <Tag color={COLORS.BLUE.NORMAL} key={t.id}>
                    {t.name}
                  </Tag>
                ))}
              </>
            }
          />
          <Property name="Data dodania" value={parseDate(asset.createdAt)} />
          <Property name="Autor" value={asset.createdBy.name} />
          {asset.modifiedAt && (
            <Property name="Ostatnia edycja" value={parseDate(asset.modifiedAt)} />
          )}
          {asset.modifiedBy && (
            <Property name="Autor ostatniej edycji" value={parseDate(asset.modifiedBy.name)} />
          )}
          <Property name="Copyright" value={asset.copyright} />
          {asset.additionalData && (
            <Property name="Informacje dodatkowe" value={asset.additionalData} />
          )}
          <Property name="Powiązane produkty" value={' '} />
        </Components.AssetProps>
      </Components.Panel>
    </Components.Container>
  );
};

export default AssetPreview;
