import React, { FunctionComponent } from 'react';
import { AssetType } from '../../@types';
import ModalContent from '../modal/ModalContent';
import ModalFooter from '../modal/ModalFooter';
import { Button } from '..';
import Assets from '../../../screens/digital-assets/components/Assets';

interface OwnProps {
  onSelect: (assets: AssetType[]) => void;
  isMulti?: boolean;
  load?: boolean;
}

type Props = OwnProps;

const AssetsModal: FunctionComponent<Props> = (props) => {
  const [selected, setSelected] = React.useState<AssetType[]>([]);
  return (
    <>
      <ModalContent>
        <Assets
          selectable={props.isMulti ? 'multi' : 'single'}
          load={props.load}
          onSelect={(selected) => setSelected(() => [...selected])}
        />
      </ModalContent>
      <ModalFooter>
        <Button
          text="Zapisz"
          onClick={() => props.onSelect(selected)}
          disabled={selected.length === 0}
        />
      </ModalFooter>
    </>
  );
};

export default AssetsModal;
