import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import { Button, TextareaInput, TextInput } from '../../../../CapeMorris/components';
import Select from '../../../../CapeMorris/components/form/select/select';
import { DictValue } from '../../../../CapeMorris/@types/dictionary/dictionary';
import COLORS from '../../../../assets/colors';
import ModalContainer from '../../../../CapeMorris/components/modal/use-modal-container';
import CustomerCompanyModal from './CustomerCompanyModal';
import DatePicker from '../../../../CapeMorris/components/form/DatePicker/DatePicker';

interface OwnProps {}

type Props = OwnProps;

const sexes: DictValue[] = [
  { value: 'female', label: 'Kobieta' },
  { value: 'male', label: 'Mężczyzna' },
  { value: 'other', label: 'Inne' },
];

const CustomerForm: FunctionComponent<Props> = (props) => {
  const { showModal } = ModalContainer.useContainer();

  const openModal = React.useCallback(() => {
    showModal({
      title: 'Nowa firma',
      body: <CustomerCompanyModal />,
    });
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item md={3}>
        <TextInput name="country" label="Kraj" placeholder="PL" required defaultValue={'PL'} />
      </Grid>
      <Grid item md={3}>
        <TextInput name="language" label="Język" placeholder="pl" required defaultValue={'pl'} />
      </Grid>
      <Grid item md={3}>
        <Select name="sex" label="Płeć" options={sexes} required />
      </Grid>
      <Grid item md={6}>
        <TextInput name="firstName" label="Imię" placeholder="Jan" required />
      </Grid>
      <Grid item md={6}>
        <TextInput name="lastName" label="Nazwisko" placeholder="Kowalski" required />
      </Grid>
      <Grid item md={6}>
        <TextInput name="email" label="E-mail" placeholder="jan@example.com" required />
      </Grid>
      <Grid item md={6}>
        <TextInput name="phoneNumber" label="Telefon" placeholder="+48 XXX XXX XXX" />
      </Grid>
      <Grid item md={6}>
        <DatePicker name="birthDate" label={'Urodziny'} placeholder="2000-01-01" />
      </Grid>
      <Grid item md={5}>
        <Select
          name={'companies'}
          baseUrl={'/companies'}
          isMulti
          placeholder={'Firma Roku Sp. z o.o.'}
          label="Firmy"
          optionLabel={'name'}
        />
      </Grid>
      <Grid item md={1} style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
        <Button text="Nowa" small color={COLORS.GREEN.NORMAL} onClick={openModal} />
      </Grid>
      <Grid item md={12}>
        <TextareaInput name="internalComment" label="Komentarz" placeholder="Lorem ipsum" />
      </Grid>
    </Grid>
  );
};

export default CustomerForm;
