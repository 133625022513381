import React, { FunctionComponent } from 'react';
import { ActivityType } from '../../@types/Activity/ActivityType';
import { Grid } from '@material-ui/core';
import { Activity } from './index';

interface OwnProps {
  activities: ActivityType[];
  includeVehicle?: boolean;
}

type Props = OwnProps;

const ActivitiesList: FunctionComponent<Props> = ({ activities, ...props }) => {
  return (
    <Grid container spacing={2}>
      {activities.map((activity, idx) => (
        <Activity includeVehicle={props.includeVehicle} activity={activity} key={activity['@id']} />
      ))}
    </Grid>
  );
};

export default ActivitiesList;
