import React, { CSSProperties, FunctionComponent } from 'react';
import { StatusOptions, StatusType } from '../../../../@types/Inventory/Inventory';
import Select from '../../../../CapeMorris/components/form/select/select';
import { selectStyles } from '../../../../CapeMorris/components/form/select/select.styles';
import { WEIGHTS } from '../../../../assets/fonts';
import COLORS, { COLORS_BY_FUNCTION, COLORS_BY_SENTIMENT } from '../../../../assets/colors';
import { useFormContext } from 'react-hook-form';

interface OwnProps {
  onChange: (status: StatusType) => void;
}

type Props = OwnProps;

const customSelectStyles = (hasError: boolean | undefined, required?: boolean) => {
  let result = selectStyles(hasError, required);
  return {
    ...result,
    container: (styles: CSSProperties) => ({
      ...styles,
      marginTop: -18,
    }),
    control: (styles: CSSProperties) => ({
      ...styles,
      fontSize: 14,
      fontWeight: WEIGHTS.BOLD,
      color: hasError ? COLORS_BY_SENTIMENT.ERROR.NORMAL : COLORS_BY_SENTIMENT.PRIMARY.DARKEST,
      backgroundColor: hasError ? COLORS.RED.LIGHT : COLORS.WHITE,
      borderColor: hasError ? COLORS_BY_SENTIMENT.ERROR.NORMAL : COLORS_BY_FUNCTION.BORDER,
      borderWidth: 0,
      borderRadius: 0,
      cursor: 'pointer',
      borderBottomWidth: 0,
      padding: 0,
      margin: 0,
      boxShadow: 'none',
    }),
  };
};

const ChangeStatusSelect: FunctionComponent<Props> = (props) => {
  const { watch } = useFormContext();
  const watcher = watch('status');
  React.useEffect(() => {
    if (!watcher) {
      return;
    }
    props.onChange(watcher);
  }, [watcher]);
  return (
    <Select
      name="status"
      placeholder="Zmień status"
      options={StatusOptions}
      selectStyles={customSelectStyles}
    />
  );
};

export default ChangeStatusSelect;
