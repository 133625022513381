import React, { FunctionComponent } from 'react';
import { AssetType } from '../../../CapeMorris/@types';
import { Grid } from '@material-ui/core';
import Asset from '../../../CapeMorris/components/asset/Asset';
import Dropzone from '../../../CapeMorris/components/dropzone/Dropzone';
import { Container, DropzoneOverlay, DropzoneText } from './Assets.style';
import { Icon } from '../../../CapeMorris/components';
import UploadIcon from '../../../CapeMorris/icons/upload.icon';
import COLORS from '../../../assets/colors';
import { loadDigitalAssets } from '../../../CapeMorris/helpers/DigitalAssets';
import api from '../../../CapeMorris/services/api';
import { AxiosResponse } from 'axios';
import DefaultDropzoneContent from '../../../CapeMorris/components/dropzone/DefaultDropzoneContent';
import AssetPreview from './AssetPreview';

interface OwnProps {
  selectable?: 'single' | 'multi';
  canUpload?: boolean;
  filters?: any;
  onSelect?: (assets: AssetType[]) => void;
  assets?: AssetType[] | undefined;
  load?: boolean;
  onDelete?: (asset: AssetType) => void;
}

type Props = OwnProps;

const Assets: FunctionComponent<Props> = ({
  selectable,
  onSelect,
  filters,
  canUpload = true,
  load = true,
  ...props
}) => {
  const [previewed, setPreviewed] = React.useState<AssetType | null>(null);
  const [assets, setAssets] = React.useState<AssetType[]>([]);
  const [selected, setSelected] = React.useState<AssetType[]>([]);

  const handleCardClick = (asset: AssetType) => {
    if (!!selectable) {
      select(asset);
    } else {
      preview(asset);
    }
  };

  const preview = React.useCallback((asset: AssetType) => {
    setPreviewed(asset);
  }, []);

  const select = React.useCallback(
    (asset: AssetType) => {
      if (!selectable) {
        return;
      }
      const index = selected.findIndex((currentAsset) => currentAsset['@id'] === asset['@id']);
      if (index !== -1) {
        const copy = [...selected];
        copy.splice(index, 1);
        setSelected(copy);
      } else {
        if (selectable === 'single') {
          setSelected((old) => [asset]);
        } else {
          setSelected((old) => [asset, ...old]);
        }
      }
    },
    [selected, selectable],
  );

  React.useEffect(() => {
    if (onSelect) {
      onSelect(selected);
    }
  }, [selected]);

  const isSelected = React.useCallback(
    (asset: AssetType) => {
      const find = selected.findIndex((currentAsset) => currentAsset['@id'] === asset['@id']);
      return find !== -1;
    },
    [selected],
  );

  React.useEffect(() => {
    if (props.assets === undefined && load) {
      loadDigitalAssets(filters).then((newAssets) => {
        setAssets((old) => [...newAssets, ...old]);
      });
    } else if (props.assets !== undefined) {
      setAssets(props.assets);
    }
  }, []);

  const addAsset = React.useCallback((asset: AssetType) => {
    setAssets((old) => [asset, ...old]);
  }, []);

  const handleUpload = (uuid: string, file: File) => {
    api
      .post('/digital_assets', {
        name: file.name,
        uploadedFile: uuid,
        description: 'Opis',
      })
      .then((response: AxiosResponse<AssetType>) => {
        addAsset(response.data);
        select(response.data);
      });
  };

  const handleNext = React.useCallback(() => {
    setPreviewed((current) => {
      if (!current) {
        return null;
      }
      let idx = assets.findIndex((el) => el['@id'] === current['@id']);
      if (idx === assets.length - 1) {
        return assets[0];
      } else {
        return assets[idx + 1];
      }
    });
  }, [previewed]);

  const handlePrev = React.useCallback(() => {
    setPreviewed((current) => {
      if (!current) {
        return null;
      }
      let idx = assets.findIndex((el) => el['@id'] === current['@id']);
      if (idx === 0) {
        return assets[assets.length - 1];
      } else {
        return assets[idx - 1];
      }
    });
  }, [previewed]);

  React.useEffect(() => {
    if (props.assets) {
      setAssets(props.assets);
    }
  }, [props.assets]);

  return (
    <Dropzone onUpload={handleUpload} noClick={assets.length !== 0} disabled={!canUpload}>
      {(isDragActive) => (
        <>
          {assets.length === 0 && canUpload && (
            <DefaultDropzoneContent isDragActive={isDragActive} />
          )}
          <Container>
            <Grid style={{ paddingTop: 10 }} container spacing={2}>
              {assets.map((asset) => (
                <Grid item md={4} lg={4} xs={3} key={asset['@id']}>
                  <Asset
                    onDelete={props.onDelete}
                    asset={asset}
                    onClick={() => handleCardClick(asset)}
                    selected={isSelected(asset)}
                  />
                </Grid>
              ))}
            </Grid>
            {isDragActive && (
              <DropzoneOverlay>
                <Icon icon={<UploadIcon />} color={COLORS.WHITE} size={80} />
                <DropzoneText>Upuść by dodać</DropzoneText>
              </DropzoneOverlay>
            )}
          </Container>
          {previewed && (
            <AssetPreview
              onPrev={handlePrev}
              onNext={handleNext}
              asset={previewed}
              onClose={() => setPreviewed(null)}
            />
          )}
        </>
      )}
    </Dropzone>
  );
};

export default Assets;
