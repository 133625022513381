import React from 'react';

export const HomeIcon: React.FC = () => {
  return (
    <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        strokeWidth={0.1}
        d="M0.999787 11.0136H1.99979V18.0222C1.99979 19.1265 2.89679 20.0246 3.99979 20.0246H15.9998C17.1028 20.0246 17.9998 19.1265 17.9998 18.0222V11.0136H18.9998C19.1975 11.0136 19.3908 10.9548 19.5552 10.8448C19.7197 10.7348 19.8478 10.5785 19.9235 10.3955C19.9991 10.2126 20.0189 10.0113 19.9804 9.81714C19.9418 9.62295 19.8466 9.44457 19.7068 9.30455L10.7068 0.293559C10.614 0.2005 10.5038 0.126672 10.3825 0.0762998C10.2612 0.0259277 10.1311 0 9.99979 0C9.86845 0 9.73841 0.0259277 9.61709 0.0762998C9.49578 0.126672 9.38558 0.2005 9.29279 0.293559L0.292787 9.30455C0.152977 9.44457 0.057771 9.62295 0.0192035 9.81714C-0.0193641 10.0113 0.000438951 10.2126 0.076109 10.3955C0.151779 10.5785 0.279918 10.7348 0.444328 10.8448C0.608738 10.9548 0.802037 11.0136 0.999787 11.0136ZM9.99979 2.41715L15.9998 8.42447V13.0161L16.0008 18.0222H3.99979V8.42447L9.99979 2.41715Z"
      />
      <path
        strokeWidth={0.1}
        d="M9.9998 16.0197C13.7028 16.0197 14.9008 12.4764 14.9498 12.3262L13.0498 11.7045C13.0418 11.7275 12.2688 14.0173 9.9998 14.0173C7.7618 14.0173 6.9798 11.7936 6.9488 11.6985L5.0498 12.3262C5.0988 12.4764 6.2968 16.0197 9.9998 16.0197Z"
      />
    </svg>
  );
};
