import React, { FunctionComponent } from 'react';
import { RowActionType, TableProperty } from './table-property';
import { TableRow } from './table.styled';
import { LocaleContext } from '../../context/locale-context/locale-context';
import TableRowActions from './TableRowActions';

interface OwnProps {
  item: any;
  properties: TableProperty[];
  actions?: RowActionType[];
  onClick?: () => void;
}

type Props = OwnProps;

const TableItem: FunctionComponent<Props> = ({ item, properties, actions, onClick }) => {
  const { locale } = React.useContext(LocaleContext);
  const resolveValue = (item: any, property: TableProperty) => {
    const nameParts = property.name.split('.');
    item = property.translated ? item.translations[locale] : item;
    let value = null;
    if (nameParts.length === 1) {
      value = item[nameParts[0]];
    } else {
      value = nameParts.reduce((obj, part) => {
        if (!obj) {
          return null;
        }
        if (!obj.hasOwnProperty(part)) {
          throw new Error(`There is no ${part} in ${obj['@id']}`);
        }
        return obj[part];
      }, item);
    }

    if (typeof property.transformValue === 'function') {
      return property.transformValue(value, item);
    } else {
      return value;
    }
  };

  return (
    <TableRow onClick={onClick}>
      {properties.map((p) => {
        const Component = p.template;
        return (
          <td key={`cell-${item['@id']}-${p.name}`}>
            <div>
              <Component item={item} value={resolveValue(item, p)} options={p.templateOptions} />
            </div>
          </td>
        );
      })}
      <td>
        <TableRowActions actions={actions} item={item} />
      </td>
    </TableRow>
  );
};

export default TableItem;
