import React, { FunctionComponent } from 'react';
import { UserType } from "../../../CapeMorris/@types";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { EntityContext } from "../../../CapeMorris/context/entity-context/entity-context";
import { PageBase } from "../../../CapeMorris/components/layout/page/page";
import {
  PageContent,
  PageTop
} from "../../../CapeMorris/components/layout/page/page.styled";
import { Button, Header, Loader } from "../../../CapeMorris/components";
import { Grid } from "@material-ui/core";
import { SingleDetail } from "../../../CapeMorris/components/Details";
import AddressType from "../../../CapeMorris/@types/address";

interface OwnProps {}

type Props = OwnProps;

const LocationDetailsScreen: FunctionComponent<Props> = (props) => {
  const { push } = useHistory();
  let { url } = useRouteMatch();
  const [entity, setEntity] = React.useState<{name: string, address: AddressType} | undefined>();
  let { id } = useParams<{id?: string}>();
  const { load } = React.useContext(EntityContext);

  React.useEffect(() => {
    load(id).then((entity) => setEntity(entity));
    console.log(id);
  }, [id, load]);

  return (
    <>
      {!entity && <Loader />}
      {entity && (<PageBase>
        <PageTop>
          <Grid container md={2} justify={'space-between'} alignItems={'center'}>
            <Grid item>
              <Header>{entity.name}</Header>
            </Grid>
            <Grid item md={1}>
              <Button text={'Edytuj'} onClick={() => push(`${url.replace('show', 'edit')}`)}/>
            </Grid>
          </Grid>
        </PageTop>
        <PageContent>
          <Grid container spacing={2}>
            <SingleDetail md={2} title={'Miasto'}>
              {entity.address.city}
            </SingleDetail>
            <SingleDetail md={2} title={'Kod pocztowy'}>
              {entity.address.postCode}
            </SingleDetail>
            <SingleDetail md={2} title={'Ulica'}>
              {entity.address.street}
            </SingleDetail>
          </Grid>
        </PageContent>
      </PageBase>)}
    </>

  );
};

export default LocationDetailsScreen;
