import React from 'react';
import { Burger, Container, UserContainer } from './header.styled';
import { Icon, Logo } from '../..';
import { UserIndicator } from '../../user-indicator/user-indicator';
import LogoShort from '../../logo/LogoShort';
import LogoutIcon from '../../../icons/Logout.icon';
import { AuthContext } from '../../../context/auth-context/auth-context';
import { COLORS_BY_SENTIMENT } from '../../../../assets/colors';
import Form from '../../form/form';
import SearchBar from './SearchBar';

type HeaderProps = {
  onToggleMenu: () => void;
};

export const Header: React.FC<HeaderProps> = ({ onToggleMenu }) => {
  const { logout } = React.useContext(AuthContext);
  return (
    <Container>
      <Burger onClick={onToggleMenu}>
        <LogoShort width={50} />
      </Burger>
      <Form onSubmit={() => {}} defaultValues={{}}>
        <SearchBar />
      </Form>
      <UserContainer>
        <UserIndicator />
        <Icon
          title={'Wyloguj'}
          style={{ marginLeft: 20, cursor: 'pointer' }}
          color={COLORS_BY_SENTIMENT.PRIMARY.DARKEST}
          icon={<LogoutIcon />}
          onClick={() => logout()}
        />
      </UserContainer>
    </Container>
  );
};
