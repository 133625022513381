import styled from 'styled-components';
import { COLORS_BY_SENTIMENT } from '../../assets/colors';

export const ActivityContainer = styled.div<{ background: boolean }>`
  width: 100%;
  position: relative;
  background-color: ${(props) => props.background && COLORS_BY_SENTIMENT.PRIMARY.LIGHT};
  padding: ${(props) => props.background && '10px'};
  border-radius: ${(props) => props.background && '5px'};
`;

export const ActivityVehicle = styled.div`
  background-color: ${COLORS_BY_SENTIMENT.PRIMARY.DARK};
  color: white;
  padding: 10px 8px;
`;
export const ActivityActions = styled.div`
  display: flex;
  .cc-icon {
    opacity: 0.8;
    transition: all 0.2s ease-out;
    cursor: pointer;
    margin-left: 5px;
    :hover {
      opacity: 1;
    }
  }
`;
export const FloatingActivityActions = styled.div`
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
  .cc-icon {
    opacity: 0.8;
    transition: all 0.2s ease-out;
    cursor: pointer;
    margin-left: 5px;
    :hover {
      opacity: 1;
    }
  }
`;
