import React, { FunctionComponent } from 'react';
import { TableProperty } from '../../../CapeMorris/components/table/table-property';
import IdTemplate from '../../../CapeMorris/components/table/cell-templates/id-template';
import StringTemplate from '../../../CapeMorris/components/table/cell-templates/string-template';
import TablePage from '../../../CapeMorris/components/table-page/table-page';
import { useHistory, useRouteMatch } from 'react-router-dom';

interface OwnProps {}

type Props = OwnProps;

const UsersList: FunctionComponent<Props> = (props) => {
  const { push } = useHistory();
  let { url } = useRouteMatch();
  console.log(url);

  const properties: TableProperty[] = [
    {
      name: '@id',
      visibleName: 'ID',
      template: IdTemplate,
    },
    {
      name: 'username',
      visibleName: 'Name',
      template: StringTemplate,
    },
  ];

  const onCreate = () => {
    push(`${url}/create`);
  };

  return (
    <TablePage
      onCreate={onCreate}
      name="Użytkownika"
      title="Użytkownicy"
      url="/users"
      properties={properties}
    />
  );
};

export default UsersList;
