import React, { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { TextInput } from '../../CapeMorris/components';
import { Grid } from '@material-ui/core';
import Switch from '../../CapeMorris/components/form/switch/Switch';

interface OwnProps {}

type Props = OwnProps;

const PotentialClientForm: FunctionComponent<Props> = (props) => {
  const { watch } = useFormContext();
  const type = watch('type');
  return (
    <>
      {type === 'potential_client' && (
        <>
          <Grid item md={3}>
            <TextInput name="email" label="E-mail" placeholder="example@example.pl" />
          </Grid>
          <Grid item md={3}>
            <TextInput name="phoneNumber" label="Telefon" placeholder="+ 48 000 000 000" />
          </Grid>
          <Grid item md={4}>
            <Switch name="otherSalePotential" label="Potencjalna inna sprzedaż" />
          </Grid>
        </>
      )}
    </>
  );
};

export default PotentialClientForm;
