import React, { FunctionComponent } from 'react';
import { PageBase } from '../layout/page/page';
import { Column, Row } from '../layout/layout.styled';
import { Button, Header, Loader } from '..';
import Form from '../form/form';
import COLORS from '../../../assets/colors';
import { PageContent, PageTop } from '../layout/page/page.styled';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import FormContent from '../form/form-content';
import { UseFormMethods } from 'react-hook-form';
import PageTabs, { PageTabType } from '../layout/page/PageTabs';
import { EntityContext } from '../../context/entity-context/entity-context';

interface OwnProps {
  title: string;
  editTitle?: string;
  onSubmit?: (data: any) => any;
  children: (methods: UseFormMethods<any>, isEdit: boolean) => any;
  defaultValues?: any;
  disableContentMargin?: boolean;
  tabs?: PageTabType[];
  currentTab?: PageTabType;
  onTabChange?: (tab: PageTabType) => void;
}

type Props = OwnProps;

const FormPage: FunctionComponent<Props> = (props) => {
  const { goBack, push } = useHistory();
  const { create, load, save, rootPath, clone } = React.useContext(EntityContext);
  const [ready, setReady] = React.useState<boolean>(false);
  const [defaultValues, setDefaultValues] = React.useState<any>();
  const { state: locationState } = useLocation<{ cloneId?: string }>();
  const [waiting, setWaiting] = React.useState(false);

  let { id } = useParams<{ id: string | undefined }>();
  if (props.tabs !== undefined && props.currentTab === undefined) {
    throw new Error('You have to deliver `currentTab` while `tabs` are defined in FormPage.');
  }

  const handleTabChange = (tab: PageTabType) => {
    if (props.onTabChange !== undefined) {
      props.onTabChange(tab);
    }
  };

  const isEdit = React.useMemo(() => {
    return !!id;
  }, [id]);

  const title = React.useMemo(() => {
    return isEdit && !!props.editTitle ? props.editTitle : props.title;
  }, [isEdit, props.title, props.editTitle]);

  React.useEffect(() => {
    if (locationState && locationState.cloneId) {
      clone(locationState.cloneId).then((entity) => {
        setDefaultValues(entity);
        setReady(true);
      });
    } else if (id) {
      load(id).then((entity) => {
        setDefaultValues(entity);
        setReady(true);
      });
    } else {
      setDefaultValues(props.defaultValues ? props.defaultValues : {});
      setReady(true);
      return;
    }
  }, []);

  const handleSubmit = React.useCallback(
    (data: any) => {
      if (props.onSubmit) {
        data = props.onSubmit(data);
      }
      setWaiting(true);

      if (isEdit) {
        if (!id) {
          throw new Error('Some error occured while saving form.');
        }
        save(id, data).then((data) => {
          setWaiting(false);
          push(`${rootPath}/${data.id}/show`);
        });
      } else {
        create(data).then((data) => {
          setWaiting(false);
          push(`${rootPath}/${data.id}/show`);
        });
      }
    },
    [props, isEdit, rootPath, id, save, create, push],
  );

  return (
    <PageBase>
      {!ready && <Loader />}
      {ready && (
        <Form onSubmit={handleSubmit} defaultValues={defaultValues}>
          <PageTop>
            <Row>
              <Header>{title}</Header>
              <Column size={1 / 4}>
                <Row>
                  <Button onClick={() => goBack()} text="Odrzuć" color={COLORS.GREY.NORMAL} />
                  <Button text="Zapisz" type={'submit'} waiting={waiting} />
                </Row>
              </Column>
            </Row>
          </PageTop>
          {props.tabs && props.currentTab && (
            <PageTabs tabs={props.tabs} onChange={handleTabChange} currentTab={props.currentTab} />
          )}
          <PageContent disableMargin={props.disableContentMargin}>
            <FormContent>{(methods) => props.children(methods, isEdit)}</FormContent>
          </PageContent>
        </Form>
      )}
    </PageBase>
  );
};

export default FormPage;
