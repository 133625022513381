import React from 'react';
import {
  HeaderContainer,
  LayoutContainer,
  LayoutInline,
  MenuContainer,
  ContentContainer,
} from './layout.styled';
import { Header } from './header/header';
import { Menu } from './menu/menu';
import LayoutSubmenu from './menu/LayoutSubmenu';
import { MenuItemProps } from './menu/menu-item';
import { ClickAwayListener } from '@material-ui/core';

type LayoutProps = {
  menuItems: React.ReactElement[];
};

export const Layout: React.FC<LayoutProps> = ({ menuItems, children }) => {
  const [menuClosed, setMenuClosed] = React.useState(false);
  const [submenuComponent, setSubmenuComponent] = React.useState<React.ReactElement | undefined>();

  const showSubmenu = React.useCallback((item: React.ReactElement<MenuItemProps>) => {
    setSubmenuComponent(item.props.submenu);
  }, []);

  const dismissSubmenu = React.useCallback(() => {
    setTimeout(() => {
      setSubmenuComponent(undefined);
    }, 100);
  }, []);

  const toggleMenu = React.useCallback(() => {
    setMenuClosed(!menuClosed);
    dismissSubmenu();
  }, [menuClosed, dismissSubmenu]);

  return (
    <LayoutContainer>
      <HeaderContainer>
        <Header onToggleMenu={toggleMenu} />
      </HeaderContainer>
      <LayoutInline>
        <ClickAwayListener onClickAway={dismissSubmenu}>
          <MenuContainer closed={menuClosed}>
            <Menu items={menuItems} linkOnMouseEnter={showSubmenu} linkOnClick={dismissSubmenu} />
            <LayoutSubmenu active={!!submenuComponent} onClick={dismissSubmenu}>
              {submenuComponent}
            </LayoutSubmenu>
          </MenuContainer>
        </ClickAwayListener>

        <ContentContainer>{children}</ContentContainer>
      </LayoutInline>
    </LayoutContainer>
  );
};
