import React from 'react';
import { Icon } from '../..';
import { MenuLink, MenuTooltip } from './menu-item.styled';
import { COLORS_BY_SENTIMENT } from '../../../../assets/colors';

export type MenuItemProps = {
  icon: React.ReactElement;
  to: string;
  name: string;
  submenu?: React.ReactElement;
};

export const MenuItem: React.FC<MenuItemProps> = ({ name, to, icon }) => {
  return (
    <MenuLink title={name}>
      <Icon icon={icon} size={28} color={COLORS_BY_SENTIMENT.PRIMARY.DARKEST} />
      <MenuTooltip>{name}</MenuTooltip>
    </MenuLink>
  );
};
