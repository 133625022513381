import React from 'react';
import { PageBase } from '../../CapeMorris/components/layout/page/page';
import { PageContent } from '../../CapeMorris/components/layout/page/page.styled';
import { Grid } from '@material-ui/core';
import { ActivitiesForm } from '../../components/Activities';
import { Header } from '../../CapeMorris/components';
import DashboardActivitiesList from './DashboardActivitiesList';
import Form from '../../CapeMorris/components/form/form';

export const Dashboard = () => {
  const [listToken, setListToken] = React.useState<string | number>(0);
  const load = React.useCallback(() => {
    setListToken(Math.random());
  }, []);

  return (
    <PageBase>
      <PageContent>
        <Grid container spacing={8}>
          <Grid item md={6} style={{ position: 'relative', minHeight: 500 }}>
            <Form onSubmit={() => {}} defaultValues={{ dates: '1' }}>
              <DashboardActivitiesList reloadToken={listToken} />
            </Form>
          </Grid>
          <Grid item md={6}>
            <Header header2>Nowa aktywność</Header>
            <ActivitiesForm
              onCreate={() => {
                load();
              }}
            />
          </Grid>
        </Grid>
      </PageContent>
    </PageBase>
  );
};
