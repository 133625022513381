import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import { AxiosResponse } from 'axios';
import { FormContainer } from './ActivitiesForm.styled';
import { ActivityType } from '../../@types/Activity/ActivityType';
import SnackContainer from '../../CapeMorris/components/snack-bar/use-snack-container';
import api from '../../CapeMorris/services/api';
import { Button, TextareaInput } from '../../CapeMorris/components';
import Form from '../../CapeMorris/components/form/form';
import COLORS from '../../assets/colors';
import Select from '../../CapeMorris/components/form/select/select';
import PotentialClientForm from './PotentialClientForm';
import InventoryOption from '../Inventory/InventoryOption';

interface OwnProps {
  entityId?: string | undefined;
  onCreate: (comment: ActivityType) => void;
}

type Props = OwnProps;

type ApiResponse = AxiosResponse<ActivityType>;

const ActivitiesForm: FunctionComponent<Props> = ({ entityId, onCreate, ...props }) => {
  const [isFormCollapsed, setFormCollapsed] = React.useState(true);
  const { showSnack } = SnackContainer.useContainer();
  const [waiting, setWaiting] = React.useState(false);

  const handleClick = React.useCallback(() => {
    setFormCollapsed((value) => !value);
  }, [entityId]);

  const submit = React.useCallback(
    (data: { comment: string }) => {
      setWaiting(true);
      let postData = {};
      if (entityId) {
        postData = {
          inventory: entityId,
          ...data,
        };
      } else {
        postData = data;
      }
      api
        .post('/vehicle_inventory_activities', postData)
        .then((response: ApiResponse) => {
          setWaiting(false);
          onCreate(response.data);
          showSnack({
            title: 'Dodano aktywność',
            message: 'Pomyślnie dodano aktywność',
          });
          setFormCollapsed(true);
        })
        .catch((error) => {
          let response = error.response;
          setWaiting(false);
          showSnack({
            title: 'Błąd',
            message: response
              ? response.data['hydra:description']
              : 'Wystąpił nieoczekiwany błąd komunikacji z serwerem [2]',
            type: 'error',
          });
        });
    },
    [entityId],
  );
  return (
    <>
      <Grid item md={12}>
        {!isFormCollapsed && (
          <FormContainer>
            <Form onSubmit={submit} defaultValues={{}}>
              <Grid container spacing={2}>
                <Grid item md={4}>
                  <Select
                    name={'type'}
                    label={'Typ kontaktu'}
                    placeholder={'Typ aktywności'}
                    options={[
                      { value: 'owner', label: 'Kontakt: Właściciel' },
                      { value: 'potential_client', label: 'Kontakt: Klient' },
                      { value: 'other', label: 'Inne' },
                    ]}
                    required
                  />
                </Grid>
                <PotentialClientForm />
                {!entityId && (
                  <Grid item md={12}>
                    <Select
                      name={'inventory'}
                      baseUrl={
                        '/vehicle_inventories?status[]=preparation&status[]=on_sale&status[]=booked&status[]=at_owners&_status=1'
                      }
                      label="Samochód"
                      optionLabel={'name'}
                      placeholder={'Samochód'}
                      optionComponent={InventoryOption}
                      required
                    />
                  </Grid>
                )}
                <Grid item md={12}>
                  <TextareaInput
                    name={'comment'}
                    label="Komentarz"
                    placeholder={'Wpisz opis aktywności'}
                    required
                  />
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Grid container spacing={2} justify={'flex-end'}>
                  <Grid item>
                    <Button text={'Anuluj'} inverted onClick={handleClick} />
                  </Grid>
                  <Grid item>
                    <Button
                      color={COLORS.GREEN.NORMAL}
                      text={'Zapisz'}
                      type="submit"
                      waiting={waiting}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </FormContainer>
        )}
      </Grid>
      <Grid item md={12}>
        {isFormCollapsed && <Button text="Dodaj aktywność" wide onClick={handleClick} />}
      </Grid>
    </>
  );
};

export default ActivitiesForm;
