export const parseSize = (bytes: number) => {
  let suffix = 'B';

  if (bytes > 1024) {
    bytes = bytes / 1024;
    suffix = 'kB';
  }

  if (bytes > 1024) {
    bytes = bytes / 1024;
    suffix = 'MB';
  }

  if (bytes > 1024) {
    bytes = bytes / 1024;
    suffix = 'GB';
  }

  return bytes.toFixed(0) + ' ' + suffix;
};
