import React, { FunctionComponent } from 'react';
import { Header, Loader } from '../../CapeMorris/components';
import { Grid } from '@material-ui/core';
import Select from '../../CapeMorris/components/form/select/select';
import { ActivitiesList } from '../../components/Activities';
import { AuthContext } from '../../CapeMorris/context/auth-context/auth-context';
import { ActivityType } from '../../@types/Activity/ActivityType';
import api from '../../CapeMorris/services/api';
import { AxiosResponse } from 'axios';
import { useFormContext } from 'react-hook-form';

interface OwnProps {
  reloadToken?: string | number;
}

type Props = OwnProps;

type ApiResponse = AxiosResponse<{
  'hydra:member': ActivityType[];
}>;

type FiltersType = {
  'createdAt[after]'?: string | null | undefined;
  createdBy: string | number | undefined | null;
};

const DashboardActivitiesList: FunctionComponent<Props> = (props) => {
  const { can } = React.useContext(AuthContext);
  const [activities, setActivities] = React.useState<ActivityType[]>([]);
  const [filters, setFilters] = React.useState<FiltersType>({
    'createdAt[after]': null,
    createdBy: null,
  });
  const {
    state: { user },
  } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);
  const { watch } = useFormContext();

  const datesWatcher = watch('dates');
  const createdByWatcher = watch('createdBy');

  const load = React.useCallback(() => {
    setLoading(true);
    let params = filters;
    params = {
      ...params,
      createdBy: filters?.createdBy ?? null,
    };
    api
      .get('/vehicle_inventory_activities', {
        params,
      })
      .then((response: ApiResponse) => {
        setActivities(response.data['hydra:member']);
        setLoading(false);
      });
  }, [filters]);

  React.useEffect(() => {
    load();
  }, []);

  React.useEffect(() => {
    load();
  }, [props.reloadToken]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      load();
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, [filters]);

  React.useEffect(() => {
    const date = new Date();
    date.setDate(date.getDate() - datesWatcher);

    setFilters((filters) => ({
      ...filters,
      'createdAt[after]': date.toISOString(),
      createdBy: createdByWatcher,
    }));
  }, [datesWatcher, createdByWatcher]);

  return (
    <>
      {loading && <Loader />}
      <Grid container>
        <Grid item md={12}>
          <Header header2>Moje aktywności</Header>
        </Grid>
        <Grid item md={12} style={{ marginBottom: 20 }}>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <Select
                name="dates"
                label=""
                placeholder={'Zakres'}
                options={[
                  { value: '1', label: 'Dzień' },
                  { value: '2', label: '2 dni' },
                  { value: '7', label: 'Tydzień' },
                  { value: '30', label: 'Miesiąc' },
                ]}
              />
            </Grid>
            {can('ROLE_ADMIN') && (
              <Grid item md={3}>
                <Select name="createdBy" label="" placeholder={'Osoba'} baseUrl="/users" />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item md={12}>
          <ActivitiesList activities={activities} includeVehicle />
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardActivitiesList;
