import React, { FunctionComponent } from 'react';
import { Container, PageTab } from './PageTabs.styled';

export type PageTabType = {
  key: string;
  name: string;
};

interface OwnProps {
  tabs: PageTabType[];
  currentTab: PageTabType;
  onChange: (tab: PageTabType) => void;
}

type Props = OwnProps;

const PageTabs: FunctionComponent<Props> = ({ tabs, onChange, currentTab }) => {
  const handleClick = (tab: PageTabType) => {
    onChange(tab);
  };

  return (
    <Container>
      {tabs.map((tab) => (
        <PageTab onClick={() => handleClick(tab)} active={tab.key === currentTab.key} key={tab.key}>
          {tab.name}
        </PageTab>
      ))}
    </Container>
  );
};

export default PageTabs;
