import React, { FunctionComponent } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Vehicle } from '../../../@types/vehicle';
import { EntityContext } from '../../../CapeMorris/context/entity-context/entity-context';
import { CompanyType } from '../../../@types/Customer/CompanyType';
import { Button, Header, Loader } from '../../../CapeMorris/components';
import { PageBase } from '../../../CapeMorris/components/layout/page/page';
import { PageContent, PageTop } from '../../../CapeMorris/components/layout/page/page.styled';
import { Grid } from '@material-ui/core';
import { Sidenote } from '../../../CapeMorris/components/Typography';
import { SingleDetail } from '../../../CapeMorris/components/Details';
import DateAsString from '../../../CapeMorris/components/Date/DateString';

interface OwnProps {}

type Props = OwnProps;

const CompanyShowScreen: FunctionComponent<Props> = (props) => {
  const { push } = useHistory();
  let { url } = useRouteMatch();
  const [entity, setEntity] = React.useState<CompanyType | undefined>();
  let { id } = useParams<{ id?: string }>();
  const { load, state } = React.useContext(EntityContext);

  React.useEffect(() => {
    load(id).then((entity) => setEntity(entity));
  }, [id, load]);

  React.useEffect(() => {
    setEntity(state.entity);
  }, [state]);

  React.useEffect(() => {
    return () => {
      setEntity(undefined);
    };
  }, []);
  return (
    <>
      {!entity && <Loader />}
      {entity && (
        <PageBase>
          <PageTop>
            <Grid container spacing={2} justify={'space-between'} alignItems={'center'}>
              <Grid item>
                <Grid container direction={'column'}>
                  <Grid item>
                    <Header>{entity.name}</Header>
                  </Grid>
                  <Grid item>
                    <Sidenote>NIP: {entity.vatNumber}</Sidenote>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Button text={'Edytuj'} onClick={() => push(`${url.replace('show', 'edit')}`)} />
              </Grid>
            </Grid>
          </PageTop>
          <PageContent>
            <Grid container spacing={2}>
              <SingleDetail md={4} title={'Nazwa'}>
                {entity.name}
              </SingleDetail>
              <SingleDetail md={4} title={'NIP'}>
                {entity.vatNumber}
              </SingleDetail>
              <SingleDetail md={4} title={'E-mail'}>
                {entity.email}
              </SingleDetail>
              <SingleDetail md={4} title={'Numer do faktur'}>
                {entity.invoiceEmail}
              </SingleDetail>
              <SingleDetail md={4} title={'Strona WWW'}>
                {entity.website}
              </SingleDetail>
              <SingleDetail md={4} title={'Gałąź gospodarki'}>
                {entity.industryBranch}
              </SingleDetail>
              <SingleDetail md={4} title={'Wielkość firmy'}>
                {entity.size}
              </SingleDetail>
              <SingleDetail md={4} title={'Utworzono przez'}>
                {entity.createdBy.name}
              </SingleDetail>
              <SingleDetail md={4} title={'Utworzono dnia'}>
                <DateAsString input={entity.createdAt} time />
              </SingleDetail>
              <SingleDetail md={12} title={'Komentarz'}>
                {entity.internalComment}
              </SingleDetail>
              <Grid item md={12}>
                <Header header2>Adres</Header>
              </Grid>
              <SingleDetail md={4} title={'Ulica'}>
                {entity.address.street}
              </SingleDetail>
              <SingleDetail md={4} title={'Kod pocztowy'}>
                {entity.address.postCode}
              </SingleDetail>
              <SingleDetail md={4} title={'Miasto'}>
                {entity.address.city}
              </SingleDetail>
            </Grid>
          </PageContent>
        </PageBase>
      )}
    </>
  );
};

export default CompanyShowScreen;
