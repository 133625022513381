import React from 'react';

export const WarningIcon: React.FC = () => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M11.9997 16.4476C11.3287 16.4476 10.7663 17.0101 10.7663 17.681C10.7663 18.352 11.3287 18.9144 11.9997 18.9144C12.646 18.9144 13.233 18.352 13.2034 17.7106C13.233 17.0051 12.6756 16.4476 11.9997 16.4476Z"
          fill="#FFC700"
          strokeWidth={1}
        />
        <path
          d="M23.416 20.818
        strokeWidth={1}8C24.1905 19.4818 24.1955 17.8882 23.4258 16.5562L15.6999 3.17632C14.9352 1.82945 13.5537 1.03021 12.0046 1.03021C10.4555 1.03021 9.07407 1.83438 8.30936 3.17138L0.573521 16.566C-0.196116 17.9129 -0.191183 19.5163 0.588322 20.8533C1.35796 22.1755 2.73443 22.9698 4.2737 22.9698H19.7059C21.2501 22.9698 22.6365 22.1656 23.416 20.8188ZM21.7385 19.8518C21.3093 20.5918 20.5496 21.0309 19.701 21.0309H4.26877C3.43006 21.0309 2.67522 20.6017 2.25587 19.8765C1.83158 19.1414 1.82665 18.2632 2.25094 17.5231L9.98678 4.13343C10.4061 3.39833 11.156 2.96417 12.0046 2.96417C12.8482 2.96417 13.6031 3.40326 14.0224 4.13836L21.7533 17.5281C22.1678 18.2484 22.1628 19.1167 21.7385 19.8518Z"
          fill="#FFC700"
        />
        <path
          d="M11.6938 7.78922C11.1067 7.95696 10.7416 8.48978 10.7416 9.13608C10.7712 9.52583 10.7959 9.92052 10.8255 10.3103C10.9093 11.7953 10.9932 13.2507 11.0771 14.7357C11.1067 15.2389 11.4964 15.604 11.9997 15.604C12.5029 15.604 12.8976 15.2142 12.9222 14.7061C12.9222 14.4002 12.9222 14.119 12.9518 13.8082C13.0061 12.856 13.0653 11.9038 13.1196 10.9516C13.1492 10.3349 13.2035 9.71824 13.2331 9.10154C13.2331 8.87953 13.2035 8.68219 13.1196 8.48485C12.868 7.93229 12.2809 7.65108 11.6938 7.78922Z"
          fill="#FFC700"
          strokeWidth={1}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WarningIcon;
