import React from 'react';
import { authReducer } from './auth-reducer';
import { AuthContext } from './auth-context';
import { UserType } from '../../@types';
import api from '../../services/api';
import { stat } from 'fs';

export const AuthProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(authReducer, { user: null, state: 'LOADING' });

  const loadUser = () => {
    void api
      .get(`/current-user`)
      .then(({ data }: { data: UserType }) => {
        dispatch({ type: 'LOGIN', user: data });
      })
      .catch((error) => {
        dispatch({ type: 'LOGOUT' });
      });
  };

  React.useEffect(() => {
    loadUser();
  }, []);

  const authenticate = (username: string, password: string) => {
    dispatch({ type: 'LOADING' });
    void api
      .post(`/login`, { username, password })
      .then((response) => {
        loadUser();
        return response;
      })
      .catch(() => {
        dispatch({ type: 'INVALID_CREDENTIALS' });
      });
  };

  const logout = () => {
    void api.post(`/logout`, {}).then(() => {
      dispatch({ type: 'LOGOUT' });
    });
  };

  const can = React.useCallback(
    (role: string) => {
      if (!state.user) {
        return false;
      }

      return state.user.roles.includes(role);
    },
    [state.user],
  );

  return (
    <AuthContext.Provider value={{ state, dispatch, authenticate, logout, can }}>
      {children}
    </AuthContext.Provider>
  );
};
