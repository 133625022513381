export const parseDate = (date: Date | string, withTime: boolean = false) => {
  date = new Date(date);
  let result = `${prefixZero(date.getDate())}.${prefixZero(
    date.getUTCMonth() + 1,
  )}.${date.getFullYear()}`;

  if (withTime) {
    result = `${result}, ${prefixZero(date.getHours())}:${prefixZero(date.getMinutes())}`;
  }

  return result;
};

const prefixZero = (toBePrefixed: number) => {
  if (toBePrefixed < 10) {
    return `0${toBePrefixed}`;
  } else {
    return toBePrefixed;
  }
};
