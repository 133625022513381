export const removeEmptyIds = (data: any) => {
  if (typeof data !== 'object') {
    return;
  }
  for (let property in data) {
    if (property === 'id' && data[property] === '') {
      delete data[property];
    }
    if (Array.isArray(data[property])) {
      data[property] = data[property].filter((el: any) => el !== '');
    }
    removeEmptyIds(data[property]);
  }
};

export const removeEmptyValues = (data: any) => {
  if (typeof data !== 'object') {
    return;
  }
  for (let property in data) {
    if (data[property] === '') {
      delete data[property];
    } else if (
      data[property] &&
      typeof data[property] === 'object' &&
      Object.keys(data[property]).length === 0
    ) {
      delete data[property];
    } else {
      removeEmptyValues(data[property]);
    }
  }
};

export const removeAllIds = (data: any) => {
  if (typeof data !== 'object') {
    return;
  }
  for (let property in data) {
    if (property === 'id') {
      delete data[property];
    }
    removeAllIds(data[property]);
  }
};
